import { useEffect, useState } from "react";
import { errorToast, successToast } from "../layouts/utile/toast";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "../components/PageTitleWrapper";
import PageTitle from "../components/PageTitle";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { ToastContainer } from "react-toastify";
import Footer from "../components/Footer";
import apiService from "../services/api-service";
import { FaPencil } from "react-icons/fa6";

// For Grid List
interface Data {
  type: string;
  sname: string;
  name: string;
  isactive: string; id: number;

  // Add more fields as necessary
}

const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

const type = [
  {
    value: 1,
    label: 'Standard Terms'
  },
  {
    value: 0,
    label: 'Special Terms'
  }
];

function QuoteTerms() {
  // For Grid Table
  const [data, setData] = useState<Data[]>([]);

  // For Grid Table Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Form data 
  const [formData, setFormData] = useState<any>({
    id: '',
    type: '',
    sname: '',
    name: '',
    isactive: '',
    cby: '',
    con: '',
    mby: '',
    mon: ''
  });

  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {

    apiService.getQuoteTerms(rowsPerPage.toString(), (page + 1).toString(), search).then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    })
  }, [rowsPerPage, page, formData,search]);

  //For Pagination 
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Textbox and Select box onchage
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      type: '',
      sname: '',
      name: '',
      isactive: '',
      cby: '',
      con: '',
      mby: '',
      mon: ''
    });
    setSelectedItem(null);
  };

  // Insert and Update 
  let submit = (event: any) => {
    event.preventDefault(); // Prevents default form submission behavior
    // Insert
    if (selectedItem == null) {

      let data = {
        "type": formData?.type,
        "sname": formData?.sname,
        "name": formData?.name,
        "isactive": formData?.isactive,
        "cby": "1"
      };

      apiService.insertQuoteTerms(data
      ).then(response => {
        console.log("response", response.data);
        if (response?.data.key == "400") {
          errorToast(response?.data?.message)
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({

            type: '',
            sname: '',
            name: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err)
      });
    }
    // Update
    else {
      let data = {
        "id": formData?.id,
        "type": formData?.type,
        "sname": formData?.sname,
        "name": formData?.name,
        "isactive": formData?.isactive,
        "cby": "1"
      };
      apiService.updateQuoteTerms(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            type: '',
            sname: '',
            name: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err)
      });
    }
  };
  // Handler for Edit Button click
  const handleEditClick = (item: Data) => {

    setFormData({
      id: item?.id.toString(),
      type: item?.type,
      sname: item?.sname,
      name: item?.name,
      isactive: item?.isactive,
    });
    setSelectedItem(item);
    setOpen(true);
  };
  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  return (
    <>
      <Helmet>
        <title>QuoteTerms - CRM</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="QuoteTerms"
          subHeading=""
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // alignItems: 'flex-end',
            mt: -3.5, // Add some margin-bottom for spacing
            gap:2
          }}
        >   
         <TextField
            label="Search"
            value={search}
            onChange={handleSearchChange}
          />
         <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add New
          </Button></Box>
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>Short Name</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.sname}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          {row.type == "1" ? "Standard Terms" : "Special Terms"}
                        </TableCell>
                        <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                          {row.isactive == "1" ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>
                          <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={Math.floor(data.length == 0 ? 0 : data[0]["count"])}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>
        <Dialog open={open} onClose={handleClose}>
          <form>
            <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
            <DialogContent>
              <TextField
                select
                margin="dense"
                label="Type"
                name='type'
                fullWidth
                value={formData?.type === '' ? '' : Number(formData?.type)}
                onChange={handleChange}
              >
                {type.map((option: { value: any; label: any; }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                required
                margin="dense"
                id="sname"
                label="Short Name"
                name='sname'
                fullWidth
                value={formData?.sname}
                onChange={handleChange}
              />
              <TextField
                required
                margin="dense"
                id="name"
                label="Name"
                name='name'
                fullWidth
                value={formData?.name}
                onChange={handleChange}
              />

              <TextField
                select
                margin="dense"
                label="Status"
                name='isactive'
                fullWidth
                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                onChange={handleChange}
              >
                {status.map((option: { value: any; label: any; }) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary">
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Container>
      <Footer />
    </>
  );

}
export default QuoteTerms;