import { Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import { it } from "date-fns/locale";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { format } from "date-fns";
import { useReactToPrint } from 'react-to-print';// import { Type } from "typescript";
import * as XLSX from 'xlsx';
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faPrint } from "@fortawesome/free-solid-svg-icons";


interface Data {

    id: number;
    EnquiryNo: number;
    EnquiryDate: Date;
    Customer: string;
    Division: string;
    Status: string;
    Priority: string;
    FollowBy: string;
    FollowDate: Date;
    FollowDescription: string;
    NextFollowDate: Date;


}






const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};


function LeadDailyFollowupReport() {
    const [data, setData] = useState<any[]>([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [formData, setFormData] = useState<any>({
        id: '',
        EnquiryNo: '',
        EnquiryDate: '',
        Customer: '',
        Division: '',
        Status: '',
        Priority: '',
        FollowBy: '',
        FollowDate: '',
        FollowDescription: '',
        NextFollowDate: ''
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    const [fromDate, setFromDate] = useState(getCurrentDate());
    const [toDate, setToDate] = useState(getCurrentDate());
    const [search, setSearch] = useState('');
    const [currentDate, setCurrentDate] = useState(getCurrentDate());
    const [isPrintMode, setIsPrintMode] = useState(false);
    const handlePrint = () => {
        setIsPrintMode(true);

        // Allow state to update before printing
        setTimeout(() => {
            window.print();
            setIsPrintMode(false);
        }, 1000);
    };

    const [showImage, setShowImage] = useState(false)


    // const DateRangeSearch = () => {

    //     const [results, setResults] = useState([]);

    //     const handleSearch = () => {
    //         // Replace with your actual API endpoint and query parameters
    //         const apiEndpoint = `search?from=${fromDate}&to=${toDate}`;

    //         fetch(apiEndpoint)
    //             .then(response => response.json())
    //             .then(data => setResults(data))
    //             .catch(error => console.error('Error:', error));
    //     };
    // }




    useEffect(() => {


        apiService.leaddailyreport(rowsPerPage.toString(), (page + 1).toString(), "", fromDate, toDate)
            .then(response => {
                console.log("setData", response.data);

                setData(response.data);
            })
            .catch(err => console.error('Error fetching list data:', err));
    }, [rowsPerPage, page, search]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleChange = (event: any) => {
        const { name, value } = event.target;


        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({


        });
        setSelectedItem(null);


    };


    const [status, setStatus] = useState<string>('');

    const handleSearch = () => {
        apiService.leaddailyreport(rowsPerPage.toString(), (page + 1).toString(), search, fromDate, toDate).then((response) => {
            setData(response.data);

        }).catch((err) => {
            console.log("err", err);
        })
        setFormData({
            id: '',
            EnquiryNo: '',
            EnquiryDate: '',
            Customer: '',
            Division: '',
            Status: '',
            Priority: '',
            FollowBy: '',
            FollowDate: '',
            FollowDescription: '',
            NextFollowDate: ''


        });
    }

    const handleEditClick = (item: Data) => {

        setFormData({
            id: item?.id.toString(),
            EnquiryNo: item?.EnquiryNo,
            EnquiryDate: item?.EnquiryDate,
            Customer: item?.Customer,
            Division: item?.Division,
            Status: item?.Status,
            Priority: item?.Priority,
            FollowBy: item?.FollowBy,
            FollowDate: item?.FollowDate,
            FollowDescription: item?.FollowDescription,
            NextFollowDate: item?.NextFollowDate

        });
        setSelectedItem(item);
        setOpen(true);

    };

    const handleTableRowsChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

    };

    const printRef = useRef(null);
    const contentToPrint: any = useRef(null);
    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "DailyFollowReort",
        onBeforeGetContent: () => setShowImage(true),
        onBeforePrint: () => setShowImage(true),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,

    });


    const handleConvert = () => {
        let jsonData: any = data
        if (jsonData) {
            const worksheet = XLSX.utils.json_to_sheet(jsonData);

            // Add the custom message to the first cell
            XLSX.utils.sheet_add_aoa(worksheet, [["DAILY FOLLOWUP REPORT"]], { origin: 'A1' });

            if (!worksheet['A1'].xl) {
                worksheet['A1'].xl = {};
            }
            worksheet['A1'].xl.font = { bold: true };

            // Shift the JSON data down to start from the second row
            XLSX.utils.sheet_add_json(worksheet, jsonData, { origin: 'A2', skipHeader: false });

            // Create a new workbook and append the worksheet
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Merge the cells for the custom message to span across all columns
            const cols = Object.keys(jsonData[0]).length; // Get the number of columns
            const endCell = String.fromCharCode(65 + cols - 1) + '1'; // Calculate the end cell (e.g., 'D1' for 4 columns)
            worksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: cols - 1 } }]; // Merge cells from A1 to endCell

            // Save the workbook to a file
            XLSX.writeFile(workbook, 'dailyfollowupreport.xlsx');
        }

    };
    return (
        <>
            <Helmet>
                <title>Lead DailyFollowupReport - CRM</title>
            </Helmet>
            {/* <PrintComponent /> */}
            <PageTitleWrapper>
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                    <Grid item xs={12} sm={2.4}>
                        <PageTitle heading="Lead Daily Followup Report" subHeading="" />
                    </Grid>
                    <Grid item xs={12} sm={9.6} container spacing={1} justifyContent="flex-end" alignItems="center" wrap="nowrap">
                        <Grid item>
                            <TextField
                                id="fdate"
                                label="From Date"
                                name="fdate"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e: any) => setFromDate(e.target.value)}
                                defaultValue={currentDate}
                                margin="dense"
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="tdate"
                                label="To Date"
                                name="tdate"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e: any) => setToDate(e.target.value)}
                                defaultValue={currentDate}
                                margin="dense"
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                id="search"
                                label="Search"
                                name="search"
                                onChange={(e: any) => setSearch(e.target.value)}
                                margin="dense"
                                sx={{ width: '75%' }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ marginTop: '10px' }}
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                fullWidth
                            >
                                Search
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ marginTop: '10px' }}
                                variant="contained"
                                color="secondary"
                                startIcon={<FontAwesomeIcon icon={faPrint} />}
                                onClick={() => handlePrint1(null, () => contentToPrint.current)}
                                fullWidth
                            >
                                Print
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ marginTop: '10px' }}
                                variant="contained"
                                color="success"
                                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                                onClick={handleConvert}
                                fullWidth
                            >
                                Excel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

            </PageTitleWrapper>
            <Container maxWidth="lg">



                <br />

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >



                    <Grid item xs={12}>
                        <Paper
                            ref={contentToPrint}
                        >


                            <div className="printable-content" style={{ padding: '20px' }}>
                                <div className="printable-content" style={{ padding: '20px' }}>
                                    <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={require('../assets/logosaarc.png')}
                                            alt="Logo"
                                            style={{ width: '50px', height: '50px', marginRight: '20px' }}
                                        />
                                        <h1 style={{ margin: 0 }}>Lead Daily Followup Report</h1>
                                    </header>
                                </div>
                            </div>

                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Lead No</TableCell>
                                            <TableCell>Lead Date</TableCell>
                                            <TableCell>Customer</TableCell>
                                            <TableCell>Division</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Priority</TableCell>
                                            <TableCell>Follow By</TableCell>
                                            <TableCell>Follow Date</TableCell>
                                            <TableCell>Follow Description</TableCell>
                                            <TableCell>Next Follow Date</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={11} style={{ textAlign: 'center', color: '#888' }}>
                                                No records or Provide Valid Filters 
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            data.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell>{index + 1}</TableCell>
                                                    <TableCell>{row.LeadNo}</TableCell>
                                                    <TableCell>{format(new Date(row.LeadDate), 'dd-MM-yyyy')}</TableCell>
                                                    <TableCell>{row.Customer}</TableCell>
                                                    <TableCell>{row.Division}</TableCell>
                                                    <TableCell>{row.Status}</TableCell>
                                                    <TableCell>{row.Priority}</TableCell>
                                                    <TableCell>{row.FollowBy}</TableCell>
                                                    <TableCell>{format(new Date(row.FollowDate), 'dd-MM-yyyy')}</TableCell>
                                                    <TableCell>{row.FollowDescription}</TableCell>
                                                    <TableCell>{format(new Date(row.NextFollowDate), 'dd-MM-yyyy')}</TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </Paper>


                    </Grid>
                </Grid>
            </Container>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Footer />



            <style>
                {`
                    @media print {
                        .printable-content {
                            display: block !important;
                        }
   .css-rorn0c-MuiTableContainer-root {
    width: 100%;
    overflow-x: unset;

}
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                `}
            </style>
        </>
    );
}

export default LeadDailyFollowupReport;
function setType(data: any) {
    throw new Error("Function not implemented.");
}

