import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useState } from 'react';

import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Button,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@mui/material';
import Footer from 'src/components/Footer';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import VolumeDown from '@mui/icons-material/VolumeDown';
import VolumeUp from '@mui/icons-material/VolumeUp';

import Switch from '@mui/material/Switch';

import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import axios from 'axios';
import apiService from 'src/services/api-service';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPencil } from "react-icons/fa6";

// For Grid List
interface Data {
  sname: string;
  name: string;
  isactive: string;
  id: number;
  districtid: number;
  // Add more fields as necessary
}

const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];
interface District {
  id: number;
  name: string;
}

function City() {

  // For Grid Table
  const [data, setData] = useState<Data[]>([]);
  const [district, setDistrict] = useState<District[]>([]);

  // For Grid Table Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //Form data 
  const [formData, setFormData] = useState<any>({
    id: '',
    sname: '',
    name: '',
    isactive: '',
    districtid: ''
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);


  useEffect(() => {
    // Fetch data from an GET API
    console.log(rowsPerPage.toString(), "size");
    console.log((page + 1).toString(), "page");

    apiService.getCity(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    })

    apiService.getDropdown("mas_district").then(response => {
      setDistrict(response.data);
    }).catch((err) => {
      console.log("err", err)
    });
  }, [rowsPerPage, page, formData]);

  //For Pagination 
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Textbox and Select box onchage
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  // Insert and Update 
  let submit = (event: any) => {
    event.preventDefault(); // Prevents default form submission behavior
    // Insert
    if (selectedItem == null) {

      let data = {
        // "id" :  formData?.id,
        "sname": formData?.sname,
        "name": formData?.name,
        "isactive": formData?.isactive,
        "districtid": formData?.districtid,
        "cby": "1"
      };

      apiService.insertCity(data
      ).then(response => {
        console.log("response", response.data);
        if (response?.data.key == "400") {
          errorToast(response?.data?.message)
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            sname: '',
            name: '',
            isactive: '',
            districtid: ''
          });
        }
      }).catch((err) => {
        console.log("err", err)
      })
    }
    // Update
    else {
      let data = {
        "id": formData?.id,
        "sname": formData?.sname,
        "name": formData?.name,
        "isactive": formData?.isactive,
        "districtid": formData?.districtid,
        "cby": "1"
      };
      apiService.updateCity(Number(formData.id), data).then(response => {
        console.log("response", response.data);
        if (response?.data.key == "400") {
          errorToast(response?.data?.message)
        }
        else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            sname: '',
            name: '',
            isactive: '',
            districtid: ''
          });
        }
      }).catch((err) => {
        console.log("err", err)
      })
    }

  }
  // Handler for Edit Button click
  const handleEditClick = (item: Data) => {

    setFormData({
      id: item?.id.toString(),
      sname: item?.sname,
      name: item?.name,
      isactive: item?.isactive,
      districtid: item?.districtid
    });

    setSelectedItem(item)

  };

  return (
    <>
      <Helmet>
        <title>City - Admin</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="City"
          subHeading=""
        />
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <form action="#">
              <Card>
                {/* Form Input Fields */}
                <CardContent>
                  <Box
                    component="form"
                    sx={{
                      '& .MuiTextField-root': { m: 1, width: '25ch' }
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <div>
                      <TextField
                        required
                        id="code"
                        label="Code"
                        name='sname'
                        focused={formData?.sname ? true : false}
                        // defaultValue={formData?.sname}
                        value={formData?.sname}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        id="name"
                        label="Name"
                        name='name'
                        focused={formData?.name ? true : false}
                        // defaultValue={formData?.name}
                        value={formData?.name}
                        onChange={handleChange}
                      />
                      <TextField
                        select
                        margin="dense"
                        label="District"
                        name='districtid'
                        fullWidth
                        value={formData?.districtid}
                        onChange={handleChange}
                      >
                        {district.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        id="outlined-select-currency"
                        select
                        label="Status"
                        name='isactive'
                        focused={formData?.isactive != '' ? true : false}
                        value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                        onChange={handleChange}
                      // helperText="Please select Status"
                      >
                        {status.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Button sx={{ margin: 1 }} onClick={submit} variant='contained' color="primary">
                        {selectedItem ? 'Update' : 'Save'}
                      </Button>
                      <Button sx={{ margin: 1 }} variant='contained' color="warning">
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </CardContent>
              </Card>
            </form>

          </Grid>

        </Grid>
        <br></br>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Short Name</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                  {/* Add more headers as necessary */}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* page * rowsPerPage, page * rowsPerPage + rowsPerPage */}
                {data.slice().map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                    <TableCell>{row.sname}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell style={{
                      color: row.isactive == "1" ? "green" :
                        'red'
                    }}> {row.isactive == "1" ? "Active" : "In-Active"}</TableCell>
                    <TableCell>
                      <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                    </TableCell>
                    {/* Add more cells as necessary */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={data.length == 0 ? 0 : data[0]["count"]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Paper>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Container>
      <Footer />

    </>
  );
}

export default City;
