import React, { useRef, useState } from 'react';
import './sidebar1.css'
import './sidebar2.css'
import { MenuItem } from '@mui/material';
import { any } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { faCircleRight, faCircleDown, faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SubItem {
  title: string;
  url?: string; // URL for sub-menu items
  icon?: string | any;
  subItems?: SubItem[];
}

interface MenuItemProps {
  title: string;
  icon?: string | any;
  subItems?: SubItem[];
  url?: string;
}

const MenuItem1: React.FC<MenuItemProps> = ({ title, icon, subItems, url }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const [recognizedText, setRecognizedText] = useState<string>("");
  const [speechActive, setSpeechActive] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleVoiceCommand = (command: string) => {
    if (command.includes("click")) {
      // Simulate a button click
      document.getElementById("submit-button")?.click();
    } else if (command.includes("type") && inputRef.current) {
      // Extract text to type from command, e.g., "type hello"
      const textToType = command.replace("type", "").trim();
      inputRef.current.value = textToType;
    }
  };
  const bikeonclick = () => {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert("Speech Recognition not supported in this browser");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = false; // Stop after each command
    recognition.interimResults = false; // Only provide final results
    recognition.lang = "en-US"; // Language setting

    recognition.onstart = () => setSpeechActive(true);
    recognition.onend = () => setSpeechActive(false);

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      const transcript = event.results[0][0].transcript.trim().toLowerCase();
      setRecognizedText(transcript);
      handleVoiceCommand(transcript); // Handle recognized command
    };

    // Start recognition on component mount
    recognition.start();

    // Cleanup on unmount
    return () => recognition.stop();
  };
  const handleClick = () => {
    setIsExpanded(!isExpanded);
    // if (url) {
    //   navigate(url);
    // } else {
    //   setIsExpanded(!isExpanded);
    // }
  };
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const handleSubItemClick = (title: string, url?: string) => {
    setActiveItem(title); // Set the active sub-item
    if (url) navigate(url); // Navigate to the corresponding URL if provided
  };

  return (
    <div>
     
      <div className={`menu-item ${isExpanded ? 'active' : ''}`} onClick={handleClick}>
        {icon && <span className="menu-icon">{icon}</span>}
        {title}
        {/* {subItems && <span className="expand-icon">{isExpanded ? '-' : '+'}</span>} */}
        {subItems && <span className="expand-icon">
          <FontAwesomeIcon icon={isExpanded ? faCaretDown : faCaretRight} />
        </span>
        }
      </div>
      {isExpanded && subItems && (
        <div className="sub-menu">
          {subItems.map((subItem, index) => (
            <div key={index} className={`sub-menu-item ${activeItem === subItem.title ? 'active' : ''}`}
              onClick={() => handleSubItemClick(subItem.title, subItem.url)}>
              <MenuItem1 key={index} {...subItem} />
              {/* <div key={index}> {subItem.title}</div> */}
            </div>
          ))}
        </div>
      )}

    </div>

  );
};

export default MenuItem1;
