import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useState, useEffect } from 'react';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination
} from '@mui/material';
import Footer from 'src/components/Footer';
import axios from 'axios';
import apiService from 'src/services/api-service';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPencil } from "react-icons/fa6";
import { log } from 'console';

interface Data {
  username: string;
  pass: string;
  displayname: string;
  mobile: string;
  email: string;
  isactive: number;
  cby: string;
  con: Date;
  mby: string;
  mon: Date;
  id: number;
  roleid: number;
}

interface Role {
  id: number;
  role: string;
  isactive: number;
}

const status = [
  {
    value: 1,
    label: 'Active'
  },
  {
    value: 0,
    label: 'In-Active'
  }
];

function User() {
  const [data, setData] = useState<Data[]>([]);
  const [role, setRole] = useState<Role[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [mobileerrors, setmobileErrors] = useState<{ [key: string]: string }>({});
  const [formData, setFormData] = useState<any>({
    id: '',
    username: '',
    pass: '',
    displayname: '',
    mobile: '',
    email: '',
    isactive: '',
    cby: '',
    con: '',
    mby: '',
    mon: '',
    roleid: ''
  });
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);

  useEffect(() => {
    apiService.getUser(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
      setData(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getDropdown("mas_role").then(response => {
      const activeRoles = response.data; // Display all roles
      setRole(activeRoles);
    }).catch((err) => {
      console.log("err", err)
    });
  }, [rowsPerPage, page, formData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validateMobile = (mobile: string) => {
    // Mobile number validation logic (example: 10 digits only)
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };
  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io|co.in)$/;
    return emailRegex.test(email);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === 'mobile') {
      setIsMobileValid(validateMobile(value));
    }

    if (name == 'email') {
      setIsEmailValid(validateEmail(value));
    }

    apiService.getUser('mobile', "", "")
      .then(response => {
        if (response.data.exists) {  // Assuming API returns an 'exists' field
          setmobileErrors(prevErrors => ({
            ...prevErrors,
            mobile: 'Mobile number already exists',
          }));
        } else {
          setmobileErrors(prevErrors => ({
            ...prevErrors,
            mobile: '', // Clear the error if number doesn't exist
          }));
        }
      })
      .catch(err => {
        console.log("Error checking mobile number:", err);
      });

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));

    if (name === 'roleid') {
      const selectedRole = role.find(r => r.id === Number(value));
      if (selectedRole) {
        if (selectedRole.isactive === 0) {
          // If the role is inactive, set status to 'inactive' and make it read-only
          setFormData(prevData => ({
            ...prevData,
            isactive: '0' // Set to '0' for inactive status
          }));
        } else {
          // If the role is active, clear the status (set to empty) and make it editable
          setFormData(prevData => ({
            ...prevData,
            isactive: '' // Clear the status field
          }));
        }
      }
    }

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: value ? '' : `${name} is required`, // Reset or keep error based on value
      });
    }
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      id: '',
      username: '',
      pass: '',
      displayname: '',
      mobile: '',
      email: '',
      isactive: '',
      cby: '',
      con: '',
      mby: '',
      mon: '',
      roleid: ''
    });
    setSelectedItem(null);
    setIsMobileValid(true);
    setIsEmailValid(true);
    setErrors(null);
  };

  const isInsert = !formData?.id;
  const validateForm = (isInsert: boolean) => {
    const requiredFields = [
      'username', 'pass', 'mobile', 'email', 'roleid','isactive'
    ];
    let valid = true;
    const newErrors: { [key: string]: string } = {};

    requiredFields.forEach((field) => {
      // Only validate all required fields during insert, or validate only if the field is being updated
      if (isInsert || formData[field] !== undefined) {
          console.log(field, formData[field], "validate");
          if(field === "isactive")
          {
              if (formData[field] === '') {
              newErrors[field] = `${field} is isactive`;
              valid = false;
              }
          }
          else{
              if (!formData[field]) {
                  newErrors[field] = `${field} is required`;
                  valid = false;
              }
          }
          
      }
  });
    setErrors(newErrors);
    return valid;
  }

  // Insert and update
  let submit = (event: any) => {
    event.preventDefault();

    if (!validateForm(isInsert)) {
      console.log('Form is invalid. Please fill all required fields.');
      return;
    }

    // Insert
    if (selectedItem == null) {
      let data = {
        username: formData?.username,
        pass: formData?.pass,
        displayname: formData?.displayname,
        mobile: formData?.mobile,
        email: formData?.email,
        isactive: formData?.isactive,
        cby: "1",
        roleid: formData?.roleid
      };

      apiService.insertUser(data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            username: '',
            pass: '',
            displayname: '',
            mobile: '',
            email: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: '',
            roleid: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err);
      });
    }
    // Update
    else {
      let data = {
        id: formData?.id,
        username: formData?.username,
        pass: formData?.pass,
        displayname: formData?.displayname,
        mobile: formData?.mobile,
        email: formData?.email,
        isactive: formData?.isactive,
        roleid: formData?.roleid,
        cby: "1"
      };

      apiService.updateUser(Number(formData.id), data).then(response => {
        if (response?.data.key == "400") {
          errorToast(response?.data?.message);
        } else {
          successToast(response.data);
          setSelectedItem(null);
          setFormData({
            id: '',
            username: '',
            pass: '',
            displayname: '',
            mobile: '',
            email: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: '',
            roleid: ''
          });
          handleClose();
        }
      }).catch((err) => {
        console.log("err", err);
      });
    }
  };

  const handleEditClick = (item: Data) => {

    apiService.getUser_id(item?.id).then(response => {
      console.log(response, "response_id");

      setFormData({
        id: response.data?.id.toString(),
        username: response.data?.username,
        pass: response.data?.pass,
        displayname: response.data?.displayname,
        mobile: response.data?.mobile,
        email: response.data?.email,
        isactive: response.data?.isactive,
        cby: response.data?.cby,
        con: response.data?.con,
        mby: response.data?.mby,
        mon: response.data?.mon,
        roleid: response.data?.roleid
      });
      setSelectedItem(item);

    }).catch((err) => {
      console.log("err", err);
    });

    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>User - Admin</title>
      </Helmet>

      <PageTitleWrapper>
        <PageTitle
          heading="User"
          subHeading=""
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // alignItems: 'flex-end',
            mt: -3.5, // Add some margin-bottom for spacing
          }}
        >  <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add User
          </Button></Box>
      </PageTitleWrapper>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add User
            </Button>
          </Grid> */}

          <Grid item xs={12}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>S.No</TableCell>
                      <TableCell>User Name</TableCell>
                      <TableCell>Display Name</TableCell>
                      <TableCell>Mobile No</TableCell>
                      <TableCell>Email Id</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice().map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{row.username}</TableCell>
                        <TableCell>{row.displayname}</TableCell>
                        <TableCell>{row.mobile}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell style={{ color: row.isactive == 1 ? "green" : 'red' }}>
                          {row.isactive == 1 ? "Active" : "In-Active"}
                        </TableCell>
                        <TableCell>
                          <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={Math.floor(data.length == 0 ? 0 : data[0]["count"])}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </Paper>
          </Grid>
        </Grid>

        <Dialog open={open} onClose={handleClose}>
          <form>
            <DialogTitle>{selectedItem ? "Edit User" : "Add User"}</DialogTitle>
            <DialogContent>
              <TextField
                required
                margin="dense"
                id="username"
                label="UserName"
                name='username'
                fullWidth
                value={formData?.username}
                onChange={handleChange}
                error={!!errors?.username} // Highlight the field if there's an error
                helperText={errors?.username ? "User Name is required" : ""}  // Display error message if present
              />
              <TextField
                required
                margin="dense"
                id="pass"
                label="Password"
                name='pass'
                fullWidth
                value={formData?.pass}
                onChange={handleChange}
                error={!!errors?.pass} // Highlight the field if there's an error
                helperText={errors?.pass ? "Password is required" : ""}  // Display error message if present
              />
              <TextField
                required
                margin="dense"
                id="displayname"
                label="Display Name"
                name='displayname'
                fullWidth
                value={formData?.displayname}
                onChange={handleChange}
              //error={!!errors?.displayname} // Highlight the field if there's an error
              // helperText={errors?.displayname ? " Name is required" : ""}  // Display error message if present // Display error message if present
              />
              <TextField
                required
                margin="dense"
                id="mobile"
                label="Mobile No"
                name='mobile'
                fullWidth
                value={formData?.mobile}
                onChange={handleChange}
                error={!isMobileValid || !!errors?.mobile}
                helperText={!isMobileValid ? 'Invalid mobile number' : errors?.mobile ? mobileerrors.mobile : ''}
                onInput={(e) => {
                  const input = e.target as HTMLInputElement;
                  input.value = input.value.replace(/\D/g, ''); // Allow only digits
                }}
              />
              <TextField
                required
                margin="dense"
                id="email"
                label="Email Id"
                name='email'
                fullWidth
                value={formData?.email}
                onChange={handleChange}
                error={!isEmailValid || !!errors?.email}
                helperText={!isEmailValid ? 'Invalid email id' : errors?.email ? "Email Id is required" : ""}

              />
              <TextField
                select
                margin="dense"
                label="User Role"
                name='roleid'
                fullWidth
                value={formData?.roleid}
                onChange={handleChange}
                error={!!errors?.roleid} // Highlight the field if there's an error
                helperText={errors?.roleid ? "Role is required" : ""}  // Display error message if present
              >
                {role.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.role}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                margin="dense"
                label="Status"
                name='isactive'
                fullWidth
                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                onChange={handleChange}
                InputProps={{
                  readOnly: formData.isactive === '0' // Make the field read-only if the status is inactive
                }}
                error={!!errors?.isactive} // Show error if empty
                helperText={errors?.isactive ? "Status is required" : ""}
              >
                {status.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={submit} variant='contained' color="primary" disabled={!isMobileValid || !isEmailValid}>
                {selectedItem ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Container>
      <Footer />
    </>
  );
}

export default User;
