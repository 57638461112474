import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";


interface Data {
    id: number;
    stateid: number;
    sname: string;
    name: string;
    pincode: string;
    isactive: string;
}

const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];

interface State {
    id: number;
    name: string;
}

function District() {

    // For Grid Table
    const [data, setData] = useState<Data[]>([]);
    const [state, setState] = useState<State[]>([]);
    const [ispincodevalid, setIspincodevalid] = useState(true);
    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    //Form data 
    const [formData, setFormData] = useState<any>({
        id: '',
        stateid: '',
        sname: '',
        name: '',
        pincode: '',
        isactive: '',
    });
    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {

        console.log(rowsPerPage.toString(), "size");
        console.log((page + 1).toString(), "page");

        apiService.getDistrict(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err)
        })

        apiService.getDropdown("mas_state").then(response => {
            setState(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
    }, [rowsPerPage, page, formData]);

    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const validatePincode = (pincode: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const pincodeRegex = /^[0-9]{6}$/;
        return pincodeRegex.test(pincode);
    };
    // Textbox and Select box onchage
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if (name === 'pincode') {
            setIspincodevalid(validatePincode(value));
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            stateid: '',
            sname: '',
            name: '',
            pincode: '',
            isactive: '',
        });
        setSelectedItem(null);
        setIspincodevalid(true);
    };

    // Insert and Update 
    let submit = (event: any) => {
        event.preventDefault(); // Prevents default form submission behavior
        // Insert
        if (selectedItem == null) {
            let data = {
                stateid: formData?.stateid,
                sname: formData?.sname,
                name: formData?.name,
                pincode: formData?.pincode,
                isactive: formData?.isactive,
                cby: "1"
            };

            apiService.insertDistrict(data
            ).then(response => {
                console.log("response", response.data);
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message)
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        stateid: '',
                        sname: '',
                        name: '',
                        pincode: '',
                        isactive: '',
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
        // Update
        else {
            let data = {
                id: formData?.id,
                stateid: formData?.stateid,
                sname: formData?.sname,
                name: formData?.name,
                pincode: formData?.pincode,
                isactive: formData?.isactive,
                cby: "1"
            };
            apiService.updateDistrict(Number(formData.id), data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        stateid: '',
                        sname: '',
                        name: '',
                        pincode: '',
                        isactive: '',
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
    };
    // Handler for Edit Button click
    const handleEditClick = (item: Data) => {
        apiService.getDistrict_id(item?.id).then(response => {
            console.log(response, "response_id");
            setFormData({
                id: response.data?.id.toString(),
                stateid: response.data?.stateid.toString(),
                sname: response.data?.sname,
                name: response.data?.name,
                pincode: response.data?.pincode,
                isactive: item?.isactive,
            });
            setSelectedItem(item);
        }).catch((err) => {
            console.log("err", err);
        });
        setOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>District - Admin</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="District"
                    subHeading=""
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        // alignItems: 'flex-end',
                        mt: -3.5, // Add some margin-bottom for spacing
                    }}
                >  <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Add New
                    </Button></Box>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    {/* <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
              Add New
            </Button>
          </Grid> */}

                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Short Name</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data.slice().map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.sname}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                                                    {row.isactive == "1" ? "Active" : "In-Active"}
                                                </TableCell>
                                                <TableCell>
                                                    <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component="div"
                                count={data.length == 0 ? 0 : data[0]["count"]}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                    <form>
                        <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
                        <DialogContent>

                            <TextField
                                required
                                margin="dense"
                                id="sname"
                                label="Short Name"
                                name='sname'
                                fullWidth
                                value={formData?.sname}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                name='name'
                                fullWidth
                                value={formData?.name}
                                onChange={handleChange}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="pincode"
                                label="Pin Code"
                                name='pincode'
                                fullWidth
                                value={formData?.pincode}
                                onChange={handleChange}
                                error={!ispincodevalid}
                                helperText={!ispincodevalid ? 'Invalid Pin Code' : ''}
                            />
                            <TextField
                                select
                                margin="dense"
                                label="State"
                                name='stateid'
                                fullWidth
                                value={formData?.stateid}
                                onChange={handleChange}
                            >
                                {state.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                margin="dense"
                                label="Status"
                                name='isactive'
                                fullWidth
                                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                                onChange={handleChange}
                            >
                                {status.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={submit} variant='contained' color="primary" disabled={!ispincodevalid}>
                                {selectedItem ? 'Update' : 'Save'}
                            </Button>

                        </DialogActions>

                    </form>
                </Dialog>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />

        </>
    );


}
export default District;
