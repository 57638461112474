import { Box, Button, Card, CardContent, CardHeader, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, SelectChangeEvent, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import apiService from 'src/services/api-service';
import { errorToast, successToast } from 'src/layouts/utile/toast';
import './designdev.css'
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { AlignHorizontalCenter } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { log } from 'console';
import { id } from 'date-fns/locale';
import { NewReleasesRounded } from '@material-ui/icons';
import { isTemplateExpression } from 'typescript';

interface Data1 {
  id: number;
  enqno: string;
  enqdate: string;
  cusname: string;
  req: string;
  eqipposition: string;
  printcolor: string;
  isproceed: number;
}
interface LocationState1 {
  reqtypeid: number;
  id: number;
}
interface Data {
  id: number;
  name: string;
  compliance: string;
  attach: string;
  remarks: string;
}
interface Test {
  id: number;
  type: string;
  name: string;
}
interface Model {
  id: number;
  casemodel: string;
}
interface EssData {
  essdataid: string;
  remarks: string;
  cby: string;
  name: string;
  attach: string;
  compliance: string;
}
interface TestAccess {
  type: string;
  testaccessid: string;
  name: string;
  bit: string;
  handle?: string;  // Marking as optional
  status?: boolean | string;  // Marking as optional
}
interface FormData {
  ifinsert: boolean;
  informid: number;
  partno: string;
  drawid: string | number; // Assuming state.id is either a string or number, adjust as needed
  reqtypeid: number;
  ecnid: number;
  refno: string;
  issueno: string;
  pagerevno: string;
  intype: string;
  drawdate: Date;
  casetype: string;
  casemodelid: number;
  result: string;
  designby: string;
  authby: string;
  status: string;
  cby: string;
  listessdata: EssData[];
  listtest: TestAccess[];
  listaccess: TestAccess[];
  listitem: TestAccess[];
  listrequire: TestAccess[];
  attch: string;
  disablePartno?: boolean;
}

interface ModelData {
  id: number;
  casemodel: string;
  innerdimns: string;
  outerdimns: string;
  boxwt: number;
  casterwheel: number;
  acceswt: number;
  epe: number;
  casenetwt1: number;
  xlpe: number;
  casenetwt2: number;
  wropecode: number;
  latchtype: string;
  hingetype: string;
  stiffwt: number;
  indlen: number;
  indwidth: number;
  indbottom: number;
  indtop: number;
  isactive: number;
}

// Status options for the dropdown
const status = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'In-Active' }
];

interface SizeWt {
  id: number;
  innerdimns: string;
  outerdimns: string;
  casenetwt2: number;
}
interface ReviewData {
  listreqitems: ReqItems[];
  ifinsert: boolean;
  drawid: string | number;
  enqdetid: number;
  rdate: Date;
  eqipsize: string;
  eqipwt: string;
  refdrgthere: number;
  referdrgtheretext: string;
  suplocspd: number;
  eqipposition: string;
  anysplmreq: number;
  eqliftbeltreq: number;
  nameplatereq: number;
  spltestreq: number;
  senspd: number;
  senspdtext: string;
  printcolor: string;
  prv: number;
  descnt: number;
  lhook: number;
  wheel: number;
  slings: number;
  flprov: number;
  lidhandle: number;
  locks: number;
  latchlookasst: number;
  huin: number;
  foam: string;
  casetype: string;
  caseresult: string;
  rofoam: string;
  roprintcolor: string;
  cby: "1";
  revno: string;
  revpoints: string;
  note: string;
}
interface ReqItems {
  revid: number;
  accessid: number;
  status: number
}
interface OutputData {
  ifinsert: boolean;
  drawid: string | number;
  odate: Date;
  cptdwgattachsc: Date;
  cptdwgattachhc: Date;
  casedwgattchsc: Date;
  casedwgattchhc: Date;
  foamdwgattachsc: Date;
  foamdwgattachhc: Date;
  scnprtdetattachsc: Date;
  scnprtdetattachhc: Date;
  billofmatattachsc: Date;
  billofmatattachhc: Date;
  stfdwgattachsc: Date;
  stfdwgattachhc: Date;
  prddevplnaatchsc: Date;
  prddevplnaatchhc: Date;
  dfmeaattachsc: Date;
  dfmeaattachhc: Date;
  cby: "1"
}
interface VerifyData {
  ifinsert: boolean;
  drawid: string | number;
  enqdetid: number;
  vdate: Date;
  cieqwidth: string;
  cieqheight: string;
  cieqlen: string;
  fdwgbtmwidth: string;
  fdwgbtmheight: string;
  fdwgbtmlen: string;
  fdwgtopwidth: string;
  fdwgtopheight: string;
  fdwgtoplen: string;
  fcutbtmwidth: string;
  fcutbtmheight: string;
  fcutbtmlen: string;
  fcuttopwidth: string;
  fcuttopheight: string;
  fcuttoplen: string;
  cdexterwidth: string;
  cdexterheight: string;
  cdexterlen: string;
  cdinterwidth: string;
  cdinterheight: string;
  cdinterlen: string;
  handle: string;
  cby: "1"
  listrequirements: Requirements[];
}
interface Requirements {
  //drawid: number;
  verid: number;
  accessid: number;
  status: string
}

interface ValidData {
  id: number;
  detailstobechecked: string;
  chkwith: string;
}
interface ValidDetailsData {
  ifinsert: boolean;
  drawid: string | number;
  ecnid: number;
  drawvalid: number;
  con: Date;
  detailscheck: string[];
  casedwg: number;
  bom: number;
  foamdwg: number;
  printingdwg: number;
  remarkslistdate: number;
  eqpwt: string;
  casewt: string;
  accwt: string;
  foamwt: string;
  stiffwt: string;
  finalwt: string;
}

type Props = {}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const UpdatePage: React.FC = () => {
  const [tableData, setTableData] = useState<Data1[]>([]);
  const [value, setValue] = useState(0);
  const location = useLocation();
  const state = location.state as LocationState1 | undefined;
  const [data, setData] = useState<Data[]>([]);
  const [test, setTest] = useState<Test[]>([]);
  const [access, setAccess] = useState<Test[]>([]);
  const [activeItem, setActiveItem] = useState<string>("Input Form");
  const [model, setModel] = useState<ModelData[]>([]);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
  const [radioValues, setRadioValues] = useState({});
  const [radioValues1, setRadioValues1] = useState({});
  const [isNewEntry, setIsNewEntry] = useState(false);
  const [radioValuesverify, setRadioValuesverify] = useState({});
  const [selectedAccessIds, setSelectedAccessIds] = useState<Set<number>>(new Set());
  const [showTextBoxreferdrg, setShowTextBoxreferdrg] = useState(false);
  const [showTextBoxsenspd, setShowTextBoxsenspd] = useState(false);
  const [insertmodelid, setinsertmodelid] = useState<number | null>(null);
  const [sizewtdata, setSizeWtData] = useState<SizeWt[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [openDesignModelPopup, setOpenDesignModelPopup] = useState(false);
  const [openFusionPopup, setOpenFusionPopup] = useState(false);
  const [isBottomEditable, setIsBottomEditable] = useState(false);
  const [isTopEditable, setIsTopEditable] = useState(false);


  const [formModelData, setFormModelData] = useState<any>({
    id: '',
    casemodel: '',
    innerdimns: '',
    outerdimns: '',
    //boxwt: 0,
    // casterwheel: 0,
    // acceswt: 0,
    // epe: 0,
    // casenetwt1: 0,
    // xlpe: 0,
    // casenetwt2: 0,
    //wropecode: 0,
    latchtype: '',
    hingetype: '',
    // stiffwt: 0,
    // indlen: 0,
    // indwidth: 0,
    // indbottom: 0,
    // indtop: 0,
    isactive: 1
  });
  const [checkboxes, setCheckboxes] = useState({
    conceptDwgAttached: false,
    casedrawingattached: false,
    foamdrawingattached: false,
    screenprintingdetailsattached: false,
    billmaterialattached: false,
    stiffnerdrawingattached: false,
    proddevplanattached: false,
    dfmeaattached: false
  });

  const [formData, setFormData] = useState<FormData>({
    ifinsert: true,
    informid: 0,
    partno: "",
    drawid: state.id,
    reqtypeid: state.reqtypeid,
    ecnid: 0,
    refno: "",
    issueno: "",
    drawdate: new Date(),
    pagerevno: "",
    intype: "",
    casetype: "",
    casemodelid: 0,
    result: "",
    designby: "1",
    authby: "1",
    status: "Initiate",
    cby: "1",
    attch: "",
    listessdata: [
      {
        essdataid: "",
        remarks: "",
        cby: "1",
        name: "",
        attach: "",
        compliance: ""
      }
    ],
    listtest: [
      {
        type: "test",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listaccess: [
      {
        type: "Accessories",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listitem: [
      {
        type: "item",
        testaccessid: "",
        name: "",
        bit: ""
      }
    ],
    listrequire: [
      {
        type: "require",
        testaccessid: "",
        name: "",
        bit: "",
        status: ""
      }
    ]
  })

  const [originalModelData, setOriginalModelData] = useState({
    casemodelid: formData.casemodelid,
    //casemodel: formData.casemodel,
  });

  const [reviewData, setReviewData] = useState<ReviewData>({
    listreqitems: [
      // {
      //   revid: state.id,
      //   accessid: null,
      //   status: null
      // }
    ],
    ifinsert: true,
    drawid: state.id,
    enqdetid: 0,
    eqipsize: "",
    eqipwt: "",
    rdate: new Date(),
    refdrgthere: null,
    referdrgtheretext: "",
    suplocspd: null,
    eqipposition: "",
    anysplmreq: null,
    eqliftbeltreq: null,
    nameplatereq: null,
    spltestreq: null,
    senspd: null,
    senspdtext: "",
    printcolor: "",
    prv: null,
    descnt: null,
    lhook: null,
    wheel: null,
    slings: null,
    flprov: null,
    lidhandle: null,
    locks: null,
    latchlookasst: null,
    huin: null,
    foam: "",
    casetype: "",
    caseresult: "",
    rofoam: "",
    roprintcolor: "",
    cby: "1",
    revno: "",
    revpoints: "",
    note: ""
  })

  const [outputData, setoutputData] = useState<OutputData>({
    ifinsert: true,
    drawid: state.id,
    odate: new Date(),
    cptdwgattachsc: null,  // Setting to a default Date value
    cptdwgattachhc: null,
    casedwgattchsc: null,
    casedwgattchhc: null,
    foamdwgattachsc: null,
    foamdwgattachhc: null,
    scnprtdetattachsc: null,
    scnprtdetattachhc: null,
    billofmatattachsc: null,
    billofmatattachhc: null,
    stfdwgattachsc: null,
    stfdwgattachhc: null,
    prddevplnaatchsc: null,
    prddevplnaatchhc: null,
    dfmeaattachsc: null,
    dfmeaattachhc: null,
    cby: "1"
  })

  const [verifyData, setverifyData] = useState<VerifyData>({
    ifinsert: true,
    drawid: state.id,
    enqdetid: 0,
    vdate: new Date(),
    cieqwidth: "",
    cieqheight: "",
    cieqlen: "",
    fdwgbtmwidth: "",
    fdwgbtmheight: "",
    fdwgbtmlen: "",
    fdwgtopwidth: "",
    fdwgtopheight: "",
    fdwgtoplen: "",
    fcutbtmwidth: "",
    fcutbtmheight: "",
    fcutbtmlen: "",
    fcuttopwidth: "",
    fcuttopheight: "",
    fcuttoplen: "",
    cdexterwidth: "",
    cdexterheight: "",
    cdexterlen: "",
    cdinterwidth: "",
    cdinterheight: "",
    cdinterlen: "",
    handle: "",
    cby: "1",
    listrequirements: [
      // {
      //   //drawid: 0,
      //   verid: 0,
      //   accessid: 0,
      //   status: 0
      // }
    ]
  })

  const [validData, setValidData] = useState<ValidData[]>([
    {
      id: 0,
      detailstobechecked: "",
      chkwith: "",
    },
  ]);

  const [validDetailsData, setvalidDetailsData] = useState<ValidDetailsData[]>([
    // {
    //   ifinsert: true,
    //   drawid: state.id,
    //   ecnid: 0,
    //   drawvalid: 0,
    //   detailscheck: [],
    //   casedwg: 0,
    //   bom: 0,
    //   foamdwg: 0,
    //   printingdwg: 0,
    //   remarkslistdate: 0,
    //   eqpwt: "",
    //   casewt: "",
    //   accwt: "",
    //   foamwt: "",
    //   stiffwt: "",
    //   finalwt: "",
    // },
  ]);

  const handleChange1 = (event: any) => {
    const { name, value } = event.target;
  }

  useEffect(() => {
    if (state?.id) {
      apiService.getEssData(Number(state.id))
        .then(response => {
          //setData(response.data);
          console.log(response.data, "response.data12");
          setFormData(prevState => ({
            ...prevState,
            listessdata: response.data
          }));
          console.log(formData, "formData12");
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

      apiService.getTestData(Number(state.id))
        .then(response => {
          // Ensure the response data includes table IDs mapped to testaccessid
          const updatedData = response.data.map((item: any) => ({
            ...item,
            testaccessid: item.id, // Assign the table ID to testaccessid
          }));

          setFormData(prevState => ({
            ...prevState,
            listtest: updatedData
          }));

          console.log(formData, "Updated listtest with table IDs");
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

      apiService.getAccessoriesData(Number(state.id))
        .then(response => {
          //setData(response.data);
          const updatedData = response.data.map((item: any) => ({
            ...item,
            testaccessid: item.id, // Assign the table ID to testaccessid
          }));

          setFormData(prevState => ({
            ...prevState,
            listaccess: updatedData
          }));

          console.log(formData, "Updated listaccess with table IDs");
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

      apiService.getSizeWt(Number(state.id))
        .then(response => {
          console.log("Fetched list data:", response.data);
          setSizeWtData(response.data);
        })
        .catch(err => {
          console.log("Error fetching grid data:", err);
        });


      apiService.getDropdown("dsn_mas_testaccess")
        .then(response => {
          console.log(response, "response")

          //     // for test
          //     const filteredData = response.data.filter((item: Test) => item.type === 'test');
          //     setFormData(prevState => ({
          //       ...prevState,
          //       listtest: filteredData
          //     }));

          //     // for accessories
          //     const filteredData1 = response.data.filter((item: Test) => item.type === 'Accessories');
          //     console.log(filteredData1);
          //     setFormData(prevState => ({
          //       ...prevState,
          //       listaccess: filteredData1
          //     }));
          //     console.log(filteredData1, "listaccess");

          //for items
          const filteredData2 = response.data.filter((item: Test) => item.type === 'item');
          console.log(filteredData2);
          setFormData(prevState => ({
            ...prevState,
            listitem: filteredData2
          }));

          //for requirements
          const filteredData3 = response.data.filter((item: Test) => item.type === 'require');
          console.log(filteredData3, "testnow");
          setFormData(prevState => ({
            ...prevState,
            listrequire: filteredData3
          }));
        })
        .catch(error => {
          console.error("Error fetching dropdown data:", error);
        });

      apiService.getDropdown("dsn_mas_model").then(response => {
        setModel(response.data);
      }).catch((err) => {
        console.log("err", err)
      });
    }

    apiService.getDropdown("dsn_draw_validation").then(response => {
      setValidData(response.data);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getInputForm(state.id).then(response => {
      console.log("API Input Response:", response.data);
      const api = response.data
      setFormData(api as FormData);
    }).catch((err) => {
      console.log("err", err)
    });

    // apiService.getDesignModelid(formData.casemodelid).then(response => {
    //   console.log("API Model Response:", response.data);
    //   const api = response.data
    //   setFormModelData(api as ModelData);
    //   console.log("API modelData:", formModelData);
    // }).catch((err) => {
    //   console.log("err", err)
    // });


    apiService.getEnq_Date(Number(state.id))
      .then(response => {
        console.log("Fetched list data:", response.data);
        setTableData(response.data);
      })
      .catch(err => {
        console.log("Error fetching grid data:", err);
      });

    apiService.getReviewForm(state.id).then(response => {
      console.log("API Response: Now Test", response.data);
      const api = response.data
      setReviewData(api as ReviewData);
      console.log("API reviewData:", reviewData);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getOutputForm(state.id).then(response => {
      console.log("API Output Response:", response.data);
      const api = response.data
      setoutputData(api as OutputData);
      console.log("API outputData:", outputData);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getVerificationForm(state.id).then(response => {
      console.log("API Response:", response.data);
      const api = response.data
      setverifyData(api as VerifyData);
    }).catch((err) => {
      console.log("err", err)
    });

    apiService.getValidationForm(state.id).then(response => {
      console.log("=> test api data", response.data);
      const api = response.data
      setvalidDetailsData(api as ValidDetailsData[]);
    }).catch((err) => {
      console.log("err", err)
    });

  }, [state?.id]);
  console.log('api', formData);

  useEffect(() => {
    if (formData.casemodelid && formData.result === 'Can Be Fusioned') {
      // Fetch the data for the existing case model
      apiService.getDesignModelid(formData.casemodelid).then((response) => {
        console.log("Fetched data for 'Can Be Fusioned':", response.data);
        const modelData = response.data[0]; // or response.data[0] if it’s an array
        setFormModelData(modelData);
      }).catch((err) => {
        console.log("Error fetching data for fusioned model:", err);
      });
    }
  }, [formData.casemodelid, formData.result]);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    console.log("=> test api data", validDetailsData);
    setValue(newValue);
  };

  //FOR drawdate
  let drawdate = new Date();
  let day1 = String(drawdate.getDate()).padStart(2, '0');
  let month1 = String(drawdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year1 = drawdate.getFullYear();
  let formattedDatedrawdate = `${day1}-${month1}-${year1}`;

  //FOR rdate
  let rdate = new Date();
  let day2 = String(rdate.getDate()).padStart(2, '0');
  let month2 = String(rdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year2 = rdate.getFullYear();
  let formattedDaterdate = `${day2}-${month2}-${year2}`;

  //FOR odate
  let odate = new Date();
  let day3 = String(odate.getDate()).padStart(2, '0');
  let month3 = String(odate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year3 = odate.getFullYear();
  let formattedDateodate = `${day3}-${month3}-${year3}`;

  //FOR vdate
  let vdate = new Date();
  let day4 = String(vdate.getDate()).padStart(2, '0');
  let month4 = String(vdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year4 = vdate.getFullYear();
  let formattedDatevdate = `${day4}-${month4}-${year4}`;

  //FOR con
  let con = new Date();
  let day5 = String(vdate.getDate()).padStart(2, '0');
  let month5 = String(vdate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  let year5 = con.getFullYear();
  let formattedDatecon = `${day5}-${month5}-${year5}`;

  const handleCheckboxChange = (field) => {
    if (field === 'indbottom') {
      setIsBottomEditable(!isBottomEditable);
      setIsTopEditable(false);  // Make the other field readonly
    } else {
      setIsTopEditable(!isTopEditable);
      setIsBottomEditable(false); // Make the other field readonly
    }
  };

  const handleRemarksChange = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    // Create a deep copy of the listessdata array
    const newTableRows = [...formData?.listessdata];
    // Update the specific row's remarks and essdataid
    newTableRows[index] = {
      ...newTableRows[index],
      [name]: value,
      essdataid: rowid.toString(),
    };
    // Update the state with the new list
    setFormData({ ...formData, listessdata: newTableRows });
    console.log(formData, "formData.listessdata");
  };

  const handleReqItemsChange = (testaccessid: any, index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    // Ensure testaccessid is properly set and not an empty string
    const validAccessId = testaccessid || 0; // Default to 0 if testaccessid is falsy
    setFormData(prevData => {
      const updatedList = [...prevData.listitem];
      updatedList[index] = {
        ...updatedList[index],
        testaccessid: isChecked ? validAccessId : null // Store validAccessId if checked, otherwise set to null
      };
      return { ...prevData, listitem: updatedList };
    });
  };

  const handleBitChange = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Update specific row's radio button selection in local state
    setRadioValues(prevValues => ({
      ...prevValues,
      [rowid]: value,
    }));

    // Update formData with new bit value only
    setFormData(prevState => {
      const newTableRows = [...prevState.listtest];
      newTableRows[index].bit = value;
      return { ...prevState, listtest: newTableRows };
    });

    console.log(formData.listtest, "Updated bit in listtest");
  };


  const handleBitChange1 = (rowid: number, index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Update local state for the specific row's radio button selection
    setRadioValues1(prevValues => ({
      ...prevValues,
      [rowid]: value,  // Ensure this update only applies to the current row
    }));
    // Update the global formData state
    const newTableRows = [...formData?.listaccess];
    newTableRows[index].bit = value;  // Update the corresponding bit value as string "1" or "0"
    newTableRows[index]["testaccessid"] = rowid.toString();
    setFormData({ ...formData, listaccess: newTableRows });
    console.log(newTableRows, "Updated listaccess");
  };

  const handleInputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'casetype' && value === 'NPD Case') {
      setFormData((prevState) => ({
        ...prevState,
        result: 'To Be Made',
      }));
      setOpenDesignModelPopup(true); // Open the design model popup
    }
    if (name === 'result' && value === 'Can Be Fusioned') {
      setFormModelData((prevState) => ({
        ...prevState,
      }));
      setOpenFusionPopup(true); // Open the design model popup   
    }
  };

  // Textbox and Select box onchage
  const handleModelChange = (event: any) => {
    const { name, value } = event.target;
    console.log(`Field updated: ${name} = ${value}`);
    setFormModelData((prevFormModelData) => {
      const newFormData = { ...prevFormModelData, [name]: value };
      console.log("Updated form data:", newFormData);
      const updatedInnerdimns = `${newFormData.indlen || ''}*${newFormData.indwidth || ''}*${newFormData.indbottom || ''}+${newFormData.indtop || ''}`;
      console.log("Updated innerdimns:", updatedInnerdimns);
      return {
        ...newFormData,
        innerdimns: updatedInnerdimns,
      };
    });
    if (formData.result === 'Can Be Fusioned' && !originalModelData.casemodelid) {
      setOriginalModelData({
        casemodelid: formData.casemodelid,
        //casemodel: formData.casemodel,
      });
    }
  };


  const handleFieldChange = (e, field) => {
    const value = e.target.value;

    // Update the individual field
    setFormModelData((prevState) => {
      const newData = { ...prevState, [field]: value };

      // Recalculate the 'innerdimns' on each field change (including bottom and top)
      const updatedInnerdimns = `${newData.indlen || ''}*${newData.indwidth || ''}*${newData.indbottom || ''}+${newData.indtop || ''}`;

      return {
        ...newData,
        innerdimns: updatedInnerdimns,
      };
    });
  };

  // Insert and Update 
  let submit = (event: any) => {
    //event.preventDefault(); // Prevents default form submission behavior
    // Insert
    let data = {
      "casemodel": formModelData?.casemodel,
      "innerdimns": formModelData?.innerdimns,
      "outerdimns": formModelData?.outerdimns,
      "boxwt": formModelData?.boxwt,
      "casterwheel": formModelData?.casterwheel,
      "acceswt": formModelData?.acceswt,
      "epe": formModelData?.epe,
      "casenetwt1": formModelData?.casenetwt1,
      "xlpe": formModelData?.xlpe,
      "casenetwt2": formModelData?.casenetwt2,
      "wropecode": formModelData?.wropecode,
      "latchtype": formModelData?.latchtype,
      "hingetype": formModelData?.hingetype,
      "stiffwt": formModelData?.stiffwt,
      "indlen": formModelData?.indlen,
      "indwidth": formModelData?.indwidth,
      "indbottom": formModelData?.indbottom,
      "indtop": formModelData?.indtop,
      "isactive": formModelData?.isactive,
      "cby": "1"
    };

    apiService.insertDesignModel(data
    ).then(response => {
      console.log("rrrr");
      console.log("response333 Model", response.data[1]);
      console.log("555");
      const newRow: ModelData = {
        id: response.data[1],
        casemodel: data.casemodel,
        innerdimns: '',
        outerdimns: '',
        boxwt: null,
        casterwheel: null,
        acceswt: null,
        epe: null,
        casenetwt1: null,
        xlpe: null,
        casenetwt2: null,
        wropecode: null,
        latchtype: '',
        hingetype: '',
        stiffwt: null,
        indlen: null,
        indwidth: null,
        indbottom: null,
        indtop: null,
        isactive: 0,
      }
      console.log("ttttt");
      setModel(prevModel => [...prevModel, newRow]);
      if (formData?.casetype === "NPD Case") {
        setinsertmodelid(response.data[1]);
        setFormData(prev => ({
          ...prev,
          casemodelid: response.data[1]  // Set the new case model ID here
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          casemodelid: formData.casemodelid  // Set the new case model ID here
        }))
      }
      console.log("insertmodelid1", insertmodelid);
      if (formData?.result === "Can Be Fusioned") {
        setFormData(prev => ({
          ...prev,
          partno: formModelData?.casemodel, // Store casemodel in partno
          disablePartno: true               // Flag to disable the partno field
        }));
      } else {
        setFormData(prev => ({
          ...prev,
          disablePartno: false // Enable partno if result is not "Can Be Fusioned"
        }));
      }
      if (formData.result === 'Can Be Fusioned') {
        // setFormData((prev) => ({
        //   ...prev,
        //   casemodelid: originalModelData.casemodelid,
        //   //casemodel: originalModelData.casemodel,
        // }));
      }
      // apiService.getDropdown("dsn_mas_model").then(response => {
      //   setModel(response.data);

      // }).catch((err) => {
      //   console.log("err", err)
      // });
      setFormModelData({
        id: '',
        casemodel: '',
        innerdimns: '',
        outerdimns: '',
        boxwt: '',
        casterwheel: '',
        acceswt: '',
        epe: '',
        casenetwt1: '',
        xlpe: '',
        casenetwt2: '',
        wropecode: '',
        latchtype: '',
        hingetype: '',
        stiffwt: '',
        indlen: '',
        indwidth: '',
        indbottom: '',
        indtop: '',
        isactive: ''
      });

      // Close the popup dialog
      handleClose();

      if (response?.data.key == "400") {
        errorToast(response?.data?.message)
      }
      else {
        //successToast(response.data);
        setSelectedItem(null);
        // setFormModelData({
        //   id: '',
        //   casemodel: '',
        //   innerdimns: '',
        //   outerdimns: '',
        //   boxwt: '',
        //   casterwheel: '',
        //   acceswt: '',
        //   epe: '',
        //   casenetwt1: '',
        //   xlpe: '',
        //   casenetwt2: '',
        //   wropecode: '',
        //   latchtype: '',
        //   hingetype: '',
        //   stiffwt: '',
        //   indlen: '',
        //   indwidth: '',
        //   indbottom: '',
        //   indtop: '',
        //   isactive: ''
        // });
        // handleClose();
      }
    }).catch((err) => {
      console.log("err", err)
    });
    // setFormData((prev) => ({
    //   ...prev,
    //   casemodelid: formModelData.id, // Store the case model ID in the main form
    // }));
    // Close the popup dialog
    setOpenDesignModelPopup(false);
  }

  const handleClosePopup = () => {
    setOpenDesignModelPopup(false);
    setOpenFusionPopup(false);
  };

  const handleReviewChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setReviewData(prevState => ({
      ...prevState,
      [name]: value
    }));

    if (name === 'refdrgthere') {
      if (value === '1') {
        setShowTextBoxreferdrg(true);
      } else {
        setShowTextBoxreferdrg(false);
      }
    }

    // Handle visibility for senspd
    if (name === 'senspd') {
      if (value === '1') {
        setShowTextBoxsenspd(true);
      } else {
        setShowTextBoxsenspd(false);
      }
    }
  };


  const handleCasemodelIdChange = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const selectedValue = parseFloat(event.target.value); // Convert string to number

    // Update formData with the selected model ID
    setFormData((prevFormData) => ({
      ...prevFormData,
      casemodelid: selectedValue,
    }));
  };


  const handleReviewChanges1 = (
    e: React.ChangeEvent<HTMLInputElement>,
    testaccessid: string,
    index: number
  ) => {
    const { checked } = e.target;
    console.log("Checkbox event target:", e.target);
    console.log("testaccessid:", testaccessid);
    console.log("index:", index);
    // Create a copy of reviewData
    const newEssentialTableRows = { ...reviewData };
    // Initialize listreqitems if it's undefined
    if (!newEssentialTableRows.listreqitems) {
      newEssentialTableRows.listreqitems = [];
    }
    // Convert testaccessid to number and validate
    const accessIdNum = Number(testaccessid);
    if (isNaN(accessIdNum) || accessIdNum <= 0) {
      console.error("Invalid testaccessid:", testaccessid);
      return;
    }
    // Create a copy of the existing listreqitems
    const updatedListReqItems = [...newEssentialTableRows.listreqitems];
    // Find the index of the item with the specified accessid
    const existingItemIndex = updatedListReqItems.findIndex(
      (item) => item.accessid === accessIdNum
    );
    // Get the correct drawid for revid
    const drawId = Number(newEssentialTableRows.drawid);
    if (isNaN(drawId)) {
      console.error("Invalid drawid:", newEssentialTableRows.drawid);
      return;
    }
    if (existingItemIndex === -1) {
      // Add a new item if it doesn't exist
      const newItem = {
        revid: drawId, // Use drawId here
        drawid: drawId,
        accessid: accessIdNum,
        status: checked ? 1 : 0,
      };
      updatedListReqItems.push(newItem);
      console.log("Added new item:", newItem);
    } else {
      // Update the existing item
      updatedListReqItems[existingItemIndex] = {
        ...updatedListReqItems[existingItemIndex],
        status: checked ? 1 : 0,
        revid: drawId // Use drawId here
      };
      console.log("Updated existing item:", updatedListReqItems[existingItemIndex]);
    }
    // Set the modified list back to newEssentialTableRows
    newEssentialTableRows.listreqitems = updatedListReqItems;
    // Update the state
    setReviewData(newEssentialTableRows);
    console.log("Updated reviewData:", newEssentialTableRows);
    // Update checked items state
    setCheckedItems((prevState) => ({
      ...prevState,
      [testaccessid]: checked,
    }));
  };
  const initializeRow = (id: number): ValidDetailsData => ({
    ifinsert: true,
    drawid: state.id,
    ecnid: 0,
    drawvalid: id,
    detailscheck: [],
    casedwg: 0,
    bom: 0,
    foamdwg: 0,
    printingdwg: 0,
    remarkslistdate: 0,
    eqpwt: "",
    casewt: "",
    accwt: "",
    foamwt: "",
    stiffwt: "",
    finalwt: "",
    con: new Date(0)
  });

  const handleValidationChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    index: number
  ) => {
    const { name, value, checked, type } = e.target;
    // Create a copy of the validDetailsData array
    const updatedValidDetailsData = [...validDetailsData];
    // Prepare the data to update with all required fields
    let res: ValidDetailsData = {
      ifinsert: true,
      drawid: state.id,
      ecnid: 0,
      drawvalid: id,  // This line ensures drawvalid is set correctly
      detailscheck: [],
      casedwg: 0,
      bom: 0,
      foamdwg: 0,
      printingdwg: 0,
      remarkslistdate: 0,
      eqpwt: "",
      casewt: "",
      accwt: "",
      foamwt: "",
      stiffwt: "",
      finalwt: "",
      con: new Date()
    };
    // If the row at the given index doesn't exist, create it with default values
    if (!updatedValidDetailsData[index]) {
      updatedValidDetailsData[index] = res;
    }
    // Update the row data based on the input type
    const currentRow = updatedValidDetailsData[index];
    if (type === 'text') {
      // Update text input fields
      updatedValidDetailsData[index] = {
        ...currentRow,
        [name]: value,
      };
    } else if (type === 'checkbox') {
      if (name === 'detailscheck') {
        // Manage detailscheck array
        let updatedDetailscheck = [...(currentRow.detailscheck || [])];
        if (checked) {
          if (!updatedDetailscheck.includes(value)) {
            updatedDetailscheck.push(value);
          }
        } else {
          updatedDetailscheck = updatedDetailscheck.filter(item => item !== value);
        }
        updatedValidDetailsData[index] = {
          ...currentRow,
          detailscheck: updatedDetailscheck,
        };
      } else {
        // Manage other checkbox fields (e.g., casedwg, bom, etc.)
        updatedValidDetailsData[index] = {
          ...currentRow,
          [name]: checked ? 1 : 0,
        };
      }
    }
    // Ensure drawvalid is not overwritten incorrectly
    updatedValidDetailsData[index] = {
      ...updatedValidDetailsData[index],
      drawvalid: id, // Explicitly set drawvalid
    };
    // Update the state with the modified array
    setvalidDetailsData(updatedValidDetailsData);
    console.log(updatedValidDetailsData, "validDetailsData");
  };

  const handleStatusChanges = (e: React.ChangeEvent<HTMLInputElement>, testaccessid: number, index: number) => {
    const { name, value } = e.target;

    console.log("Handle status changes:", { name, value, testaccessid, index });

    // Update the radioValuesverify state
    setRadioValuesverify(prevValues => ({
      ...prevValues,
      [testaccessid]: value,
    }));

    // Safely update the verifyData state
    setverifyData(prevData => {
      const newEssentialTableRows = { ...prevData };

      // Ensure the index is within bounds
      if (index >= 0 && index <= newEssentialTableRows.listrequirements.length) {
        const drawId = Number(newEssentialTableRows.drawid);
        const updatedRow = {
          ...newEssentialTableRows.listrequirements[index],
          accessid: testaccessid,
          verid: drawId,
          //drawid: drawId,
          [name]: value ? value : 0
        };

        // Use immutability to update the row
        const updatedListrequirements = [...newEssentialTableRows.listrequirements];
        updatedListrequirements[index] = updatedRow;
        console.log("Updated listrequirements array:", updatedListrequirements);
        return {
          ...newEssentialTableRows,
          listrequirements: updatedListrequirements,
        };
      } else {
        console.error("Index out of bounds:", index);
        return newEssentialTableRows;  // Return the current state to avoid breaking it
      }
    });
  };

  const handleOutputChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setoutputData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleVerifyChanges = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setverifyData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleDropdownChange = (e: SelectChangeEvent<unknown>, index: number) => {
    const { name, value } = e.target;
    setverifyData((prevState: any) => ({
      ...prevState,
      [name]: value,  // Update the specific field in verifyData
    }));
  };

  const [isPrintable, setIsPrintable] = useState(false);
  const [showImage, setShowImage] = useState(false)
  const [rows, setRows] = useState([{}]); // Initialize with one empty row
  const [selectedItem, setSelectedItem] = useState<FormData>(null);
  const [selectedItem1, setSelectedItem1] = useState<ReviewData>(null);
  const [selectedItem2, setSelectedItem2] = useState<VerifyData>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [checkboxState, setCheckboxState] = useState<{ [key: string]: boolean }>({});
  const [error, setError] = useState(false);

  const addRow = () => {
    setRows([...rows, {}]); // Add a new empty row
  };

  const handleClose = () => {
    // setFormData(prevState => {
    //   // Clear remarks field in listessdata
    //   const newListessdata = prevState.listessdata.map(item => ({
    //     ...item,
    //     remarks: ""
    //   }));
    //   // Clear only testaccessid and bit in listtest
    //   const newListtest = prevState.listtest.map(item => ({
    //     ...item,
    //     testaccessid: "",
    //     bit: ""
    //   }));
    //   // Clear only testaccessid and bit in listaccess
    //   const newListaccess = prevState.listaccess.map(item => ({
    //     ...item,
    //     testaccessid: "",
    //     bit: ""
    //   }));
    //   return {
    //     ...prevState,
    //     ifinsert: true,
    //     informid: 0,
    //     partno: "",
    //     drawid: state.id,
    //     ecnid: 0,
    //     refno: "",
    //     issueno: "",
    //     pagerevno: "",
    //     intype: "",
    //     //  casetype: "",
    //     // casemodelid: 0,
    //     // result: "",
    //     designby: "1",
    //     authby: "1",
    //     status: "Initiate",
    //     cby: "1",
    //     listessdata: newListessdata,  // Updated listessdata with cleared remarks
    //     listtest: newListtest,  // Updated listtest with cleared radio buttons
    //     listaccess: newListaccess  // Updated listaccess with cleared radio buttons
    //   };
    // });
    // setSelectedItem(null);
  };

  const handleClose1 = () => {
    setReviewData(prevState => ({
      ...prevState,
      listreqitems: [],
      ifinsert: true,
      drawid: state.id,
      enqdetid: 0,
      eqipsize: "",
      eqipwt: "",
      refdrgthere: null,
      referdrgtheretext: "",
      suplocspd: null,
      eqipposition: "",
      anysplmreq: null,
      eqliftbeltreq: null,
      nameplatereq: null,
      spltestreq: null,
      senspd: null,
      senspdtext: "",
      printcolor: "",
      prv: null,
      descnt: null,
      lhook: null,
      wheel: null,
      slings: null,
      flprov: null,
      lidhandle: null,
      locks: null,
      latchlookasst: null,
      huin: null,
      foam: "",
      casetype: "",
      caseresult: "",
      rofoam: "",
      roprintcolor: "",
      cby: "1",
      revno: "",
      revpoints: "",
      note: ""
    }));
    setCheckedItems({});
  };

  const handleClose2 = () => {
    setoutputData({
      ifinsert: true,
      drawid: state?.id || "",
      odate: new Date(0),
      cptdwgattachsc: new Date(0),  // Setting to a default Date value
      cptdwgattachhc: new Date(0),
      casedwgattchsc: new Date(0),
      casedwgattchhc: new Date(0),
      foamdwgattachsc: new Date(0),
      foamdwgattachhc: new Date(0),
      scnprtdetattachsc: new Date(0),
      scnprtdetattachhc: new Date(0),
      billofmatattachsc: new Date(0),
      billofmatattachhc: new Date(0),
      stfdwgattachsc: new Date(0),
      stfdwgattachhc: new Date(0),
      prddevplnaatchsc: new Date(0),
      prddevplnaatchhc: new Date(0),
      dfmeaattachsc: new Date(0),
      dfmeaattachhc: new Date(0),
      cby: "1"
    });
    setCheckboxes({
      conceptDwgAttached: false,
      casedrawingattached: false,
      foamdrawingattached: false,
      screenprintingdetailsattached: false,
      billmaterialattached: false,
      stiffnerdrawingattached: false,
      proddevplanattached: false,
      dfmeaattached: false
    });
  };

  const handleClose3 = () => {
    setverifyData(prevState => {
      console.log("Previous State:", prevState);
      const newListrequire = prevState.listrequirements.map(item => ({
        ...item,
        accessid: null,
        status: null
      }));
      const updatedState = {
        ...prevState,
        listrequirements: newListrequire,
        ifinsert: true,
        drawid: state.id,
        enqdetid: 0,
        cieqwidth: "",
        cieqheight: "",
        cieqlen: "",
        fdwgbtmwidth: "",
        fdwgbtmheight: "",
        fdwgbtmlen: "",
        fdwgtopwidth: "",
        fdwgtopheight: "",
        fdwgtoplen: "",
        fcutbtmwidth: "",
        fcutbtmheight: "",
        fcutbtmlen: "",
        fcuttopwidth: "",
        fcuttopheight: "",
        fcuttoplen: "",
        cdexterwidth: "",
        cdexterheight: "",
        cdexterlen: "",
        cdinterwidth: "",
        cdinterheight: "",
        cdinterlen: "",
        handle: "",  // Clear the handle field
      };
      console.log("Updated State:", updatedState);
      return updatedState;
    });
    setSelectedItem2(null);
  };

  const handleClose4 = () => {
    setvalidDetailsData([
      {
        ifinsert: true,
        drawid: state.id, // Reset to initial state values
        ecnid: 0,
        drawvalid: 0,
        detailscheck: [],
        casedwg: 0,
        bom: 0,
        foamdwg: 0,
        printingdwg: 0,
        remarkslistdate: 0,
        eqpwt: "",
        casewt: "",
        accwt: "",
        foamwt: "",
        stiffwt: "",
        finalwt: "",
        con: new Date(0)
      },
    ]);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;

    if (name === "attch" && event.target.files) {
      const file = event.target.files[0];
      const formdata = new FormData();
      formdata.append('file', file);

      apiService.uploadFiles(formdata)
        .then(response => {
          const url = response.data; // Assuming response.data contains the file URL

          // Update the formData with the file URL
          setFormData(prevFormData => ({
            ...prevFormData,
            attch: url,
          }));
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  };

  const [isSaved, setIsSaved] = useState(false);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);

  const [isProceedClicked, setIsProceedClicked] = useState(false);

  const handleProceedClick = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = formData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.isproceed(dataToSubmit);
      setTableData(prevData =>
        prevData.map(data => ({ ...data, isproceed: 1 }))
      );
      setIsProceedClicked(true);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };
  //FOR INPUT FORM SUBMIT
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Save button clicked"); // Log to verify button click

    // Check if "casetype" is selected
    if (!formData.casetype) {
      setError(true);
      return; // Stop form submission if validation fails
    }

    // if (!formData.casemodelid) {
    //   setError(true);
    //   return; // Stop form submission if validation fails
    // }

    if (!formData.result) {
      setError(true);
      return; // Stop form submission if validation fails
    }

    try {
      const dataToSubmit = formData;
      console.log("dataToSubmit", dataToSubmit);

      const response = await apiService.postdesigndata(dataToSubmit);

      // Log and display success message
      console.log("Form submitted successfully:", response.data);
      toast.success("Input Form registered successfully");

      // Set the form as saved and scroll to top
      setIsSaved(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      // Log different types of errors
      if (error.response) {
        console.error("Error response:", error.response);
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);
      } else if (error.request) {
        console.error("Error request:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };

  const printRef = useRef(null);
  const contentToPrint: any = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => setShowImage(true),
    onBeforePrint: () => setShowImage(true),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  //FOR REVIEW SUBMIT
  const handleSubmitReview = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = reviewData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postreviewdata(dataToSubmit);
      // Handle response
      console.log('Review Form registered successfully', response.data);
      successToast(response.data);
      setIsSaved(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  //FOR OUTPUT FORM SUBMIT
  const handleSubmitOutput = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = outputData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postoutputdata(dataToSubmit);
      // Handle response
      console.log('Output Form registered successfully', response.data);
      successToast(response.data);
      // console.log('setReviewData',value);
      setIsSaved(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  //FOR VERIFICATION FORM SUBMIT
  const handleSubmitVerification = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = verifyData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postverifydata(dataToSubmit);
      // Handle response
      console.log('Verification Form registered successfully', response.data);
      successToast(response.data);
      // console.log('setReviewData',value);
      setIsSaved(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  //FOR DRAWING VALIDATION FORM SUBMIT
  const handleSubmitValidation = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = validDetailsData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.postdrawvaliddata(dataToSubmit);
      // Handle response
      console.log('Drawing Validation Form registered successfully', response.data);
      successToast(response.data);
      // console.log('setReviewData',value);
      setIsSaved(true);
      window.scrollTo(0, 0);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  const handleSubmitToMarket = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = formData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.generatempnno(dataToSubmit);
      // Handle response
      console.log('Successfully Submitted to Marketing Team', response.data);

      // Success toast can also be used for more detailed data
      successToast(response.data);

      // Delay navigation to give time for toast to display
      setTimeout(() => {
        navigate('/Design/DesignFollowup');
      }, 5000);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  const handleSubmitToClarify = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const dataToSubmit = formData;
      console.log("dataToSubmit", dataToSubmit);
      const response = await apiService.req_clarify(dataToSubmit);
      setTableData(prevData =>
        prevData.map(data => ({ ...data, isproceed: 0 }))
      );
      setIsProceedClicked(false); // Optionally reset the isProceedClicked flag
      // Handle response
      console.log('Requested for Clarification', response.data);
      // Success toast can also be used for more detailed data
      successToast(response.data);
      setTimeout(() => {
        navigate('/Design/DesignFollowup');
      }, 5000);
    } catch (error: any) {
      if (error.response) {
        console.error('Error response:', error.response);
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error message:', error.message);
      }
      console.error('Error config:', error.config);
    }
  };

  function formatDate(dateString) {
    if (!dateString) {
      return ''; // Return empty string for null or undefined
    }
    const date = new Date(dateString);
    const day = ("0" + date.getDate()).slice(-2); // Get the day and ensure two digits
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Get the month and ensure two digits
    const year = date.getFullYear(); // Get the full year
    return `${day}-${month}-${year}`; // Format as dd-MM-yyyy
  }

  return (
    <>
      <Helmet>
        <title>Design & Development - Design</title>
      </Helmet>
      <br></br>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <CardHeader title="Design and Development" />
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', color: 'white' }}>
                  <Tab
                    label="Back"
                    onClick={() => {
                      if (isSaved) {
                        // Navigate to the form page when saved
                        setValue(0); // Navigate back to the form page
                        setIsSaved(false);
                        window.scrollTo(0, 0);
                      } else {
                        // Navigate to the DesignFollowup page when not saved
                        navigate('/Design/DesignFollowup');
                      }
                    }}
                    sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                  />
                </div>
              </div>
              <Divider />
              <CardContent>
                {!isSaved && (
                  <>
                    {tableData.map((item, index) => (
                      <div key={item.id} style={{
                        marginBottom: '20px', display: 'flex', gap: '20px', marginLeft: '20px',
                        color: "#a50101",
                      }} className="panel no-print">
                        <div><strong style={{ color: "#000" }}>Enquiry No : </strong> <b style={{ color: "" }}>{item.enqno}</b> </div>
                        <div><strong style={{ color: "#000" }}>Enquiry Date :</strong> <b>{item.enqdate}</b></div>
                        <div><strong style={{ color: "#000" }}>Customer :</strong> <b>{item.cusname}</b></div>
                        <div><strong style={{ color: "#000" }}>Requirement :</strong><b>{item.req}</b></div>
                      </div>

                    ))}

                  </>
                )}
                <Box sx={{ width: '100%' }}>
                  {!isSaved && (
                    <div className="tabs-container no-print">
                      <Tabs
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="primary"
                        indicatorColor="primary"
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Input Form" sx={{ "&:hover": { color: "white" } }} />
                        {/* <Tab label="Planning" sx={{ "&:hover": { color: "white" } }} /> */}
                        <Tab label="Review" sx={{ "&:hover": { color: "white" } }} />
                        {/* <Tab label="Output" sx={{ "&:hover": { color: "white" } }} /> */}
                        <Tab label="Verification" sx={{ "&:hover": { color: "white" } }} />
                        <Tab label="Drawing Validation" sx={{ "&:hover": { color: "white" } }} />
                      </Tabs>
                    </div>
                  )}
                  <TabPanel value={value} index={0}>
                    <div className="main-content" >
                      {!isSaved ? (
                        <div className="display-area">
                          <div className="panel">
                            <Typography variant="h6" gutterBottom >
                              <b style={{ color: "#2801ff" }}><u>EQUIPMENT DETAILS</u></b>
                            </Typography>
                            <Grid item xs={12}>
                              <Paper>
                                <TableContainer>
                                  <Table className="">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{ textAlign: 'center' }} ><b>SL. NO</b></TableCell>
                                        <TableCell style={{ textAlign: 'center' }}><b>DESIGN DATA DETAILS</b></TableCell>
                                        <TableCell style={{ textAlign: 'center' }}><b>GIVEN DETAILS & FILES</b></TableCell>
                                        <TableCell style={{ textAlign: 'center' }}><b>REMARKS</b></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>

                                      {/* {console.log(formData?.listessdata, "essdatanew")} */}
                                      {formData?.listessdata?.map((row: any, index: number) => (
                                        <TableRow key={row.essdataid}>
                                          <TableCell>{index + 1}</TableCell>
                                          <TableCell>{row.name}</TableCell>
                                          <TableCell>   <div>
                                            {/* Always display the compliance value */}
                                            <div>{row.compliance}</div>
                                            {/* Conditionally display the attach link only if attach is present */}
                                            {row.attach && (
                                              <a
                                                href={`https://docs.google.com/viewer?url=${encodeURIComponent(row.attach)}&embedded=true`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                  color: hoverIndex === index ? '#0056b3' : '#007bff', // Change color on hover for specific row
                                                  textDecoration: hoverIndex === index ? 'underline' : 'none', // Add underline on hover for specific row
                                                }}
                                                onMouseEnter={() => setHoverIndex(index)} // Set hover for this specific row
                                                onMouseLeave={() => setHoverIndex(null)}  // Reset hover when mouse leaves
                                              >
                                                View
                                              </a>
                                            )}
                                          </div></TableCell>
                                          <TableCell>
                                            <TextField
                                              required
                                              name="remarks"
                                              value={row.remarks}
                                              onChange={(e) => handleRemarksChange(row.essdataid, index, e)}
                                              variant="outlined"
                                              size="small"
                                              inputProps={{ maxLength: 200 }}
                                              fullWidth
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Paper>
                            </Grid>
                            {tableData.some(data => data.isproceed === null) && (
                              <DialogActions>
                                <Button onClick={handleSubmitToClarify} color="primary" variant='contained' className="btn-print">
                                  Request for Clarification
                                </Button>
                                <Button onClick={handleProceedClick} variant='contained' color="primary">
                                  Proceed
                                </Button>
                              </DialogActions>
                            )}
                          </div>
                          {(isProceedClicked || tableData.some(data => data.isproceed === 1) || tableData.some(data => data.isproceed === 0)) && (
                            <>
                              <div className="display-area">
                                <div className="panel">
                                  <Typography variant="h6" gutterBottom>
                                    <b style={{ color: "#2801ff" }}><u>TEST DETAILS (AS PER MIL-STD-810G/JSS-0253-01/JSG 0102 & AS PER SAARC QAP)</u></b>
                                  </Typography>
                                  <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                      <Paper>
                                        <TableContainer style={{ height: "350px", overflowY: "auto" }}>
                                          <Table className="table-body">
                                            <TableHead>
                                              <TableRow className="table-header">
                                                <TableCell><b>SL. NO</b></TableCell>
                                                <TableCell><b>NAME OF THE TEST</b></TableCell>
                                                <TableCell><b>Yes / No</b></TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>

                                              {formData?.listtest?.map((row: any, index: number) => (
                                                <TableRow key={row.testaccessid}>
                                                  <TableCell>{index + 1}</TableCell>
                                                  <TableCell>{row.name}</TableCell>
                                                  <TableCell style={{ width: "150px" }}>
                                                    <FormControl component="fieldset">
                                                      <RadioGroup
                                                        //value={radioValues[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                        value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                                        onChange={(e) => {
                                                          handleBitChange(row.id, index, e);
                                                        }}
                                                        row
                                                        className="compact-radio-group"
                                                      >
                                                        <FormControlLabel value="1" control={<Radio size="small" />} label="Y" />
                                                        <FormControlLabel value="0" control={<Radio size="small" />} label="N" />
                                                      </RadioGroup>
                                                    </FormControl>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </Paper>
                                    </Grid>
                                    <Grid item xs={6}  >
                                      <TableContainer style={{ height: "350px", overflowY: "auto" }}>
                                        <Table className="table-body">
                                          <TableHead>
                                            <TableRow className="table-header">
                                              <TableCell><b>SL. NO</b></TableCell>
                                              <TableCell><b>NAME OF THE ACCESSORIES</b></TableCell>
                                              <TableCell><b>APPLICABLE Yes / No</b></TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>

                                            {formData?.listaccess?.map((row: any, index: number) => (
                                              <TableRow key={row.testaccessid}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell style={{ width: "150px" }}>
                                                  <FormControl component="fieldset">
                                                    <RadioGroup
                                                      //value={radioValues1[row.id] ?? (row.bit === 'True' || row.bit === true ? "1" : row.bit === 'False' || row.bit === false ? "0" : "")}
                                                      value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                                      onChange={(e) => handleBitChange1(row.id, index, e)}
                                                      row
                                                      className="compact-radio-group"
                                                    >
                                                      <FormControlLabel name="bit" value="1" control={<Radio size="small" />} label="Y" />
                                                      <FormControlLabel name="bit" value="0" control={<Radio size="small" />} label="N" />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Grid>
                                  </Grid>
                                </div>
                              </div>
                              <Grid>
                                <div className="display-area">
                                  <div className="panel">
                                    <Typography variant="h6" gutterBottom>
                                      <b style={{ color: "#2801ff" }}><u>CASE SELECTION</u></b>
                                    </Typography>
                                    <Grid item>
                                      <Table className="casetable">
                                        <TableHead>
                                          <TableRow className="table-header">
                                            <TableCell><b>TYPE</b></TableCell>
                                            <TableCell><b>MODEL</b></TableCell>
                                            <TableCell><b>RESULT</b></TableCell>
                                            <TableCell><b>PART NO</b></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>

                                          <TableRow>
                                            <TableCell>
                                              <Box display="flex" flexDirection="column" alignItems="flex-start">
                                                <TextField
                                                  required
                                                  select
                                                  id="casetype"
                                                  name="casetype"
                                                  fullWidth
                                                  value={formData?.casetype}
                                                  onChange={handleInputChanges}
                                                  error={error && !formData.casetype} // Show error if empty
                                                  helperText={error && !formData.casetype ? "Please select a case type" : ""}
                                                >
                                                  <MenuItem value="Existing Case">Existing Case</MenuItem>
                                                  <MenuItem value="NPD Case">NPD Case</MenuItem>
                                                </TextField>
                                                {formData?.casetype === "NPD Case" && (
                                                  <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                                                    <a
                                                      href="#"
                                                      onClick={(e) => {
                                                        e.preventDefault(); // Prevent default anchor behavior
                                                        setOpenDesignModelPopup(true); // Open the dialog
                                                      }}
                                                      style={{ color: '#448EE4', textDecoration: 'underline', cursor: 'pointer' }}
                                                    >
                                                      Create Model
                                                    </a>
                                                  </Box>
                                                )}
                                              </Box>
                                            </TableCell>
                                            <TableCell>
                                              {/* {formData?.casetype === 'Existing Case' && ( */}
                                              <TextField
                                                required
                                                select
                                                id="casemodelid"
                                                name="casemodelid"
                                                fullWidth
                                                // value={formData?.casemodelid}
                                                value={insertmodelid !== null ? insertmodelid : formData?.casemodelid}
                                                onChange={handleCasemodelIdChange}
                                              //error={error && !formData.casemodelid} // Show error if empty
                                              // helperText={error && !formData.casemodelid ? "Please select a model" : ""}
                                              >
                                                {console.log(insertmodelid, "insertmodelid")}
                                                {model.map((option) => (
                                                  <MenuItem key={option.id}
                                                    value={insertmodelid !== null ? insertmodelid : option.id}>
                                                    {option.casemodel}
                                                  </MenuItem>
                                                ))}
                                              </TextField>
                                              {/* )} */}
                                              {/* {formData?.casetype === 'NPD Case' && ( */}
                                              <Dialog open={openDesignModelPopup} onClose={handleClosePopup}>
                                                {/* Add your Design Model form here */}
                                                <DialogContent>
                                                  <Typography variant="h6" gutterBottom>
                                                    <b>Create Model</b>
                                                  </Typography>
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        required
                                                        margin="dense"
                                                        id="casemodel"
                                                        label="Case Model"
                                                        name="casemodel"
                                                        fullWidth
                                                        value={formModelData?.casemodel}
                                                        onChange={handleModelChange}
                                                        error={!!errors?.casemodel} // Highlight the field if there's an error
                                                        helperText={errors?.casemodel ? "Case Model is required" : ""}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                        <label style={{ display: 'block', marginBottom: '8px' }}>Case Inner Dimns. in mm</label>
                                                        <div style={{ display: 'flex', gap: '8px' }}>
                                                          <TextField
                                                            required
                                                            label="Length"
                                                            value={formModelData.indlen || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indlen')}
                                                          />
                                                          <TextField
                                                            required
                                                            label="Width"
                                                            value={formModelData.indwidth || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indwidth')}
                                                          />
                                                          <TextField
                                                            required
                                                            label="Bottom"
                                                            value={formModelData.indbottom || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indbottom')}
                                                          />
                                                          <TextField
                                                            required
                                                            label="Top"
                                                            value={formModelData.indtop || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indtop')}
                                                          />
                                                        </div>
                                                      </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        required
                                                        margin="dense"
                                                        id="outerdimns"
                                                        label="Case Outer Dimns. in mm"
                                                        name="outerdimns"
                                                        fullWidth
                                                        value={formModelData?.outerdimns}
                                                        onChange={handleModelChange}
                                                        error={!!errors?.outerdimns}
                                                        helperText={errors?.outerdimns ? "Case Outer Dimns. is required" : ""}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="boxwt"
                                                        label="Box Wt."
                                                        name="boxwt"
                                                        fullWidth
                                                        value={formModelData?.boxwt}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="casterwheel"
                                                        label="Caster Wheel"
                                                        name="casterwheel"
                                                        fullWidth
                                                        value={formModelData?.casterwheel}
                                                        onChange={handleModelChange}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="acceswt"
                                                        label="Access Wt."
                                                        name="acceswt"
                                                        fullWidth
                                                        value={formModelData?.acceswt}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="epe"
                                                        label="EPE"
                                                        name="epe"
                                                        fullWidth
                                                        value={formModelData?.epe}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="casenetwt1"
                                                        label="Case Netwt.1"
                                                        name="casenetwt1"
                                                        fullWidth
                                                        value={formModelData?.casenetwt1}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, ''); // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="xlpe"
                                                        label="XLPE"
                                                        name="xlpe"
                                                        fullWidth
                                                        value={formModelData?.xlpe}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="casenetwt2"
                                                        label="Case Netwt.2"
                                                        name="casenetwt2"
                                                        fullWidth
                                                        value={formModelData?.casenetwt2}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="wropecode"
                                                        label="W rope Code"
                                                        name="wropecode"
                                                        fullWidth
                                                        value={formModelData?.wropecode}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="latchtype"
                                                        label="Latch Type"
                                                        name="latchtype"
                                                        fullWidth
                                                        value={formModelData?.latchtype}
                                                        onChange={handleModelChange}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="hingetype"
                                                        label="Hinge Type"
                                                        name="hingetype"
                                                        fullWidth
                                                        value={formModelData?.hingetype}
                                                        onChange={handleModelChange}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="stiffwt"
                                                        label="Stiff Wt."
                                                        name="stiffwt"
                                                        fullWidth
                                                        value={formModelData?.stiffwt}
                                                        onChange={handleModelChange}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        select
                                                        margin="dense"
                                                        label="Status"
                                                        name="isactive"
                                                        fullWidth
                                                        value={formModelData?.isactive === '' ? '' : Number(formModelData?.isactive)}
                                                        onChange={handleModelChange}
                                                      >
                                                        {status.map((option) => (
                                                          <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                          </MenuItem>
                                                        ))}
                                                      </TextField>
                                                    </Grid>
                                                  </Grid>
                                                </DialogContent>
                                                <DialogActions>
                                                  <Button onClick={handleClosePopup} color="primary">
                                                    Close
                                                  </Button>
                                                  <Button
                                                    onClick={() => {
                                                      submit(formModelData); // Save the form data
                                                      setIsNewEntry(true); // Mark as new entry from popup
                                                      setFormData((prev) => ({
                                                        ...prev,
                                                        casemodelid: formModelData.id, // Store new case model ID
                                                        //casemodel: formModelData.casemodel // Optional: store name if needed
                                                      }));
                                                      setinsertmodelid(formModelData.id);
                                                      handleClosePopup(); // Close the popup after saving
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                  >
                                                    {selectedItem ? 'Update' : 'Save'}
                                                  </Button>

                                                </DialogActions>
                                              </Dialog>
                                              {/* )} */}
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                required
                                                select
                                                id="result"
                                                name="result"
                                                fullWidth
                                                value={formData?.result}
                                                onChange={handleInputChanges}
                                                error={error && !formData.result} // Show error if empty
                                                helperText={error && !formData.result ? "Please select a result" : ""}
                                              >
                                                <MenuItem value="Can be used">Can be used</MenuItem>
                                                <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                                {/* <MenuItem value="Top NPD">Top NPD</MenuItem>
                                                <MenuItem value="Bottom NPD">Bottom NPD</MenuItem> */}
                                                <MenuItem value="To Be Made">To Be Made</MenuItem>
                                              </TextField>
                                              {formData?.result === "Can Be Fusioned" && (
                                                <Box display="flex" justifyContent="flex-end" width="100%" mt={1}>
                                                  <a
                                                    href="#"
                                                    onClick={(e) => {
                                                      e.preventDefault(); // Prevent default anchor behavior
                                                      setOpenFusionPopup(true); // Open the dialog
                                                    }}
                                                    style={{ color: '#448EE4', textDecoration: 'underline', cursor: 'pointer' }}
                                                  >
                                                    Modify Model
                                                  </a>
                                                </Box>
                                              )}
                                              <Dialog open={openFusionPopup} onClose={handleClosePopup}>
                                                <DialogContent>
                                                  <Typography variant="h6" gutterBottom>
                                                    <b>Modify Model</b>
                                                  </Typography>
                                                  <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                      {/* Add your Design Model form here */}
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={isBottomEditable}
                                                            onChange={() => handleCheckboxChange('indbottom')}
                                                          />
                                                        }
                                                        label="Bottom"
                                                      />
                                                      <TextField
                                                        required
                                                        margin="dense"
                                                        id="indbottom"
                                                        //label="Bottom"
                                                        name="indbottom"
                                                        fullWidth
                                                        value={`${formModelData.indlen || ''}*${formModelData.indwidth || ''}*${formModelData.indbottom || ''}`}
                                                        onChange={(e) => handleModelChange({ target: { name: 'indbottom', value: e.target.value.split('*').pop() } })}
                                                        InputProps={{
                                                          readOnly: !isBottomEditable
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={isTopEditable}
                                                            onChange={() => handleCheckboxChange('indtop')}
                                                          />
                                                        }
                                                        label="Top"
                                                      />
                                                      <TextField
                                                        required
                                                        margin="dense"
                                                        id="indtop"
                                                        //label="Top"
                                                        name="indtop"
                                                        fullWidth
                                                        value={`${formModelData.indlen || ''}*${formModelData.indwidth || ''}*${formModelData.indtop || ''}`}
                                                        onChange={(e) => handleModelChange({ target: { name: 'indtop', value: e.target.value.split('*').pop() } })}
                                                        InputProps={{
                                                          readOnly: !isTopEditable
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        required
                                                        margin="dense"
                                                        id="casemodel"
                                                        label="Case Model"
                                                        name="casemodel"
                                                        fullWidth
                                                        value={formModelData?.casemodel}
                                                        onChange={handleModelChange}
                                                        error={!!errors?.casemodel} // Highlight the field if there's an error
                                                        helperText={errors?.casemodel ? "Case Model is required" : ""}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                        <label style={{ display: 'block', marginBottom: '8px' }}>Case Inner Dimns. in mm</label>
                                                        <div style={{ display: 'flex', gap: '8px' }}>
                                                          <TextField
                                                            required
                                                            label="Length"
                                                            value={formModelData.indlen || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indlen')}
                                                          />
                                                          <TextField
                                                            required
                                                            label="Width"
                                                            value={formModelData.indwidth || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indwidth')}
                                                          />
                                                          <TextField
                                                            required
                                                            label="Bottom"
                                                            value={formModelData.indbottom || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indbottom')}
                                                          />
                                                          <TextField
                                                            required
                                                            label="Top"
                                                            value={formModelData.indtop || ''}
                                                            onChange={(e) => handleFieldChange(e, 'indtop')}
                                                          />
                                                        </div>
                                                      </div>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        required
                                                        margin="dense"
                                                        id="outerdimns"
                                                        label="Case Outer Dimns. in mm"
                                                        name="outerdimns"
                                                        fullWidth
                                                        value={formModelData?.outerdimns}
                                                        onChange={handleModelChange}
                                                        error={!!errors?.outerdimns}
                                                        helperText={errors?.outerdimns ? "Case Outer Dimns. is required" : ""}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="boxwt"
                                                        label="Box Wt."
                                                        name="boxwt"
                                                        fullWidth
                                                        value={formModelData?.boxwt}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="casterwheel"
                                                        label="Caster Wheel"
                                                        name="casterwheel"
                                                        fullWidth
                                                        value={formModelData?.casterwheel}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="acceswt"
                                                        label="Access Wt."
                                                        name="acceswt"
                                                        fullWidth
                                                        value={formModelData?.acceswt}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="epe"
                                                        label="EPE"
                                                        name="epe"
                                                        fullWidth
                                                        value={formModelData?.epe}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="casenetwt1"
                                                        label="Case Netwt.1"
                                                        name="casenetwt1"
                                                        fullWidth
                                                        value={formModelData?.casenetwt1}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="xlpe"
                                                        label="XLPE"
                                                        name="xlpe"
                                                        fullWidth
                                                        value={formModelData?.xlpe}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="casenetwt2"
                                                        label="Case Netwt.2"
                                                        name="casenetwt2"
                                                        fullWidth
                                                        value={formModelData?.casenetwt2}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, '');  // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="wropecode"
                                                        label="W rope Code"
                                                        name="wropecode"
                                                        fullWidth
                                                        value={formModelData?.wropecode}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, ''); // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="latchtype"
                                                        label="Latch Type"
                                                        name="latchtype"
                                                        fullWidth
                                                        value={formModelData?.latchtype}
                                                        onChange={handleModelChange}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="hingetype"
                                                        label="Hinge Type"
                                                        name="hingetype"
                                                        fullWidth
                                                        value={formModelData?.hingetype}
                                                        onChange={handleModelChange}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        margin="dense"
                                                        id="stiffwt"
                                                        label="Stiff Wt."
                                                        name="stiffwt"
                                                        fullWidth
                                                        value={formModelData?.stiffwt}
                                                        onChange={handleModelChange}
                                                        InputLabelProps={{
                                                          shrink: true, // Keeps the label above the input when there’s a value
                                                        }}
                                                        onInput={(e) => {
                                                          const input = e.target as HTMLInputElement;
                                                          input.value = input.value.replace(/[^0-9.]/g, ''); // Allow only digits
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                      <TextField
                                                        select
                                                        margin="dense"
                                                        label="Status"
                                                        name="isactive"
                                                        fullWidth
                                                        value={formModelData?.isactive === '' ? '' : Number(formModelData?.isactive)}
                                                        onChange={handleModelChange}
                                                      >
                                                        {status.map((option) => (
                                                          <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                          </MenuItem>
                                                        ))}
                                                      </TextField>
                                                    </Grid>
                                                  </Grid>
                                                </DialogContent>
                                                <DialogActions>
                                                  <Button onClick={handleClosePopup} color="primary">
                                                    Close
                                                  </Button>
                                                  <Button
                                                    onClick={() => {
                                                      submit(formModelData); // Save the form data
                                                      setIsNewEntry(true); // Mark as new entry from popup
                                                      setFormData((prev) => ({
                                                        ...prev,
                                                        casemodelid: formModelData.id, // Store new case model ID
                                                        //casemodel: formModelData.casemodel // Optional: store name if needed
                                                      }));
                                                      handleClosePopup(); // Close the popup after saving
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                  >
                                                    {selectedItem ? 'Update' : 'Save'}
                                                  </Button>
                                                </DialogActions>
                                              </Dialog>
                                            </TableCell>
                                            <TableCell>
                                              <TextField
                                                required
                                                name="partno"
                                                value={formData?.partno}
                                                onChange={handleInputChanges}
                                                //disabled={formData.disablePartno}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                              />
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                              <DialogActions>
                                <Button onClick={handleClose} color="primary" variant='contained' className="btn-print">
                                  Clear
                                </Button>
                                <Button onClick={handleSubmit} variant='contained' color="primary">
                                  Save
                                </Button>
                              </DialogActions>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="display-area" id="printable-content" style={{ marginTop: '-40px' }} ref={contentToPrint}>
                          <div className="display-area1">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <img
                                src={require('../assets/logosaarc.png')}
                                alt="Logo"
                                style={{ width: '60px', height: '60%', marginTop: '23px', marginBottom: '30px' }}
                              />
                              <div style={{ flex: 1, textAlign: 'center' }}>
                                <Typography gutterBottom style={{ marginBottom: '20px' }}>
                                  <h2><b>INPUT FORM</b></h2>
                                </Typography>
                              </div>
                            </div>
                            {tableData.map((item, index) => (
                              <div key={item.id} style={{ marginBottom: "20px" }}>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Enquiry No :</strong>
                                  <span className="enquiry-data">{item.enqno}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Enquiry Date :</strong>
                                  <span className="enquiry-data">{item.enqdate}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Customer :</strong>
                                  <span className="enquiry-data">{item.cusname}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Requirement :</strong>
                                  <span className="enquiry-data">{item.req}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Date :</strong>
                                  <span className="enquiry-data">{formattedDatedrawdate}</span>
                                </div>
                              </div>
                            ))}
                            <div>
                              <Typography variant="h6" gutterBottom>
                                <b style={{ color: "#a50101" }}><u>EQUIPMENT DETAILS</u></b>
                              </Typography>
                              <Table className="table-body">
                                <TableBody>
                                  <TableRow>
                                    <TableCell><b>SL. NO</b></TableCell>
                                    <TableCell><b>DESIGN DATA DETAILS</b></TableCell>
                                    <TableCell><b>GIVEN DETAILS & FILES</b></TableCell>
                                    <TableCell><b>REMARKS</b></TableCell>
                                  </TableRow>
                                  {formData?.listessdata?.map((row, index) => (
                                    <TableRow key={row.essdataid}>
                                      <TableCell>{index + 1}</TableCell>
                                      <TableCell>{row.name}</TableCell>
                                      <TableCell>{row.compliance}</TableCell>
                                      <TableCell>{row.remarks}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                            <div>
                              <br></br>
                              <div className='test-print'>
                                <Typography variant="h6" gutterBottom >
                                  <b style={{ color: "#a50101" }}><u>TEST DETAILS (AS PER MIL-STD-810G/JSS-0253-01/JSG 0102 & AS PER SAARC QAP)</u></b>
                                </Typography>
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <TableContainer>
                                      <Table className="table-body">
                                        <TableHead>
                                          <TableRow className="table-header">
                                            <TableCell><b>SL. NO</b></TableCell>
                                            <TableCell><b>NAME OF THE TEST</b></TableCell>
                                            <TableCell><b>Y/N</b></TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>

                                          {formData?.listtest?.map((row, index) => (
                                            <TableRow key={row.testaccessid}>
                                              <TableCell>{index + 1}</TableCell>
                                              <TableCell>{row.name}</TableCell>
                                              {/* Display Yes/No based on the stored 0 or 1 */}
                                              <TableCell>{row.bit === '1' ? 'Yes' : 'No'}</TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>

                                  </Grid>
                                  <Grid item xs={6}>
                                    <Table className="table-body">
                                      <TableBody>
                                        <TableRow className="table-header">
                                          <TableCell><b>SL. NO</b></TableCell>
                                          <TableCell><b>NAME OF THE ACCESSORIES</b></TableCell>
                                          <TableCell><b>APPLICABLE Y/N</b></TableCell>
                                        </TableRow>
                                        {formData?.listaccess?.map((row, index) => (
                                          <TableRow key={row.testaccessid}>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            {/* Display Yes/No based on the stored 0 or 1 */}
                                            <TableCell>{row.bit === '1' ? 'Yes' : 'No'}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                            <br></br>
                            <div>
                              <Typography variant="h6" gutterBottom>
                                <b><u>CASE SELECTION</u></b>
                              </Typography>
                              <Table className="table-body">
                                <TableBody>
                                  <TableRow>
                                    <TableCell><b>TYPE</b></TableCell>
                                    <TableCell><b>MODEL</b></TableCell>
                                    <TableCell><b>RESULT</b></TableCell>
                                    <TableCell><b>PART NO</b></TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>{formData?.casetype}</TableCell>
                                    <TableCell>{model.find(m => m.id === formData?.casemodelid)?.casemodel}</TableCell>
                                    <TableCell>{formData?.result}</TableCell>
                                    <TableCell>{formData?.partno}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </div>
                          </div>
                          <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                            <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                            <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                          </div>
                          <Button onClick={() => {
                            handlePrint(null, () => contentToPrint.current);
                          }} style={{ marginTop: '20px', marginLeft: '10px' }} className="no-print"
                            variant="contained"
                            color="primary"
                            startIcon={<FontAwesomeIcon icon={faPrint} />}>
                            Print
                          </Button>
                          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '675px', color: 'white', marginTop: '-40px' }}>
                            <Tab
                              label="Back"
                              onClick={() => {
                                setValue(0); // Navigate back to the form page
                                setIsSaved(false);
                                window.scrollTo(0, 0);
                              }}
                              sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                            />
                          </div> */}
                        </div>
                      )}
                      <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                    </div>
                  </TabPanel>

                  {/* FOR PLANNING  */}
                  {/* <TabPanel value={value} index={1}>
                  </TabPanel> */}

                  {/* FOR REVIEW */}
                  <TabPanel value={value} index={1}>
                    <div>
                      {!isSaved ? (
                        <div>
                          <div className="panel">
                            <div className="main-heading" style={{ color: "#2801ff" }}>INPUT DETAILS</div>
                            <div className="content-grid">
                              <div className="column">
                                <div>
                                  {tableData.map((item, index) => (
                                    <div key={item.id} style={{ marginBottom: "20px" }}>
                                      <TextField
                                        margin="dense"
                                        id="revno"
                                        label="I/P NO"
                                        name="revno"
                                        value={item.enqno}
                                        fullWidth
                                        onChange={handleReviewChanges}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                  ))}

                                  {sizewtdata.map((item, index) => (

                                    <div key={item.id} style={{ marginTop: '-5px' }}>

                                      <TextField
                                        margin="dense"
                                        id="eqipsize"
                                        label="EQUIPMENT SIZE"
                                        name='eqipsize'
                                        value={item.outerdimns}
                                        fullWidth
                                        onChange={handleReviewChanges}
                                        inputProps={{ maxLength: 45 }}
                                        InputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                      <div style={{ marginTop: '15px' }}>
                                        <TextField
                                          margin="dense"
                                          id="eqipwt"
                                          label="EQUIPMENT WEIGHT"
                                          name='eqipwt'
                                          value={item?.casenetwt2}
                                          fullWidth
                                          onChange={handleReviewChanges}
                                          inputProps={{ maxLength: 45 }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <br></br>
                                <div>
                                  <FormControl component="fieldset" className="radioWithMargin">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>REFER DRG THERE</FormLabel>
                                      </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <RadioGroup
                                          value={reviewData?.refdrgthere}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="refdrgthere" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="refdrgthere" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                    {showTextBoxreferdrg && (
                                      <TextField
                                        label="Additional Information"
                                        variant="outlined"
                                        name="referdrgtheretext"
                                        value={reviewData?.referdrgtheretext || ''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) =>
                                          setReviewData((prevState) => ({
                                            ...prevState,
                                            referdrgtheretext: e.target.value,
                                          }))
                                        }
                                      />
                                    )}
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>EQUIPMENT POSITION</FormLabel>
                                      </Grid>

                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin" >

                                      {tableData.map((item, index) => (
                                        <div key={item.id}>
                                          <Grid item >
                                            <RadioGroup
                                              value={item.eqipposition}
                                              onChange={handleReviewChanges}
                                              row>
                                              <FormControlLabel name="eqipposition" value="H" control={<Radio />} label="H" />
                                              <FormControlLabel name="eqipposition" value="V" control={<Radio />} label="V" />
                                            </RadioGroup>
                                          </Grid>
                                        </div>
                                      ))}
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 10 }}>ANY SPL MARKING REQUIRED</FormLabel>
                                      </Grid>

                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin">

                                      <Grid item >
                                        <RadioGroup
                                          value={reviewData?.anysplmreq}
                                          onChange={handleReviewChanges}
                                          row>
                                          <FormControlLabel name="anysplmreq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="anysplmreq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>

                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 5 }}>EQPT LIFTING BELT REQUIRED</FormLabel>
                                      </Grid>

                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin">

                                      <Grid item >
                                        <RadioGroup value={reviewData?.eqliftbeltreq}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="eqliftbeltreq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="eqliftbeltreq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>NAME PLATE REQUIRED</FormLabel>
                                      </Grid>

                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin">

                                      <Grid item >
                                        <RadioGroup value={reviewData?.nameplatereq}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="nameplatereq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="nameplatereq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>SPECIAL TEST REQUIRED</FormLabel>
                                      </Grid>

                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin" >

                                      <Grid item >
                                        <RadioGroup value={reviewData?.spltestreq}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="spltestreq" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="spltestreq" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 19 }}>SENSITIVITY SPECIFIED</FormLabel>
                                      </Grid>

                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin" >

                                      <Grid item >
                                        <RadioGroup value={reviewData?.senspd}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="senspd" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="senspd" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                    {showTextBoxsenspd && (
                                      <TextField
                                        label="Additional Information"
                                        variant="outlined"
                                        name="senspdtext"
                                        value={reviewData?.senspdtext || ''}
                                        fullWidth
                                        margin="normal"
                                        onChange={(e) =>
                                          setReviewData((prevState) => ({
                                            ...prevState,
                                            senspdtext: e.target.value,
                                          }))
                                        }
                                      />
                                    )}
                                  </FormControl>

                                </div>
                              </div>
                              <div className="column">
                                {/*  <div>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>PRV</FormLabel>
                                      </Grid>
                                      <Grid item  >
                                        <RadioGroup value={reviewData?.prv}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="prv" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="prv" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset" >
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>DESCNT</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.descnt}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="descnt" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="descnt" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>LHOOK</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.lhook}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="lhook" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="lhook" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>WHEEL</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.wheel}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="wheel" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="wheel" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 25 }}>SLINGS</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.slings}
                                          onChange={handleReviewChanges}
                                          row>
                                          <FormControlLabel name="slings" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="slings" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 20 }}>FL PROVISION</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.flprov}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="flprov" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="flprov" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 20 }}>LID HANDLE</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.lidhandle}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="lidhandle" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="lidhandle" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 66 }}>LOCK</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.locks}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="locks" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="locks" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 29 }}>LATCH LOOK ASST.</FormLabel>
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.latchlookasst}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="latchlookasst" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="latchlookasst" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 29 }}>HU. IN  <FormLabel
                                      </Grid>
                                      <Grid item >
                                        <RadioGroup value={reviewData?.huin}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="huin" value="1" control={<Radio />} label="Yes" />
                                          <FormControlLabel name="huin" value="0" control={<Radio />} label="No" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                </div> */}
                                <FormControl component="fieldset">
                                  <Grid container alignItems="center" className="radioWithMargin">
                                    <Grid item>
                                      <FormLabel component="legend" >PRINTING COLOUR</FormLabel>
                                    </Grid>

                                  </Grid>
                                  <Grid container alignItems="center" className="radioWithMargin">

                                    {tableData.map((item, index) => (
                                      <div key={item.id}>
                                        <Grid item >
                                          <RadioGroup value={reviewData?.printcolor}
                                            onChange={handleReviewChanges} row>
                                            <FormControlLabel name="printcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                            <FormControlLabel name="printcolor" value="White" control={<Radio />} label="White" />
                                            <FormControlLabel name="printcolor" value="Black" control={<Radio />} label="Black" />
                                          </RadioGroup>
                                        </Grid>
                                      </div>
                                    ))}
                                  </Grid>
                                </FormControl>
                                <FormControl component="fieldset">
                                  <Grid container alignItems="center" className="radioWithMargin">
                                    <Grid item>
                                      <FormLabel component="legend" sx={{ mr: 20 }}>FOAM</FormLabel>
                                    </Grid>

                                  </Grid>
                                  <Grid container alignItems="center" className="radioWithMargin">

                                    <Grid item >
                                      <RadioGroup value={reviewData?.foam}
                                        onChange={handleReviewChanges} row>
                                        <FormControlLabel name="foam" value="EPE" control={<Radio />} label="EPE" />
                                        <FormControlLabel name="foam" value="XLPE" control={<Radio />} label="XLPE" />
                                        <FormControlLabel name="foam" value="PU" control={<Radio />} label="PU" />
                                      </RadioGroup>
                                    </Grid>
                                  </Grid>
                                </FormControl>
                                <FormControl component="fieldset" >
                                  {formData?.listaccess?.map((row: any, index: number) => (
                                    <div key={row.testaccessid}>
                                      <Grid container alignItems="center" className="radioWithMargin" >
                                        <Grid item>
                                          <FormLabel component="legend" sx={{ fontSize: 16 }}>{row.name}</FormLabel>
                                          <RadioGroup
                                            value={row.bit ? (row.bit === '1' ? "1" : "0") : ""}
                                            onChange={(e) => handleBitChange1(row.id, index, e)}
                                            row
                                            className="compact-radio-group"
                                          >
                                            <FormControlLabel name="bit" value="1" control={<Radio size="small" />} label="Yes" />
                                            <FormControlLabel name="bit" value="0" control={<Radio size="small" />} label="No" />
                                          </RadioGroup>
                                        </Grid>
                                      </Grid>

                                    </div>
                                  ))}
                                </FormControl>
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                            <div className="content-grid">
                              <div className="table-container">
                                <div className="table">
                                  <div className="main-heading" style={{ color: "#2801ff" }}>REVIEW OUTPUTS</div>
                                  <TextField
                                    required
                                    select
                                    id="casetype"
                                    name="casetype"
                                    fullWidth
                                    value={formData?.casetype}
                                    onChange={handleReviewChanges}
                                    style={{ marginBottom: '16px' }} // Adds space below the first TextField
                                  >
                                    <MenuItem value="Existing Case">Existing Case</MenuItem>
                                    <MenuItem value="NPD Case">NPD Case</MenuItem>
                                  </TextField>
                                  <TextField
                                    required
                                    select
                                    id="result"
                                    name="caseresult"
                                    fullWidth
                                    value={formData?.result}
                                    onChange={handleReviewChanges}
                                    style={{ marginBottom: '16px' }}
                                  >
                                    <MenuItem value="Can Be Used">Can Be Used</MenuItem>
                                    <MenuItem value="Can Be Fusioned">Can Be Fusioned</MenuItem>
                                    {/* <MenuItem value="Top NPD">Top NPD</MenuItem>
                                    <MenuItem value="Bottom NPD">Bottom NPD</MenuItem> */}
                                    <MenuItem value="To Be Made">To Be Made</MenuItem>
                                  </TextField>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin">
                                      <Grid item>
                                        <FormLabel component="legend" sx={{ mr: 15 }}>FOAM</FormLabel>
                                      </Grid>
                                    
                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin">
                                    
                                      <Grid item >
                                        <RadioGroup value={reviewData?.rofoam}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="rofoam" value="EPE" control={<Radio />} label="EPE" />
                                          <FormControlLabel name="rofoam" value="XLPE" control={<Radio />} label="XLPE" />
                                          <FormControlLabel name="rofoam" value="PU" control={<Radio />} label="PU" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                    
                                  </FormControl>
                                  <FormControl component="fieldset">
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                      <Grid item>
                                        <FormLabel component="legend" >PRINTING COLOUR</FormLabel>
                                      </Grid>
                                      
                                    </Grid>
                                    <Grid container alignItems="center" className="radioWithMargin" >
                                     
                                      <Grid item >
                                        <RadioGroup value={reviewData?.roprintcolor}
                                          onChange={handleReviewChanges} row>
                                          <FormControlLabel name="roprintcolor" value="Yellow" control={<Radio />} label="Yellow" />
                                          <FormControlLabel name="roprintcolor" value="White" control={<Radio />} label="White" />
                                          <FormControlLabel name="roprintcolor" value="Black" control={<Radio />} label="Black" />
                                        </RadioGroup>
                                      </Grid>
                                    </Grid>
                                  </FormControl>
                                </div>

                                <div className="table">
                                  <div className="main-heading" style={{ color: "#2801ff" }}>REQUIRED ITEMS</div>
                                  <div className="items-container">
                                    {formData?.listitem?.map((row: any, index: number) => {
                                      const isChecked = reviewData.listreqitems?.some(
                                        item => item.accessid === row.id && item.status === 1
                                      );
                                      return (
                                        <div className="item" key={row.id}>
                                          <input
                                            type="checkbox"
                                            id={`checkbox-${row.id}`}
                                            value={row.id}
                                            name="accessid"
                                            checked={isChecked}
                                            onChange={(e) => handleReviewChanges1(e, row.id, index)}
                                          />
                                          <label htmlFor={`checkbox-${row.id}`}>{row.name}</label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="panel">
                            <div className="table-container">
                              <div className="table">
                                <div className='reviewpoints' >
                                  <label htmlFor="revpoints">REVIEW POINTS</label>
                                  <textarea name="revpoints" onChange={handleReviewChanges} value={reviewData?.revpoints} rows="1" maxLength={500}></textarea>
                                </div>
                                <br></br>
                                <div className="note-section">
                                  <label htmlFor="note">NOTE</label>
                                  <textarea name="note" value={reviewData?.note} onChange={handleReviewChanges} rows="4" maxLength={500}></textarea>
                                </div>
                                <br></br>
                                <label className='review'>REVIEW HISTORY</label>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Rev. No</TableCell>
                                      <TableCell>Rev. Date</TableCell>
                                      <TableCell>Review Points</TableCell>
                                      <TableCell>ECN No</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                <button type="button" onClick={addRow}>Add Row</button>
                              </div>
                            </div>
                            <DialogActions>
                              <Button onClick={handleClose1} color="primary" variant='contained' className="btn-print" >
                                Clear
                              </Button>
                              <Button onClick={handleSubmitReview} variant='contained' color="primary" >
                                Save
                              </Button>
                            </DialogActions>
                          </div>
                        </div>
                      ) : (
                        <div >
                          <div ref={contentToPrint} className="reviewprint">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <img
                                src={require('../assets/logosaarc.png')}
                                alt="Logo"
                                style={{ width: '60px', height: '60%', marginTop: '-15px', marginBottom: '30px' }}
                              />
                              <div style={{ flex: 1, textAlign: 'center' }}>
                                <Typography gutterBottom style={{ marginBottom: '20px', marginTop: '-45px' }}>
                                  <h2><b>REVIEW FORM</b></h2>
                                </Typography>
                              </div>
                            </div>
                            {tableData.map((item, index) => (
                              <div key={item.id} style={{ marginBottom: "20px" }}>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Enquiry No :</strong>
                                  <span className="enquiry-data">{item.enqno}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Enquiry Date :</strong>
                                  <span className="enquiry-data">{item.enqdate}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Customer :</strong>
                                  <span className="enquiry-data">{item.cusname}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Requirement :</strong>
                                  <span className="enquiry-data">{item.req}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Date :</strong>
                                  <span className="enquiry-data">{formattedDaterdate}</span>
                                </div>
                              </div>
                            ))}
                            <div className="main-heading">INPUT DETAILS</div>
                            <div className="one-by-one">
                              <div className="field">
                                <label><strong>I/P NO :</strong></label>
                                {tableData.map((item, index) => (
                                  <div key={item.id}>
                                    <span className="value">{item.enqno}</span>
                                  </div>
                                ))}
                              </div>
                              <div className="field">
                                <label><strong>EQUIPMENT SIZE:</strong></label>
                                {sizewtdata.map((item, index) => (
                                  <div key={item.id} style={{ marginBottom: "20px" }}>
                                    <span className="value">{item.outerdimns}</span>
                                  </div>
                                ))}
                              </div>
                              <div className="field">
                                <label><strong>EQUIPMENT WEIGHT:</strong></label>
                                {sizewtdata.map((item, index) => (
                                  <div key={item.id} style={{ marginBottom: "20px" }}>
                                    <span className="value">{item.casenetwt2}</span>
                                  </div>
                                ))}
                              </div>
                            </div>

                            {/* Start of two columns layout */}
                            <div className="two-in-row">
                              <div className="field">
                                <strong>REFER DRG THERE:</strong>
                                <span className="value">
                                  {reviewData?.refdrgthere != null
                                    ? reviewData.refdrgthere.toString() === "1"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>
                              <div className="field">
                                <strong>REFER DRG THERE TEXT:</strong>
                                <span className="value"> {reviewData?.referdrgtheretext}</span>
                              </div>

                              <div className="field">
                                <strong>SUPPORT LOCATION SPECIFIED:</strong>
                                <span className="value">
                                  {reviewData?.suplocspd != null
                                    ? reviewData.suplocspd.toString() === "1"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>
                              <div className="field">
                                <strong>EQUIPMENT POSITION:</strong>
                                <span className="value">{reviewData.eqipposition}</span>
                              </div>

                              <div className="field">
                                <strong>ANY SPL MARKING REQUIRED:</strong>
                                <span className="value">
                                  {reviewData?.anysplmreq != null
                                    ? reviewData.anysplmreq.toString() === "1"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>
                              <div className="field">
                                <strong>EQPT LIFTING BELT REQUIRED:</strong>
                                <span className="value">
                                  {reviewData?.eqliftbeltreq != null
                                    ? reviewData.eqliftbeltreq.toString() === "1"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>

                              <div className="field">
                                <strong>NAME PLATE REQUIRED:</strong>
                                <span className="value">
                                  {reviewData?.nameplatereq != null
                                    ? reviewData.nameplatereq.toString() === "1"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>
                              <div className="field">
                                <strong>SPECIAL TEST REQUIRED:</strong>
                                <span className="value">
                                  {reviewData?.spltestreq != null
                                    ? reviewData.spltestreq.toString() === "1"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>

                              <div className="field">
                                <strong>SENSITIVITY SPECIFIED:</strong>
                                <span className="value">
                                  {reviewData?.senspd != null
                                    ? reviewData.senspd.toString() === "1"
                                      ? "Yes"
                                      : "No"
                                    : "No"}
                                </span>
                              </div>
                              <div className="field">
                                <strong>SENSITIVITY SPECIFIED TEXT:</strong>
                                <span className="value">{reviewData?.senspdtext}</span>
                              </div>

                              <div className="field">
                                <strong>PRINTING COLOUR:</strong>
                                <span className="value">{reviewData.printcolor}</span>
                              </div>
                              <div className="field">
                                <strong>FOAM:</strong>
                                <span className="value">{reviewData.foam}</span>
                              </div>

                              {/* Display radio options in two columns */}
                              <div className="field">
                                <Grid container spacing={2}>
                                  {formData?.listaccess?.map((row: any, index: number) => (
                                    <Grid item xs={6} key={row.testaccessid}>
                                      <div className="radioWithMargin">
                                        <strong>{row.name.toUpperCase()}</strong>
                                        <span className="value">{row.bit === '1' ? 'Yes' : 'No'}</span>
                                      </div>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            </div>
                            <div className="review-outputs">
                              <div className="main-heading-break">
                                <div className="main-heading">REVIEW OUTPUTS</div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>CASE TYPE:</strong>
                                    <span className="value">{formData?.casetype}</span>
                                  </div>
                                  <div className="field">
                                    <strong>RESULT:</strong>
                                    <span className="value">{formData?.result}</span>
                                  </div>
                                </div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>FOAM:</strong>
                                    <span className="value">{reviewData?.rofoam}</span>
                                  </div>
                                  <div className="field">
                                    <strong>PRINTING COLOUR:</strong>
                                    <span className="value">{reviewData?.roprintcolor}</span>
                                  </div>
                                </div>
                              </div>
                              <br></br>
                              <div className="section">
                                <div className="main-heading">REQUIRED ITEMS</div>
                                <div>
                                  <ul>
                                    {formData?.listitem?.filter((row: any) =>
                                      reviewData.listreqitems?.some(
                                        item => item.accessid === row.id && item.status === 1
                                      )
                                    ).map((selectedRow: any) => (
                                      <li key={selectedRow.id}>{selectedRow.name}</li>
                                    ))}
                                    {/* Display message if no items were selected */}
                                    {formData?.listitem?.filter((row: any) =>
                                      reviewData.listreqitems?.some(
                                        item => item.accessid === row.id && item.status === 1
                                      )
                                    ).length === 0 && <p>No items selected</p>}
                                  </ul>
                                </div>
                              </div>
                              <div className="section">
                                <div className="main-heading">REVIEW DETAILS</div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>REVIEW POINTS:</strong>
                                    <span className="value">{reviewData?.revpoints}</span>
                                  </div>
                                </div>
                                <div className="field-row">
                                  <div className="field">
                                    <strong>NOTE:</strong>
                                    <span className="value">{reviewData?.note}</span>
                                  </div>
                                </div>
                                <div className="review-history">
                                  <strong>REVIEW HISTORY:</strong>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>Rev. No</TableCell>
                                        <TableCell>Rev. Date</TableCell>
                                        <TableCell>Review Points</TableCell>
                                        <TableCell>ECN No</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {rows.map((row, index) => (
                                        <TableRow key={index}>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                          <TableCell></TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                            <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                            <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                          </div>
                          <Button onClick={() => {
                            handlePrint(null, () => contentToPrint.current);
                          }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                            variant="contained"
                            color="primary"
                            startIcon={<FontAwesomeIcon icon={faPrint} />}>
                            Print
                          </Button>
                          {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '675px', color: 'white', marginTop: '-40px' }}>
                            <Tab
                              label="Back"
                              onClick={() => {
                                setValue(0); // Navigate back to the form page
                                setIsSaved(false);
                                window.scrollTo(0, 0);
                              }}
                              sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                            />
                          </div> */}
                        </div>
                      )}
                      <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                    </div>
                  </TabPanel>

                  {/* FOR OUTPUT FORM */}
                  {/* <TabPanel value={value} index={2}>
                    {!isSaved ? (
                      <div>
                        <div className="panel">
                          <div className="main-heading">DESIGN OUTPUT DETAILS</div>
                          <table>
                            <thead>
                              <tr>
                                <th rowSpan={2} className='designop'>DESIGN OUTPUT</th>
                                <th colSpan={2} className='designop'>OUTPUT DATE</th>
                              </tr>
                              <tr>
                                <th className='designop'>SC</th>
                                <th className='designop' >HC</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Concept dwg attached for finalization</td>
                                <td><input type="date" className="textbox-style" name="cptdwgattachsc"
                                  value={outputData?.cptdwgattachsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="cptdwgattachhc"
                                  value={outputData?.cptdwgattachhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                              <tr>
                                <td>Case drawing attached</td>
                                <td><input type="date" className="textbox-style" name="casedwgattchsc"
                                  value={outputData?.casedwgattchsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="casedwgattchhc"
                                  value={outputData?.casedwgattchhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                              <tr>
                                <td>Foam drawing attached</td>
                                <td><input type="date" className="textbox-style" name="foamdwgattachsc"
                                  value={outputData?.foamdwgattachsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="foamdwgattachhc"
                                  value={outputData?.foamdwgattachhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                              <tr>
                                <td>Screen Printing details attached</td>
                                <td><input type="date" className="textbox-style" name="scnprtdetattachsc"
                                  value={outputData?.scnprtdetattachsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="scnprtdetattachhc"
                                  value={outputData?.scnprtdetattachhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                              <tr>
                                <td>Bill of material attached</td>
                                <td><input type="date" className="textbox-style" name="billofmatattachsc"
                                  value={outputData?.billofmatattachsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="billofmatattachhc"
                                  value={outputData?.billofmatattachhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                              <tr>
                                <td>Stiffner drawing attached</td>
                                <td><input type="date" className="textbox-style" name="stfdwgattachsc"
                                  value={outputData?.stfdwgattachsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="stfdwgattachhc"
                                  value={outputData?.stfdwgattachhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                              <tr>
                                <td>Product development plan attached</td>
                                <td><input type="date" className="textbox-style" name="prddevplnaatchsc"
                                  value={outputData?.prddevplnaatchsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="prddevplnaatchhc"
                                  value={outputData?.prddevplnaatchhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                              <tr>
                                <td>DFMEA attached</td>
                                <td><input type="date" className="textbox-style" name="dfmeaattachsc"
                                  value={outputData?.dfmeaattachsc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                                <td><input type="date" className="textbox-style" name="dfmeaattachhc"
                                  value={outputData?.dfmeaattachhc}
                                  onChange={handleOutputChanges} style={{ fontFamily: "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif" }} /></td>
                              </tr>
                            </tbody>
                          </table>
                          <DialogActions>
                            <Button onClick={handleClose2} variant='contained' color="primary" >
                              Clear
                            </Button>
                            <Button onClick={handleSubmitOutput} variant='contained' color="primary" >
                              Save
                            </Button>
                          </DialogActions>
                        </div>
                      </div>
                    ) : (
                      <div className="outputprint" ref={contentToPrint}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <img
                            src={require('../assets/logosaarc.png')}
                            alt="Logo"
                            style={{ width: '60px', height: '60%', marginTop: '-10px', marginBottom: '30px' }}
                          />
                          <div style={{ flex: 1, textAlign: 'center' }}>
                            <Typography gutterBottom style={{ marginBottom: '20px', marginTop: '-35px' }}>
                              <h2><b>OUTPUT FORM</b></h2>
                            </Typography>
                          </div>
                        </div>
                        {tableData.map((item, index) => (
                          <div key={item.id} style={{ marginBottom: "20px" }}>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Enquiry No :</strong>
                              <span className="enquiry-data">{item.enqno}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Enquiry Date :</strong>
                              <span className="enquiry-data">{item.enqdate}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Customer :</strong>
                              <span className="enquiry-data">{item.cusname}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Requirement :</strong>
                              <span className="enquiry-data">{item.req}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Date :</strong>
                              <span className="enquiry-data">{formattedDateodate}</span>
                            </div>
                          </div>
                        ))}
                        <br></br>
                        <div style={{ fontSize: '15px', marginBottom: '10px' }}><u><b>DESIGN OUTPUT DETAILS</b></u></div>
                        <table>
                          <thead>
                            <tr>
                              <th rowSpan={2} className='designop'>DESIGN OUTPUT</th>
                              <th colSpan={2} className='designop'>OUTPUT DATE</th>
                            </tr>
                            <tr>
                              <th className='designop'>SC</th>
                              <th className='designop'>HC</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Concept dwg attached for finalization</td>
                              <td>{formatDate(outputData.cptdwgattachsc)}</td>
                              <td>{formatDate(outputData.cptdwgattachhc)}</td>
                            </tr>
                            <tr>
                              <td>Case drawing attached</td>
                              <td>{formatDate(outputData.casedwgattchsc)}</td>
                              <td>{formatDate(outputData.casedwgattchhc)}</td>
                            </tr>
                            <tr>
                              <td>Foam drawing attached</td>
                              <td>{formatDate(outputData.foamdwgattachsc)}</td>
                              <td>{formatDate(outputData.foamdwgattachhc)}</td>
                            </tr>
                            <tr>
                              <td>Screen Printing details attached</td>
                              <td>{formatDate(outputData.scnprtdetattachsc)}</td>
                              <td>{formatDate(outputData.scnprtdetattachhc)}</td>
                            </tr>
                            <tr>
                              <td>Bill of material attached</td>
                              <td>{formatDate(outputData.billofmatattachsc)}</td>
                              <td>{formatDate(outputData.billofmatattachhc)}</td>
                            </tr>
                            <tr>
                              <td>Stiffner drawing attached</td>
                              <td>{formatDate(outputData.stfdwgattachsc)}</td>
                              <td>{formatDate(outputData.stfdwgattachhc)}</td>
                            </tr>
                            <tr>
                              <td>Product development plan attached</td>
                              <td>{formatDate(outputData.prddevplnaatchsc)}</td>
                              <td>{formatDate(outputData.prddevplnaatchhc)}</td>
                            </tr>
                            <tr>
                              <td>DFMEA attached</td>
                              <td>{formatDate(outputData.dfmeaattachsc)}</td>
                              <td>{formatDate(outputData.dfmeaattachhc)}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                          <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                          <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                        </div>
                        <Button onClick={() => {
                          handlePrint(null, () => contentToPrint.current);
                        }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                          variant="contained"
                          color="primary"
                          startIcon={<FontAwesomeIcon icon={faPrint} />}>
                          Print
                        </Button>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '675px', color: 'white', marginTop: '-40px' }}>
                          <Tab
                            label="Back"
                            onClick={() => {
                              setValue(0); // Navigate back to the form page
                              setIsSaved(false);
                              window.scrollTo(0, 0);
                            }}
                            sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                          />
                        </div>
                      </div>
                    )}
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </TabPanel> */}

                  {/* FOR VERIFICATION SUBMIT */}
                  <TabPanel value={value} index={2}>
                    {!isSaved ? (
                      <div className="panel">
                        <table>
                          <thead>
                            <tr>
                              <th rowSpan={3} className="no-border"></th>
                              <th rowSpan={3} className='designop'>EQPT. DIMNS.</th> {/* Equipment Dimensions on the left */}
                              <th colSpan={6} className='designop'>OUTPUT</th> {/* Output heading spanning all columns */}
                            </tr>
                            <tr>

                              <th className='designop' rowSpan={2}>CASE EXTERIOR DIMNS.</th>
                              <th className='designop' rowSpan={2}>CASE INNER DIMNS.</th>
                              <th colSpan={2} className='designop'>FOAM OUTER DIMNS.</th> {/* Foam Outer Dimensions with partitions */}
                              <th colSpan={2} className='designop'>FOAM CUT DIMNS.</th>   {/* Foam Cut Dimensions with partitions */}
                            </tr>
                            <tr>
                              {/* Sub-headings under Foam Dimensions */}
                              <th className='designop'>BOTTOM</th>
                              <th className='designop'>TOP</th>
                              <th className='designop'>BOTTOM</th>
                              <th className='designop'>TOP</th>
                            </tr>
                          </thead>
                          <tbody>
                          <tr>
  <td className='verticaltext'>DIMENSIONS</td> {/* Equipment dimensions cell */}
  <td><input type="text" className="textbox-style" name="cieqdimns" /></td>
  {sizewtdata.map((item, index) => (
    <React.Fragment key={index}>
      <td><input type="text" className="textbox-style" name="caseexdimns" value={item.outerdimns || ''} /></td> {/* CASE EXTERIOR DIMNS. */}
      <td><input type="text" className="textbox-style" name="caseinnerdims" value={item.innerdimns || ''} /></td> {/* CASE INNER DIMNS. */}
    </React.Fragment>
  ))}
  <td><input type="text" className="textbox-style" name="foamoutbottom" /></td> {/* FOAM OUTER DIMNS. BOTTOM */}
  <td><input type="text" className="textbox-style" name="foamouttop" /></td> {/* FOAM OUTER DIMNS. TOP */}
  <td><input type="text" className="textbox-style" name="formcutbottom" /></td> {/* FOAM CUT DIMNS. BOTTOM */}
  <td><input type="text" className="textbox-style" name="formcuttop" /></td> {/* FOAM CUT DIMNS. TOP */}
</tr>

                            <td rowSpan={17} className='verticaltext'>REQUIREMENTS</td>
                            {formData?.listrequire?.map((row: any, index: number) => (
                              <tr key={row.id}>
                                {console.log(row, "teststatus")}
                                <td className="designop1" colSpan={4}>
                                  {row.name}
                                  {index === 0 && (
                                    <FormControl variant="outlined" size="small" style={{ marginLeft: '8px', marginTop: '1px' }}>
                                      <Select
                                        name="handle"
                                        value={verifyData?.handle}
                                        onChange={(e: SelectChangeEvent<unknown>) => handleDropdownChange(e, index)}
                                        style={{ width: '130px', marginLeft: '20px', padding: '2px', marginTop: '0.01px' }}
                                      >
                                        <MenuItem value="tiny">TINY</MenuItem>
                                        <MenuItem value="ss">SS</MenuItem>
                                        <MenuItem value="plastic">PLASTIC</MenuItem>
                                      </Select>
                                    </FormControl>
                                  )}
                                </td>
                                <td colSpan={3}>
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      name='status'
                                      // value = {radioValuesverify[row.id] ?? (row.status === "1" ? "1" : "0")}
                                      value={verifyData?.listrequirements?.[index]?.status ?? null}
                                      // Ensure this matches the expected type
                                      onChange={(e) => handleStatusChanges(e, row.id, index)}
                                      className="compact-radio-group"
                                      row
                                    >
                                      <FormControlLabel name="status" value="1" control={<Radio size="small" />} label="Yes" />
                                      <FormControlLabel name="status" value="0" control={<Radio size="small" />} label="No" />
                                    </RadioGroup>
                                  </FormControl>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <DialogActions>
                          <Button onClick={handleClose3} variant='contained' color="primary" >
                            Clear
                          </Button>
                          <Button onClick={handleSubmitVerification} variant='contained' color="primary" >
                            Save
                          </Button>
                        </DialogActions>
                      </div>
                    ) : (
                      <div className="verifyprint " ref={contentToPrint}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <img
                            src={require('../assets/logosaarc.png')}
                            alt="Logo"
                            style={{ width: '60px', height: '60%', marginTop: '-10px', marginBottom: '30px' }}
                          />
                          <div style={{ flex: 1, textAlign: 'center' }}>
                            <Typography gutterBottom style={{ marginBottom: '20px', marginTop: '-40px' }}>
                              <h2><b>VERIFICATION FORM</b></h2>
                            </Typography>
                          </div>
                        </div>
                        {tableData.map((item, index) => (
                          <div key={item.id} style={{ marginBottom: "20px" }}>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Enquiry No :</strong>
                              <span className="enquiry-data">{item.enqno}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Enquiry Date :</strong>
                              <span className="enquiry-data">{item.enqdate}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Customer :</strong>
                              <span className="enquiry-data">{item.cusname}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Requirement :</strong>
                              <span className="enquiry-data">{item.req}</span>
                            </div>
                            <div className="enquiry-item">
                              <strong className="enquiry-heading">Date :</strong>
                              <span className="enquiry-data">{formattedDatevdate}</span>
                            </div>
                          </div>
                        ))}
                        <table>
                          <thead>
                            <tr>
                              <th rowSpan={2} className='designop'></th>
                              <th rowSpan={2} className='designop'>CUSTOMER'S INPUT</th>
                              <th colSpan={4} className='designop'>OUTPUT </th>
                            </tr>
                            <tr>
                              <th className='designop'>FOAM DWG DIMNS</th>
                              <th className='designop'>FOAM CUT DIMNS</th>
                              <th className='designop'>CASE DIMNS</th>
                              <th className='designop'>CASE DIMNS</th>
                            </tr>
                            <tr>
                              <th rowSpan={2} className='designop'></th>
                              <th className='designop'>EQPT. DIMNS.</th>
                              <th className='designop'>BOTTOM FOAM </th>
                              <th className='designop'>BOTTOM FOAM</th>
                              <th className='designop'>EXTERIOR DIMN.</th>
                              <th className='designop'>INTERIOR DIMN.</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td rowSpan={7} className='verticaltext'>DIMENSIONS</td>
                              <td>{verifyData.cieqwidth}</td>
                              <td>{verifyData.fdwgbtmwidth}</td>
                              <td>{verifyData.fcutbtmwidth}</td>
                              <td>{verifyData.cdexterwidth}</td>
                              <td>{verifyData.cdinterwidth}</td>
                            </tr>
                            <tr>
                              <td>{verifyData.cieqheight}</td>
                              <td>{verifyData.fdwgbtmheight}</td>
                              <td>{verifyData.fcutbtmheight}</td>
                              <td>{verifyData.cdexterheight}</td>
                              <td>{verifyData.cdinterheight}</td>
                            </tr>
                            <tr>
                              <td>{verifyData.cieqlen}</td>
                              <td>{verifyData.fdwgbtmlen}</td>
                              <td>{verifyData.fcutbtmlen}</td>
                              <td>{verifyData.cdexterlen}</td>
                              <td>{verifyData.cdinterlen}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className='designop'><b>TOP FOAM</b></td>
                              <td className='designop'><b>TOP FOAM</b></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>{verifyData.fdwgtopwidth}</td>
                              <td>{verifyData.fcuttopwidth}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>{verifyData.fdwgtopheight}</td>
                              <td>{verifyData.fcuttopheight}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>{verifyData.fdwgtoplen}</td>
                              <td>{verifyData.fcuttoplen}</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <td rowSpan={17} className='verticaltext'>REQUIREMENTS</td>
                            {formData.listrequire.map((row, index) => (
                              <tr key={row.testaccessid}>
                                <td className="designop1" colSpan={3}>
                                  {row.name}
                                  {index === 0 && verifyData.handle && (
                                    <span style={{ marginLeft: '8px', marginTop: '1px' }}>
                                      {verifyData.handle === 'tiny' ? 'TINY' :
                                        verifyData.handle === 'ss' ? 'SS' :
                                          verifyData.handle === 'plastic' ? 'PLASTIC' :
                                            ''}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <span>
                                    {(verifyData?.listrequirements?.[index]?.status === '1') // Use == to compare both types
                                      ? "Yes"
                                      : "No"}
                                  </span>
                                </td>
                                <td></td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                          <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                          <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                        </div>
                        <Button onClick={() => {
                          handlePrint(null, () => contentToPrint.current);
                        }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                          variant="contained"
                          color="primary"
                          startIcon={<FontAwesomeIcon icon={faPrint} />}>
                          Print
                        </Button>
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '675px', color: 'white', marginTop: '-40px' }}>
                          <Tab
                            label="Back"
                            onClick={() => {
                              setValue(0); // Navigate back to the form page
                              setIsSaved(false);
                              window.scrollTo(0, 0);
                            }}
                            sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                          />
                        </div> */}
                      </div>
                    )}
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </TabPanel>

                  {/* FOR DRAWING VALIDATION */}
                  <TabPanel value={value} index={3}>
                    {!isSaved ? (
                      <div className="panel">
                        <table>
                          <thead>
                            <tr>
                              <th className='designop'>DETAILS TO BE CHECKED</th>
                              <th className='designop'>CHK WITH</th>
                              <th className='designop'>CASE DWG</th>
                              <th className='designop'>BOM</th>
                              <th className='designop'>FOAM DWG</th>
                              <th className='designop'>PRINTING DWG</th>
                              <th className='designop'>REMARKS/LIST DATE</th>
                            </tr>
                          </thead>
                          <tbody>
                            {validData?.map((row, index) => (
                              <tr key={row.id} >
                                <td className="valid" style={{ width: '150px' }} >
                                  {row.detailstobechecked}
                                  {index === 3 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label><input type="checkbox" className="checkbox-style" value='req' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('req') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> REQ </label>
                                      <label><input type="checkbox" className="checkbox-style" value='not req' checked={validDetailsData[index]?.detailscheck?.includes('not req') || false} name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '32px', marginRight: '10px' }} /> NOT REQ </label>
                                    </div>
                                  )}
                                  {index === 4 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label> <input type="checkbox" className="checkbox-style" value='latch' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('latch') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> LATCH </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='hinge' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('hinge') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '38px', marginRight: '10px' }} /> HINGE </label>
                                      <label><input type="checkbox" className="checkbox-style" value='handle' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('handle') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '38px', marginRight: '10px' }} /> HANDLE </label>
                                    </div>
                                  )}
                                  {index === 5 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                      <label><input type="checkbox" className="checkbox-style" value='rivet' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('rivet') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> RIVET </label>
                                      <label><input type="checkbox" className="checkbox-style" value='bolt' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('bolt') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BOLT </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='big' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('big') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BIG </label>
                                      <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                        SS CA <label><input type="checkbox" className="checkbox-style" value='2240' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2240') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2240 </label>
                                        <label>  <input type="checkbox" className="checkbox-style" value='2230' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2230') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2230 </label>
                                        <label><input type="checkbox" className="checkbox-style" value='2220' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2220') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2220 </label>
                                      </div>
                                    </div>
                                  )}
                                  {index === 7 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      SS CA<label><input type="checkbox" className="checkbox-style" value='2150' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2150') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> 2150 </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='4350' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4350') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4350 </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='4760' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4760') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4760 </label>
                                    </div>
                                  )}
                                  {index === 10 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label> <input type="checkbox" className="checkbox-style" value='epe' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('epe') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> EPE </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='xlpe' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('xlpe') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> XLPE </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='pu' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('pu') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> PU </label>
                                    </div>
                                  )}
                                  {index === 13 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label><input type="checkbox" className="checkbox-style" value='xlpe' checked={validDetailsData[index]?.detailscheck?.includes('xlpe') || false} name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> XLPE </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='silica gel' checked={validDetailsData[index]?.detailscheck?.includes('silica gel') || false} name="detailscheck" onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> SILICA GEL </label>
                                    </div>
                                  )}
                                  {index === 15 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label><input type="checkbox" className="checkbox-style" value='textstyle' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('textstyle') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> TEXT STYLE </label>
                                      <label><input type="checkbox" className="checkbox-style" value='hteveness' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('hteveness') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> HT EVENESS </label>
                                    </div>
                                  )}
                                  {index === 17 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label>  <input type="checkbox" className="checkbox-style" value='yellow' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('yellow') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> YELLOW </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='black' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('black') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BLACK </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='white' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('white') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> WHITE </label>
                                    </div>
                                  )}
                                  {index === 19 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label><input type="checkbox" className="checkbox-style" value='green' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('green') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> GREEN </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='grey' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('grey') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> GREY </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='black' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('black') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> BLACK </label>
                                    </div>
                                  )}
                                  {index === 20 && (
                                    <div style={{ marginTop: '10px' }}>
                                      <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <label> <input type="checkbox" className="checkbox-style" value='plastic' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('plastic') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> PLASTIC </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='ss' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('ss') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> SS </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='tiny' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('tiny') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> TINY </label>
                                      </div>
                                      <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                        SS CA <label><input type="checkbox" className="checkbox-style" value='2000' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2000') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> 2000 </label>
                                        <label>  <input type="checkbox" className="checkbox-style" value='2050' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2050') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2050 </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='2110' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2110') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2110 </label>
                                      </div>
                                    </div>
                                  )}
                                  {index === 21 && (
                                    <div style={{ marginTop: '10px' }}>
                                      <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <label><input type="checkbox" className="checkbox-style" value='prv' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('prv') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> PRV </label>
                                        <label><input type="checkbox" className="checkbox-style" value='humidity' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('humidity') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '10px', marginRight: '10px' }} /> HUMIDITY </label>
                                        <label>  <input type="checkbox" className="checkbox-style" value='doc hold' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('doc hold') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '10px', marginRight: '10px' }} /> DOC </label>
                                      </div>
                                      <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '10px' }}>
                                        SS CA<label><input type="checkbox" className="checkbox-style" value='2320' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2320') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '2px', marginRight: '10px' }} />  2320 </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='4000' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4000') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4000 </label>
                                        <label> <input type="checkbox" className="checkbox-style" value='4050' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('4050') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 4050 </label>
                                      </div>
                                    </div>
                                  )}
                                  {index === 22 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label> <input type="checkbox" className="checkbox-style" value='screen printing' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('screen printing') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> SCREEN PRINTING </label>
                                      <label>  <input type="checkbox" className="checkbox-style" value='name plate' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('name plate') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '5px', marginRight: '10px' }} /> NAME PLATE </label>
                                    </div>
                                  )}
                                  {index === 23 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label><input type="checkbox" className="checkbox-style" value='1.5' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('1.5') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> 1.5 </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='3' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('3') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 3 </label>
                                      <label> <input type="checkbox" className="checkbox-style" value='2.5' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('2.5') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> 2.5 SPECIFY </label>
                                    </div>
                                  )}
                                  {index === 24 && (
                                    <div style={{ display: 'inline-flex', alignItems: 'center', marginTop: '10px' }}>
                                      <label><input type="checkbox" className="checkbox-style" value='req' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('req') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginRight: '10px' }} /> REQ </label>
                                      <label><input type="checkbox" className="checkbox-style" value='not req' name="detailscheck" checked={validDetailsData[index]?.detailscheck?.includes('not req') || false} onChange={(e) => handleValidationChanges(e, row.id, index)} style={{ marginLeft: '20px', marginRight: '10px' }} /> NOT REQ </label>
                                    </div>
                                  )}
                                </td>
                                {index === 11 ? (
                                  <td className="verticaltext" rowSpan={5}>
                                    {row.chkwith}
                                  </td>
                                ) : index > 11 && index < 16 ? null : (
                                  <td className="designop">
                                    {row.chkwith}
                                  </td>
                                )}
                                {index === 9 ? (
                                  <>
                                    <td className="designop">CASE WT <input type="text" className="textbox-style" value={validDetailsData[index]?.casewt || ''} name="casewt" onChange={(e) => handleValidationChanges(e, row.id, index)} maxLength={20} /></td>
                                    <td className="designop">ACC. WT <input type="text" className="textbox-style" value={validDetailsData[index]?.accwt || ''} name="accwt" onChange={(e) => handleValidationChanges(e, row.id, index)} maxLength={20} /></td>
                                    <td className="designop">FOAM WT <input type="text" className="textbox-style" value={validDetailsData[index]?.foamwt || ''} name="foamwt" onChange={(e) => handleValidationChanges(e, row.id, index)} maxLength={20} /></td>
                                    <td className="designop">STIFF. WT<input type="text" className="textbox-style" value={validDetailsData[index]?.stiffwt || ''} name="stiffwt" onChange={(e) => handleValidationChanges(e, row.id, index)} maxLength={20} /></td>
                                    <td className="designop">FINAL WT <input type="text" className="textbox-style" value={validDetailsData[index]?.finalwt || ''} name="finalwt" onChange={(e) => handleValidationChanges(e, row.id, index)} maxLength={20} /></td>
                                  </>
                                ) : index === 8 ? (
                                  <>
                                    <td className="designop" colSpan={4}><input type="text" className="textbox-style" value={validDetailsData[index]?.eqpwt || ''} name="eqpwt" onChange={(e) => handleValidationChanges(e, row.id, index)} maxLength={25} /></td>
                                    <td className="tick">
                                      <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        checked={validDetailsData[index]?.remarkslistdate === 1}
                                        name="remarkslistdate"
                                        onChange={(e) => handleValidationChanges(e, row.id, index)}
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td className="tick">
                                      <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        checked={validDetailsData[index]?.casedwg === 1}
                                        name="casedwg"
                                        onChange={(e) => handleValidationChanges(e, row.id, index)}
                                      />
                                    </td>
                                    <td className="tick">
                                      <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        checked={validDetailsData[index]?.bom === 1}
                                        name="bom"
                                        onChange={(e) => handleValidationChanges(e, row.id, index)}
                                      />
                                    </td>
                                    <td className="tick">
                                      <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        checked={validDetailsData[index]?.foamdwg === 1}
                                        name="foamdwg"
                                        onChange={(e) => handleValidationChanges(e, row.id, index)}
                                      />
                                    </td>
                                    <td className="tick">
                                      <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        checked={validDetailsData[index]?.printingdwg === 1}
                                        name="printingdwg"
                                        onChange={(e) => handleValidationChanges(e, row.id, index)}
                                      />
                                    </td>
                                    <td className="tick">
                                      <input
                                        type="checkbox"
                                        className="styled-checkbox"
                                        checked={validDetailsData[index]?.remarkslistdate === 1}
                                        name="remarkslistdate"
                                        onChange={(e) => handleValidationChanges(e, row.id, index)}
                                      />
                                    </td>
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        <DialogActions>
                          <Button onClick={handleClose4} color="primary" variant='contained' className="btn-print" >
                            Clear
                          </Button>
                          <Button onClick={handleSubmitValidation} variant='contained' color="primary" >
                            Save
                          </Button>
                        </DialogActions>
                      </div>
                    ) : (
                      <div className='compress-form'>
                        <div className='container1' ref={contentToPrint}>
                          <div className="reviewprint">
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <img
                                src={require('../assets/logosaarc.png')}
                                alt="Logo"
                                style={{ width: '60px', height: '60%', marginTop: '-10px', marginBottom: '30px' }}
                              />
                              <div style={{ flex: 1, textAlign: 'center' }}>
                                <Typography gutterBottom style={{ marginBottom: '20px', marginTop: '-35px' }}>
                                  <h2><b>DRAWING VALIDATION FORM</b></h2>
                                </Typography>
                              </div>
                            </div>
                            {tableData.map((item, index) => (
                              <div key={item.id} style={{ marginBottom: "20px" }}>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Enquiry No :</strong>
                                  <span className="enquiry-data">{item.enqno}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Enquiry Date :</strong>
                                  <span className="enquiry-data">{item.enqdate}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Customer :</strong>
                                  <span className="enquiry-data">{item.cusname}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Requirement :</strong>
                                  <span className="enquiry-data">{item.req}</span>
                                </div>
                                <div className="enquiry-item">
                                  <strong className="enquiry-heading">Date :</strong>
                                  <span className="enquiry-data">{formattedDatecon}</span>
                                </div>
                              </div>
                            ))}
                            <br></br>
                            <table>
                              <thead>
                                <tr>
                                  <th className='designop'>DETAILS TO BE CHECKED</th>
                                  <th className='designop'>CHK WITH</th>
                                  <th className='designop'>CASE DWG</th>
                                  <th className='designop'>BOM</th>
                                  <th className='designop'>FOAM DWG</th>
                                  <th className='designop'>PRINTING DWG</th>
                                  <th className='designop'>REMARKS/LIST DATE</th>
                                </tr>
                              </thead>
                              <tbody>
                                {validData?.map((row, index) => (
                                  <tr key={row.id}>
                                    <td className="valid" style={{ width: '150px' }}>
                                      {row.detailstobechecked}
                                      {index === 3 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['req', 'not req'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 4 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['latch', 'hinge', 'handle'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 5 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['rivet', 'bolt', 'big'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                          {['2240', '2230', '2220'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>SS CA      {value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 7 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['2150', '4350', '4760'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>SS CA      {value}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 10 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['epe', 'xlpe', 'pu'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 13 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['xlpe', 'silica gel'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 15 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['textstyle', 'hteveness'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 17 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['yellow', 'black', 'white'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 19 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['green', 'grey', 'black'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 20 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['plastic', 'ss', 'tiny'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                          {['2000', '2050', '2110'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>SS CA      {value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 21 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['prv', 'humidity', 'doc hold'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                          {['2320', '4000', '4050'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>SS CA      {value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 22 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['screen printing', 'name plate'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 23 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['1.5', '3', '2.5'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                      {index === 24 && (
                                        <div style={{ marginTop: '10px' }}>
                                          {['req', 'not req'].map((value) => (
                                            validDetailsData[index]?.detailscheck?.includes(value) ? (
                                              <span key={value} style={{ marginRight: '10px' }}>{value.toUpperCase()}</span>
                                            ) : null
                                          ))}
                                        </div>
                                      )}
                                    </td>
                                    {index === 11 ? (
                                      <td className="verticaltext" rowSpan={5}>
                                        {row.chkwith}
                                      </td>
                                    ) : index > 11 && index < 16 ? null : (
                                      <td className="designop">
                                        {row.chkwith}
                                      </td>
                                    )}
                                    {index === 9 ? (
                                      <>
                                        <td className="designop">CASE WT {validDetailsData[index]?.casewt || ''}</td>
                                        <td className="designop">ACC. WT {validDetailsData[index]?.accwt || ''}</td>
                                        <td className="designop">FOAM WT {validDetailsData[index]?.foamwt || ''}</td>
                                        <td className="designop">STIFF. WT {validDetailsData[index]?.stiffwt || ''}</td>
                                        <td className="designop">FINAL WT {validDetailsData[index]?.finalwt || ''}</td>
                                      </>
                                    ) : index === 8 ? (
                                      <>
                                        <td className="designop" colSpan={4}>{validDetailsData[index]?.eqpwt || ''}</td>
                                        <td className="tick">
                                          <input
                                            type="checkbox"
                                            className="styled-checkbox"
                                            checked={validDetailsData[index]?.remarkslistdate === 1}
                                            name="remarkslistdate"
                                            disabled
                                          />
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td className="tick">
                                          <input
                                            type="checkbox"
                                            className="styled-checkbox"
                                            checked={validDetailsData[index]?.casedwg === 1}
                                            name="casedwg"
                                            disabled
                                          />
                                        </td>
                                        <td className="tick">
                                          <input
                                            type="checkbox"
                                            className="styled-checkbox"
                                            checked={validDetailsData[index]?.bom === 1}
                                            name="bom"
                                            disabled
                                          />
                                        </td>
                                        <td className="tick">
                                          <input
                                            type="checkbox"
                                            className="styled-checkbox"
                                            checked={validDetailsData[index]?.foamdwg === 1}
                                            name="foamdwg"
                                            disabled
                                          />
                                        </td>
                                        <td className="tick">
                                          <input
                                            type="checkbox"
                                            className="styled-checkbox"
                                            checked={validDetailsData[index]?.printingdwg === 1}
                                            name="printingdwg"
                                            disabled
                                          />
                                        </td>
                                        <td className="tick">
                                          <input
                                            type="checkbox"
                                            className="styled-checkbox"
                                            checked={validDetailsData[index]?.remarkslistdate === 1}
                                            name="remarkslistdate"
                                            disabled
                                          />
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div style={{ marginTop: '100px', display: 'flex', justifyContent: 'space-between', marginLeft: '20px', marginRight: '20px' }}>
                              <Typography variant="body1"><b>AUTHORIZED BY</b></Typography>
                              <Typography variant="body1"><b>DESIGNED BY</b></Typography>
                            </div>
                            <Button onClick={() => {
                              handlePrint(null, () => contentToPrint.current);
                            }} style={{ marginTop: '10px', marginLeft: '10px' }} className="no-print"
                              variant="contained"
                              color="primary"
                              startIcon={<FontAwesomeIcon icon={faPrint} />}>
                              Print
                            </Button>
                            {/* <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '675px', color: 'white', marginTop: '-40px' }}>
                              <Tab
                                label="Back"
                                onClick={() => {
                                  setValue(0); // Navigate back to the form page
                                  setIsSaved(false);
                                  window.scrollTo(0, 0);
                                }}
                                sx={{ backgroundColor: 'blue', "&:hover": { color: 'white' } }}
                              />
                            </div> */}
                          </div>
                        </div>
                        <br></br>
                        <br></br>
                        <div className="panel">
                          <div className="main-heading">SUBMIT TO MARKETING TEAM</div>
                          <input
                            type="file"
                            name="attch"
                            id="attch"
                            onChange={handleFileChange}
                          />
                          {/* Display the URL as a clickable link */}
                          {formData.attch && (
                            <a href={formData.attch} target="_blank" rel="noopener noreferrer">
                              View File
                            </a>
                          )}
                          <Button onClick={handleSubmitToMarket} style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '750px', marginTop: '20px' }} variant='contained'>
                            Send
                          </Button>
                        </div>
                      </div>
                    )}
                    <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
                  </TabPanel>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container >
      <Footer />
    </>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default UpdatePage;