import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
import { Key, ReactChild, ReactFragment, ReactPortal, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { FaPencil } from "react-icons/fa6";
import { ToastContainer } from "react-toastify";
import Footer from "src/components/Footer";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { errorToast, successToast } from "src/layouts/utile/toast";
import apiService from "src/services/api-service";

interface Data {
    compid: number,
    name: string,
    sname: string,
    mobile: string,
    phone: string,
    email: string,
    pan: string,
    gstno: string,
    tinno: string,
    hsncode: string,
    gemsellerid: string,
    add1: string,
    add2: string,
    add3: number,
    add4: number,
    add5: number,
    add6: string,
    cadd1: string,
    cadd2: string,
    cadd3: string,
    cadd4: string,
    cadd5: string,
    cadd6: string,
    cby: string,
    isactive: string;
    id: number;
    // cityid: string;
    // distid: string;
    // stateid: string;
    // Add more fields as necessary
}
interface Name {
    id: number;
    name: string;
    isactive: number;
}

const status = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'In-Active'
    }
];

interface City {
    id: number;
    name: string;
}

interface Dist {
    id: number;
    name: string;
}

interface State {
    id: number;
    name: string;
}

function Plant() {
    // For Grid Table
    const [data, setData] = useState<Data[]>([]);
    const [name, setName] = useState<Name[]>([]);
    const [city, setCity] = useState<City[]>([]);
    const [dist, setDist] = useState<Dist[]>([]);
    const [state, setState] = useState<State[]>([]);
    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isMobileValid, setIsMobileValid] = useState(true);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [ispincode1valid, setIspincode1valid] = useState(true);
    const [isgstvalid, setIsgstvalid] = useState(true);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    //Form data 
    const [formData, setFormData] = useState<any>({
        id: '',
        compid: '',
        name: '',
        sname: '',
        mobile: '',
        phone: '',
        email: '',
        pan: '',
        gstno: '',
        tinno: '',
        hsncode: '',
        gemsellerid: '',
        add1: '',
        add2: '',
        add3: 0,
        add4: 0,
        add5: 0,
        add6: '',
        cadd1: '',
        cadd2: '',
        cadd3: '',
        cadd4: '',
        cadd5: '',
        cadd6: '',
        isactive: '',
        cby: '',
        con: '',
        mby: '',
        mon: '',
        // cityid: '',
        // distid: '',
        // stateid: ''
    });

    const [selectedItem, setSelectedItem] = useState<any>(null);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {

        apiService.getPlant(rowsPerPage.toString(), (page + 1).toString(), "").then(response => {
            setData(response.data);
        }).catch((err) => {
            console.log("err", err)
        })

        apiService.getDropdown("mas_company").then(response => {
            const activeNames = response.data.filter((item: Name) => item.isactive === 1);
            setName(activeNames);
            //setName(response.data);
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getDropdown("mas_city").then(response => {
            setCity(response.data);
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getDropdown("mas_district").then(response => {
            setDist(response.data);
        }).catch((err) => {
            console.log("err", err)
        });

        apiService.getDropdown("mas_state").then(response => {
            setState(response.data);
        }).catch((err) => {
            console.log("err", err)
        });
    }, [rowsPerPage, page, formData]);

    //For Pagination 
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const validateMobile = (mobile: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const mobileRegex = /^[0-9]{10}$/;
        return mobileRegex.test(mobile);
    };
    const validateEmail = (email: string) => {
        const emailRegex =  /^[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*@[a-z]+\.(com|in|org|info|tech|gov|edu|mil|co|io|co.in)$/;
        return emailRegex.test(email);
    };
    const validatePincode1 = (add6: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const pincodeRegex1 = /^[0-9]{6}$/;
        return pincodeRegex1.test(add6);
    };
    const validateGST = (gstno: string) => {
        // Mobile number validation logic (example: 10 digits only)
        const GSTRegex = /^[A-Z0-9]{15}$/;
        return GSTRegex.test(gstno);
    };

    const handleChange = (event: any) => {
        const { name, value } = event.target;

        if (name === 'mobile') {
            setIsMobileValid(validateMobile(value));
        }

        if (name == 'email') {
            setIsEmailValid(validateEmail(value));
        }

        if (name === 'add6') {
            setIspincode1valid(validatePincode1(value));
        }
        if (name == 'gstno') {
            setIsgstvalid(validateGST(value));
        }

        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        if (errors[name]) {
            setErrors({
              ...errors,
              [name]: value ? '' : `${name} is required`, // Reset or keep error based on value
            });
          }
    };
    
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setFormData({
            id: '',
            compid: '',
            name: '',
            sname: '',
            mobile: '',
            phone: '',
            email: '',
            pan: '',
            gstno: '',
            tinno: '',
            hsncode: '',
            gemsellerid: '',
            add1: '',
            add2: '',
            add3: 0,
            add4: 0,
            add5: 0,
            add6: '',
            cadd1: '',
            cadd2: '',
            cadd3: '',
            cadd4: '',
            cadd5: '',
            cadd6: '',
            isactive: '',
            cby: '',
            con: '',
            mby: '',
            mon: '',
            // cityid: '',
            // distid: '',
            // stateid: ''
        });
        setSelectedItem(null);
        setIspincode1valid(true);
        setIsMobileValid(true);
        setIsEmailValid(true);
        setErrors(null);
    };

const isInsert = !formData?.id; 
    const validateForm = (isInsert: boolean) => {
        const requiredFields = [
          'compid', 'sname', 'name', 'mobile', 'email', 'pan', 'gstno','isactive'
        ];
        let valid = true;
        const newErrors: { [key: string]: string } = {};
    
        requiredFields.forEach((field) => {
            // Only validate all required fields during insert, or validate only if the field is being updated
            if (isInsert || formData[field] !== undefined) {
                console.log(field, formData[field], "validate");
                if(field === "isactive")
                {
                    if (formData[field] === '') {
                    newErrors[field] = `${field} is isactive`;
                    valid = false;
                    }
                }
                else{
                    if (!formData[field]) {
                        newErrors[field] = `${field} is required`;
                        valid = false;
                    }
                }
                
            }
        });
        setErrors(newErrors);
        return valid;
      }
    // Insert and Update 
    let submit = (event: any) => {

        event.preventDefault(); // Prevents default form submission behavior
        if (!validateForm(isInsert)) {
            console.log('Form is invalid. Please fill all required fields.');
            return;
          }
        // Insert
        if (selectedItem == null) {

            let data = {

                "compid": formData?.compid,
                "sname": formData?.sname,
                "name": formData?.name,
                "mobile": formData?.mobile,
                "email": formData?.email,
                "pan": formData?.pan,
                "gstno": formData?.gstno,
                "tinno": formData?.tinno,
                "add1": formData?.add1,
                "add2": formData?.add2,
                "add3": formData?.add3,
                "add4": formData?.add4,
                "add5": formData?.add5,
                "add6": formData?.add6,
                "isactive": formData?.isactive,
                "cby": "1",
                "mby": "",
                // "cityid": formData?.cityid,
                // "distid": formData?.distid,
                // "stateid": formData?.stateid

            };
            console.log('Submit --> ', data);


            apiService.insertPlant(data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        compid: '',
                        name: '',
                        sname: '',
                        mobile: '',
                        phone: '',
                        email: '',
                        pan: '',
                        gstno: '',
                        tinno: '',
                        hsncode: '',
                        gemsellerid: '',
                        add1: '',
                        add2: '',
                        add3: 0,
                        add4: 0,
                        add5: 0,
                        add6: '',
                        cadd1: '',
                        cadd2: '',
                        cadd3: '',
                        cadd4: '',
                        cadd5: '',
                        cadd6: '',
                        isactive: '',
                        cby: '',
                        con: '',
                        mby: '',
                        mon: '',
                        // cityid: '',
                        // distid: '',
                        // stateid: ''
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }

        // Update
        else {
            let data = {
                "id": formData?.id,
                "compid": formData?.compid,
                "name": formData?.name,
                "sname": formData?.sname,
                "mobile": formData?.mobile,
                "email": formData?.email,
                "pan": formData?.pan,
                "gstno": formData?.gstno,
                "tinno": formData?.tinno,
                "add1": formData?.add1,
                "add2": formData?.add2,
                "add3": formData?.add3,
                "add4": formData?.add4,
                "add5": formData?.add5,
                "add6": formData?.add6,
                "isactive": formData?.isactive,
                "cby": "1",
                "mby": "1",
                // "cityid": formData?.cityid,
                // "distid": formData?.distid,
                // "stateid": formData?.stateid
            };
            apiService.updatePlant(Number(formData.id), data).then(response => {
                if (response?.data.key == "400") {
                    errorToast(response?.data?.message);
                }
                else {
                    successToast(response.data);
                    setSelectedItem(null);
                    setFormData({
                        id: '',
                        compid: '',
                        name: '',
                        sname: '',
                        mobile: '',
                        phone: '',
                        email: '',
                        pan: '',
                        gstno: '',
                        tinno: '',
                        hsncode: '',
                        gemsellerid: '',
                        add1: '',
                        add2: '',
                        add3: '',
                        add4: '',
                        add5: '',
                        add6: '',
                        cadd1: '',
                        cadd2: '',
                        cadd3: '',
                        cadd4: '',
                        cadd5: '',
                        cadd6: '',
                        isactive: '',
                        cby: '',
                        con: '',
                        mby: '',
                        mon: '',
                        // cityid: '',
                        // distid: '',
                        // stateid: ''
                    });
                    handleClose();
                }
            }).catch((err) => {
                console.log("err", err)
            });
        }
    };
    // Handler for Edit Button click
    const handleEditClick = (item: Data) => {

        setFormData({
            id: item?.id.toString(),
            compid: item?.compid,
            sname: item?.sname,
            name: item?.name,
            mobile: item?.mobile,
            email: item?.email,
            pan: item?.pan,
            gstno: item?.gstno,
            tinno: item?.tinno,
            add1: item?.add1,
            add2: item?.add2,
            add3: item?.add3,
            add4: item?.add4,
            add5: item?.add5,
            add6: item?.add6,
            isactive: item?.isactive,
            // cityid: item?.cityid,
            // distid: item?.distid,
            // stateid: item?.stateid

        });
        setSelectedItem(item);
        setOpen(true);
    };

    return (
        <>
            <Helmet>
                <title>Plant - Admin</title>
            </Helmet>

            <PageTitleWrapper>
                <PageTitle
                    heading="Plant"
                    subHeading=""
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        // alignItems: 'flex-end',
                        mt: -3.5, // Add some margin-bottom for spacing
                    }}
                >  <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Add New
                    </Button></Box>
            </PageTitleWrapper>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                >
                    {/* <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleClickOpen}>
                            Add New
                        </Button>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>S.No</TableCell>
                                            <TableCell>Short Name</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Mobile No</TableCell>
                                            <TableCell>Email Id</TableCell>
                                            <TableCell>Pan No</TableCell>
                                            <TableCell>GST No</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.slice().map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                                <TableCell>{row.sname}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.mobile}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.pan}</TableCell>
                                                <TableCell>{row.gstno}</TableCell>
                                                <TableCell style={{ color: row.isactive == "1" ? "green" : 'red' }}>
                                                    {row.isactive == "1" ? "Active" : "In-Active"}
                                                </TableCell>
                                                <TableCell>
                                                    <span className='' onClick={() => handleEditClick(row)}><FaPencil /></span>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TablePagination
                                component="div"
                                count={Math.floor(data.length == 0 ? 0 : data[0]["count"])}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                            />
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog open={open} onClose={handleClose}>
                    <form>
                        <DialogTitle>{selectedItem ? "Edit" : "Add"}</DialogTitle>
                        <DialogContent>
                            <TextField
                                select
                                margin="dense"
                                label="Company"
                                name='compid'
                                fullWidth
                                value={formData?.compid}
                                onChange={handleChange}
                                // error={!!errors?.compid} // Highlight the field if there's an error
                                // helperText={errors?.compid} // Display error message if present
                            >
                                {name.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                required
                                margin="dense"
                                id="sname"
                                label="Short Name"
                                name='sname'
                                fullWidth
                                value={formData?.sname}
                                onChange={handleChange}
                                error={!!errors?.sname} // Highlight the field if there's an error
                                helperText={errors?.sname ? "Short Name is required" : ""}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="name"
                                label="Name"
                                name='name'
                                fullWidth
                                value={formData?.name}
                                onChange={handleChange}
                                error={!!errors?.name} // Highlight the field if there's an error
                                helperText={errors?.name ? "Name is required" : ""}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="mobile"
                                label="Mobile No"
                                name='mobile'
                                fullWidth
                                value={formData?.mobile}
                                onChange={handleChange}
                                error={!isMobileValid || !!errors?.mobile}
                                helperText={!isMobileValid ? 'Invalid mobile number' : errors?.mobile ? "Mobile No is required" : ""}
                                onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/\D/g, ''); // Allow only digits
                                  }}
                            />
                            <TextField
                                required
                                margin="dense"
                                id="email"
                                label="Email Id"
                                name='email'
                                fullWidth
                                value={formData?.email}
                                onChange={handleChange}
                                error={!isEmailValid || !!errors?.email}
                                helperText={!isEmailValid ? 'Invalid email id' : errors?.email ? "Email Id is required" : ""}

                            />
                            <TextField
                                required
                                margin="dense"
                                id="pan"
                                label="Pan No"
                                name='pan'
                                fullWidth
                                value={formData?.pan}
                                onChange={handleChange}
                                error={!!errors?.pan} // Highlight the field if there's an error
                                helperText={errors?.pan ? "PAN is required" : ""} // Display error message if present
                            />
                            <TextField
                                required
                                margin="dense"
                                id="gstno"
                                label="GST No"
                                name='gstno'
                                fullWidth
                                value={formData?.gstno}
                                onChange={handleChange}
                                error={!isgstvalid || !!errors?.gstno}
                                helperText={!isgstvalid ? 'Invalid GST number' : errors?.gstno ? "GST No is required" : ""}
                       />
                            <TextField
                               
                                margin="dense"
                                id="tinno"
                                label="TIN No"
                                name='tinno'
                                fullWidth
                                value={formData?.tinno}
                                onChange={handleChange}
                            />
                            <TextField
                               
                                margin="dense"
                                id="add1"
                                label="House No"
                                name='add1'
                                fullWidth
                                value={formData?.add1}
                                onChange={handleChange}
                            />
                            <TextField
                            required
                                id="add2"
                                label="Street Address"
                                name='add2'
                                value={formData?.add2}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                error={!!errors?.add2} // Highlight the field if there's an error
                                helperText={errors?.add2} // Display error message if present
                            />
                            <TextField
                                select
                                label="City"
                                name='add3'
                                value={formData?.add3}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                // error={!!errors?.add3} // Highlight the field if there's an error
                                // helperText={errors?.add3} // Display error message if present
                            >
                                {city.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="District"
                                name='add4'
                                value={formData?.add4}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                // error={!!errors?.add4} // Highlight the field if there's an error
                                // helperText={errors?.add4} // Display error message if present
                            >
                                {dist.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label="State"
                                name='add5'
                                value={formData?.add5}
                                onChange={handleChange}
                                fullWidth
                                margin="dense"
                                // error={!!errors?.add5} // Highlight the field if there's an error
                                // helperText={errors?.add5} // Display error message if present
                            >
                                {state.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                required
                                margin="dense"
                                id="add6"
                                label="Pin Code"
                                name='add6'
                                fullWidth
                                value={formData?.add6}
                                onChange={handleChange}
                                error={!ispincode1valid}
                                helperText={!ispincode1valid ? 'Invalid Pin Code' : ''}
                            />
                            <TextField
                                select
                                margin="dense"
                                label="Status"
                                name='isactive'
                                fullWidth
                                value={formData?.isactive === '' ? '' : Number(formData?.isactive)}
                                onChange={handleChange}
                                error={!!errors?.isactive} // Show error if empty
                                helperText={errors?.isactive ? "Status is required" : ""}
                            >
                                {status.map((option: { value: any; label: any; }) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={submit} variant='contained' color="primary" disabled={!isMobileValid || !isEmailValid || !ispincode1valid}>
                                {selectedItem ? 'Update' : 'Save'}
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </Container>
            <Footer />
        </>
    );

}
export default Plant;

function setRole(data: any) {
    throw new Error("Function not implemented.");
}
function setName(data: any) {
    throw new Error("Function not implemented.");
}

