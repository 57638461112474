import React from 'react';
import MenuItem1 from './MenuItem1';
import './sidebar1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight, faTachometerAlt, faShoppingCart, faUsers, faChartBar, faFolder, faGear,faAnchor,faTruckFast,faFileEdit,faUsersGear,faStore,faCheckDouble,faWarehouse,faCaravan,faHandshake,faGears,faCalculator, faUserTag, faUser, faUserShield, faBuilding, faSitemap, faIdBadge, faPeopleCarry, faUserCheck, faUserGraduate, faHardHat, faUserSecret, faUserTie, faCity, faMapMarkerAlt, faIndustry, faCogs, faHome, faCalendar, faChartLine, faCalendarAlt, faFileInvoice, faMoneyBillWave, faReceipt, faFileAlt, faUserFriends, faTools, faClipboardCheck, faChartPie, faWallet, faClipboardList, faThumbsUp, faStar, faShieldAlt, faGauge, faAward, faVial, faWrench, faCubes, faDraftingCompass, faShapes, faPenRuler, faPencilRuler, faBarsStaggered, faMoneyCheckDollar, faPhone, faShield, faShieldHalved, faComment, faRankingStar, faPeopleGroup, faBullseye, faFileContract, faAddressCard, faHourglassHalf, faFileSignature, faStopwatch, faClockRotateLeft, faTriangleExclamation, faBookBookmark, faScroll, faSquare, faSquarePollHorizontal } from '@fortawesome/free-solid-svg-icons';
import * as icons from '@material-ui/icons';

const Sidebar: React.FC = () => {
  return (
    <div className="sidebar">
      {/* <MenuItem1 title="Dashboard"  icon={<FontAwesomeIcon icon={faTachometerAlt} />} url="/crm/addenquiry"/>
      <MenuItem1 title="Orders" icon={<FontAwesomeIcon icon={faShoppingCart} />} />
      <MenuItem1 title="Customers" icon={<FontAwesomeIcon icon={faUsers} />} />
      <MenuItem1 title="Reports" icon={<FontAwesomeIcon icon={faChartBar} />} /> */}
      <MenuItem1 title="Super Admin" icon={<FontAwesomeIcon icon={faUserShield} />} subItems={[
        { title: 'User Settings', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
            { title: 'User Role', url: '/SuperAdmin/UserSettings/UserRole', icon: <FontAwesomeIcon icon={faUserTag} /> },
            { title: 'User', url: '/SuperAdmin/UserSettings/User', icon: <FontAwesomeIcon icon={faUser} /> },
            { title: 'Department', url: '/SuperAdmin/UserSettings/Department', icon: <FontAwesomeIcon icon={faSitemap} /> },
            { title: 'Designation', url: '/SuperAdmin/UserSettings/Designation', icon: <FontAwesomeIcon icon={faIdBadge} /> },
            { title: 'Employee', url: '/SuperAdmin/UserSettings/Employee' , icon: <FontAwesomeIcon icon={faUserTie} />},
            { title: 'Places (City)', url: '/SuperAdmin/UserSettings/City' , icon: <FontAwesomeIcon icon={faMapMarkerAlt} />}
          ] },
        { title: 'Company Register', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
            { title: 'Company', url: '/SuperAdmin/CompanyRegister/Company', icon: <FontAwesomeIcon icon={faHome} />   },
            { title: 'Plant', url: '/SuperAdmin/CompanyRegister/Plant', icon: <FontAwesomeIcon icon={faIndustry} />   },
            { title: 'Financial Year', url: '/SuperAdmin/CompanyRegister/Financialyear' , icon: <FontAwesomeIcon icon={faCalendarAlt} />  },
            { title: 'Tax', url: '/SuperAdmin/CompanyRegister/Tax' , icon: <FontAwesomeIcon icon={faFileInvoice} />  }
          ] },
      ]} />
       <MenuItem1 title="Marketing CRM" icon={<FontAwesomeIcon icon={faUserFriends} />} subItems={[
        { title: 'General Settings', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
            { title: 'Mode Of Enquiry', url: '/MarketingCRM/GeneralSettings/ModeOfEnquiry', icon: <FontAwesomeIcon icon={faPhone} />  },
            { title: 'Enquiry Type', url: '/MarketingCRM/GeneralSettings/Type' , icon: <FontAwesomeIcon icon={faShieldHalved} /> },
            { title: 'Followup Type', url: '/MarketingCRM/GeneralSettings/FollowType' , icon: <FontAwesomeIcon icon={faComment} /> },
            { title: 'Priority', url: '/MarketingCRM/GeneralSettings/Priority', icon: <FontAwesomeIcon icon={faRankingStar} />  },
            { title: 'Status', url: '/MarketingCRM/GeneralSettings/Status', icon: <FontAwesomeIcon icon={faHourglassHalf} />  },
            { title: 'Terms & Conditions', url: '/MarketingCRM/GeneralSettings/Quoteterms' , icon: <FontAwesomeIcon icon={faFileContract} /> },
            { title: 'Team', url: '/MarketingCRM/GeneralSettings/Team' , icon: <FontAwesomeIcon icon={faPeopleGroup} /> },
            { title: 'Target', url: '/MarketingCRM/GeneralSettings/Target' , icon: <FontAwesomeIcon icon={faBullseye} /> }
          ] },
          { title: 'Lead/Enquiry Followup', url: '/MarketingCRM/LeadEnquiry' , icon: <FontAwesomeIcon icon={faClockRotateLeft} /> },
          { title: 'Customer Register', url: '/MarketingCRM/customer' , icon: <FontAwesomeIcon icon={faFileSignature} /> },
          { title: 'Lead Register', url: '/MarketingCRM/addlead', icon: <FontAwesomeIcon icon={faFileSignature} />  },
          { title: 'Enquiry Register', url: '/MarketingCRM/addenquiry', icon: <FontAwesomeIcon icon={faFileSignature} />  },
        
          { title: 'Request To Clarify', url: '/MarketingCRM/requesttoclarify' , icon: <FontAwesomeIcon icon={faTriangleExclamation} /> },
        { title: ' Lead Reports', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
       
            { title: 'Lead Detail Report', url: '/MarketingCRM/LeadReports/LeadReport' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
            { title: 'Lead Monthly Report', url: '/MarketingCRM/LeadReports/Leadmonthlyreport' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
            { title: 'Lead Status Wise Report', url: '/MarketingCRM/LeadReports/LeadStatuswiseReport' , icon: <FontAwesomeIcon icon={faFileEdit} /> }, //fade  },
            { title: 'Lead MIS Report', url: '/MarketingCRM/LeadReports/LeadMISReport' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
            { title: 'Lead Daily Follow Report', url: '/MarketingCRM/LeadReports/LeadDailyFollowReport' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
          ] },
          { title: 'Enquiry Reports', 
            icon: <FontAwesomeIcon icon={faBarsStaggered} />,
            subItems: [
              { title: 'Enquiry Detail Report', url: '/MarketingCRM/EnquiryReports/enquiryreport' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
              { title: 'Enquiry Monthly Report', url: '/MarketingCRM/EnquiryReports/Enquirymonthlyreport'  , icon: <FontAwesomeIcon icon={faFileEdit} /> },
              { title: 'Enquiry Status Wise Report', url: '/MarketingCRM/EnquiryReports/EnquiryStatusWiseReport' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
              { title: 'Enquiry MIS Report', url: '/MarketingCRM/EnquiryReports/MISDashboard' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
              { title: 'Daily Followup Report', url: '/MarketingCRM/EnquiryReports/DailyFollowupReport' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
              { title: 'Customer Detail Report', url: '/MarketingCRM/EnquiryReports/CustomerDetail' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
              // { title: 'Enquiry Analysis Dashboard', url: '/MarketingCRM/Reports/EnquiryAnalysisDashboard' , icon: <FontAwesomeIcon icon={faFileEdit} />  }
            ] },
      ]} />
       <MenuItem1 title="Design" icon={<FontAwesomeIcon icon={faFileEdit} />} subItems={[
        { title: 'General Settings', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
            { title: 'Test & Accessories', url: '/design/testaccessories' , icon: <FontAwesomeIcon icon={faWrench} /> },
            { title: 'Model', url: '/design/designmodel', icon: <FontAwesomeIcon icon={faShapes} />  }
          ] },
          { title: 'Design Followup', url: '/Design/DesignFollowup', icon: <FontAwesomeIcon icon={faPencilRuler} />  },
        { title: 'Reports', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
            { title: 'Concept Drawing Report', url: '/design/company'  , icon: <FontAwesomeIcon icon={faFileEdit} /> },
            { title: 'Production Drawing Report ', url: '/admin/plant' , icon: <FontAwesomeIcon icon={faFileEdit} />  },
            { title: 'ECN Report', url: '/admin/financialyear'  , icon: <FontAwesomeIcon icon={faFileEdit} /> }
          ] },
      ]} />
        <MenuItem1 title="Sale Order" icon={<FontAwesomeIcon icon={faShoppingCart} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
          <MenuItem1 title=" HRMS" icon={<FontAwesomeIcon icon={faMoneyCheckDollar} />} subItems={[
         { title: 'General Settings', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
            { title: 'Blood Group', url: '/HRMS/GeneralSettings/BloodGroup', icon: <FontAwesomeIcon icon={faFileEdit} />  },
          ] },
        { title: 'Level 2' },
      ]} />
            <MenuItem1 title=" VMS " icon={<FontAwesomeIcon icon={faShoppingCart} />} subItems={[
        { title: 'General Settings', 
          icon: <FontAwesomeIcon icon={faBarsStaggered} />,
          subItems: [
            { title: 'Things', url: '/VMS/GeneralSettings/Vmsthings', icon: <FontAwesomeIcon icon={faFileEdit} />  },
          ] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Production" icon={<FontAwesomeIcon icon={faTools} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
       <MenuItem1 title="Stock & Inventory" icon={<FontAwesomeIcon icon={faStore} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Quality Management" icon={<FontAwesomeIcon icon={faThumbsUp} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Ware House" icon={<FontAwesomeIcon icon={faWarehouse} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
         <MenuItem1 title="Logistics" icon={<FontAwesomeIcon icon={faTruckFast} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="Service" icon={<FontAwesomeIcon icon={faHandshake} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
        <MenuItem1 title="HRMS Payroll" icon={<FontAwesomeIcon icon={faUsersGear} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />
       <MenuItem1 title="Accounts" icon={<FontAwesomeIcon icon={faClipboardList} />} subItems={[
        { title: 'Level 2', subItems: [{ title: 'Level 3' }] },
        { title: 'Level 2' },
      ]} />

    </div>
  );
};

export default Sidebar;
