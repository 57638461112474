import React, { useEffect, useRef, useState } from "react";

const VoiceControlledForm: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [recognizedText, setRecognizedText] = useState<string>("");
  const [speechActive, setSpeechActive] = useState<boolean>(false);

  useEffect(() => {
    // Check if browser supports SpeechRecognition
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert("Speech Recognition not supported in this browser");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = false; // Stop after each command
    recognition.interimResults = false; // Only provide final results
    recognition.lang = "en-US"; // Language setting

    recognition.onstart = () => setSpeechActive(true);
    recognition.onend = () => setSpeechActive(false);
    
    recognition.onresult = (event: SpeechRecognitionEvent) => {
      const transcript = event.results[0][0].transcript.trim().toLowerCase();
      setRecognizedText(transcript);
      handleVoiceCommand(transcript); // Handle recognized command
    };

    // Start recognition on component mount
    recognition.start();

    // Cleanup on unmount
    return () => recognition.stop();
  }, []);

  const handleVoiceCommand = (command: string) => {
    if (command.includes("click")) {
      // Simulate a button click
      document.getElementById("submit-button")?.click();
    } else if (command.includes("type") && inputRef.current) {
      // Extract text to type from command, e.g., "type hello"
      const textToType = command.replace("type", "").trim();
      inputRef.current.value = textToType;
    }
    
  };
  const bikeonclick = () => {
    const SpeechRecognition =
    (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

  if (!SpeechRecognition) {
    alert("Speech Recognition not supported in this browser");
    return;
  }

  const recognition = new SpeechRecognition();
  recognition.continuous = false; // Stop after each command
  recognition.interimResults = false; // Only provide final results
  recognition.lang = "en-US"; // Language setting

  recognition.onstart = () => setSpeechActive(true);
  recognition.onend = () => setSpeechActive(false);
  
  recognition.onresult = (event: SpeechRecognitionEvent) => {
    const transcript = event.results[0][0].transcript.trim().toLowerCase();
    setRecognizedText(transcript);
    handleVoiceCommand(transcript); // Handle recognized command
  };

  // Start recognition on component mount
  recognition.start();

  // Cleanup on unmount
  return () => recognition.stop();
  };
  return (
    <>
    <div style={{ padding: "20px" }}>
      <h2>AI Voice-Controlled Form</h2>

      <input
        ref={inputRef}
        type="text"
        placeholder="Say 'type [your text]'"
        style={{ marginRight: "10px" }}
      />

<button onClick={bikeonclick}>
    mike on
</button>
      <button id="submit-button" onClick={() => alert("Button Clicked!")}>
        Submit
      </button>

      <p>Recognized Text: {recognizedText}</p>
      <p>Speech Active: {speechActive ? "Listening..." : "Not Listening"}</p>
    </div>
    </>
  );
};

export default VoiceControlledForm;
