
import { Bar, Doughnut, Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    LineElement,
    PointElement,
    Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Accordion, Button, Col, Container, Row, Table } from 'react-bootstrap';
import { SyntheticEvent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarChart, faCircleRight, faCommentDots, faCube, faFaceGrinTongueSquint, faFileArchive, faHandPointDown, faHandPointUp, faICursor, faLayerGroup, faLineChart, faList, faListCheck, faMessage, faPieChart, faQuoteLeftAlt, faWonSign } from '@fortawesome/free-solid-svg-icons';
import { Box, colors, Grid, Paper, Tab, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Typography } from '@mui/material';
import { FaEmpire, FaRegMessage } from 'react-icons/fa6';
import { Padding } from '@mui/icons-material';
import apiService from 'src/services/api-service';
import { useNavigate } from 'react-router';

type Props = {}

// Register the required components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

// Register the necessary chart components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, ChartDataLabels,
    //Filler,  // Required for the area fill effect
);

// Register required Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, ChartDataLabels);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
export default function CrmDashboard({ }: Props) {
    const [isExpanded, setIsExpanded] = useState(true);
    const [countData, setCountData] = useState([]);
    const [countDataEnq, setCountDataEnq] = useState([]);
    const [countPriority, setCountPriority] = useState([]);
    const [countPriorityEnq, setCountPriorityEnq] = useState([]);
    const [countMode, setCountMode] = useState([]);
    const [countModeEnq, setCountModeEnq] = useState([]);
    const [countStatus, setCountStatus] = useState([]);
    const [countStatusEnq, setCountStatusEnq] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activeItem, setActiveItem] = useState<string>('Today');
    const [activeItem1, setActiveItem1] = useState<string>('Today');
    const [leadList, setLeadList] = useState([]);
    const [EnqList, setEnqList] = useState([]);
    const [countNewex, setCountNewex] = useState([]);
    const [countNewexEnq, setCountNewexEnq] = useState([]);
    const [cusOpp, setCusOpp] = useState([]);
    const [cusOppEnq, setCusOppEnq] = useState([]);
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [filteredData1, setFilteredData1] = useState([]);
    const navigate = useNavigate();
    //Pie chart
    const data = {
        labels: ['Hot', 'Warm', 'Cold'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3],
                backgroundColor: [
                    '#ff625f',
                    '#384647',
                    '#03aaa2',


                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderColor: [
                    '#384647',
                    '#03aaa2',

                    '#ff625f',
                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderWidth: 1,
            },
        ],
    };

    // Chart options
    // const options = {
    //     responsive: true,
    //     plugins: {
    //         legend: {
    //             position: 'top' as const,
    //         },
    //         tooltip: {
    //             enabled: true,
    //         },
    //     },
    // };

    const options = {
        plugins: {
            datalabels: {
                color: '#fff',           // Color of the labels
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex]; // Get the label (name)
                    return `${label}: ${value}`;  // Display both label and value
                },
                font: {
                    weight: 'bold' as const,
                    size: 12,
                },
            },
            legend: {
                display: false,
                position: 'top' as const,
            },
        },
    };
    const optionssource = {
        plugins: {
            datalabels: {
                color: '#fff',           // Color of the labels
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex]; // Get the label (name)
                    return ` ${value}`;  // Display both label and value
                },
                font: {
                    weight: 'bold' as const,
                    size: 12,
                },
            },
            legend: {
                display: true,
                position: 'top' as const,
            },
        },
    };

    // // Doughnut 
    // const data2 = {
    //     labels: labels,
    //     datasets: [
    //         {
    //             label: 'Priority',
    //             data: dataValues,
    //             backgroundColor: [
    //                 '#ff6384',
    //                 '#4bc0c0',
    //                 '#36a2eb',
    //             ],
    //             borderColor: [
    //                 '#ff6384',
    //                 '#4bc0c0',
    //                 '#36a2eb',
    //             ],
    //             borderWidth: 1,
    //         },
    //     ],
    // };
    const dataType = {
        labels: ['New', 'Ex-Customer', 'Repeat'],
        datasets: [
            {
                label: 'Leads Count',
                data: [12, 19, 3],
                backgroundColor: [
                    '#ff6384',
                    '#4bc0c0',
                    '#36a2eb',


                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderColor: [
                    '#ff6384',
                    '#4bc0c0',
                    '#36a2eb',

                    '#f3c613',
                    '#606d6f',
                    '#87d4e8',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataDealSource = {
        labels: ['Mail-ID', 'Tender', 'Expo', 'Phone Call', 'Direct Visit'],
        datasets: [
            {
                label: 'Deals Count',
                data: [12, 19, 3, 6, 10],
                backgroundColor: [
                    '#008779',

                ],
                borderColor: [
                    '#008779',
                ],
                borderWidth: 1,
            },
        ],
    };
    //barcahrt

    //Stocked Bar  //Doughnut Stages

    // const data4 = {
    //     labels: ['Potential', 'Interested', 'Qualified', 'Ex-Customer', 'Not Interested', 'Bad Fit', 'Convert To Enquiry', 'Canceled'],
    //     datasets: [
    //         {
    //             label: 'Leads Count',
    //             data: [12, 19, 3, 5, 2, 3],
    //             backgroundColor: '#008779',
    //         },

    //     ],
    // };

    const dataDealStatus = {
        labels: ['P0s Enquiry Received', 'P1-Q Budgetary Quote', 'P1-QTN Quotation', 'P2 Tender Submitted', 'P3 Under Negotiation', 'P4 Order Receipt', 'L1 OL -Price High', 'L2 OL - Technically Rejected'],
        datasets: [
            {
                label: 'Deals Count',
                data: [12, 19, 3, 5, 2, 3, 7, 15],
                backgroundColor: '#008779',
            },
            // {
            //     label: 'Dataset 2',
            //     data: [5, 10, 8, 12, 6, 7],
            //     backgroundColor: '#384647',
            // },
            // {
            //     label: 'Dataset 3',
            //     data: [2, 3, 10, 15, 9, 12],
            //     backgroundColor: '#ff625f',
            // },
        ],
    };


    const options4 = {

        responsive: true,
        maintainAspectRatio: false,
        // plugins: {
        //     legend: {
        //         position: 'top' as const,
        //     },
        //     title: {
        //         display: false,
        //         text: 'Stacked Bar Chart Example',
        //     },
        // },
        plugins: {

            datalabels: {
                color: '#fff',           // Color of the labels
                formatter: (value, context) => {
                    const label = context.chart.data.labels[context.dataIndex]; // Get the label (name)
                    return `${value}`;  // Display both label and value
                },
                font: {
                    weight: 'bold' as const,
                    size: 12,
                },
            },
            legend: {
                display: true,
                position: 'top' as const,
            },
        },
        scales: {
            x: {
                stacked: true,  // Enable stacking on x-axis
            },
            y: {
                stacked: true,  // Enable stacking on y-axis
                beginAtZero: true,  // Ensures the y-axis starts at 0
            },
        },
    };
    //Area Line Chart
    const dataAreaLine = {
        labels: ['Apl', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct'],
        datasets: [
            {
                label: 'Target',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,  // Fills the area below the line
                borderColor: '#ff6384',
                backgroundColor: '#ff6384',
                tension: 0.4,  // Smooth curve effect
            },
            {
                label: 'Generated',
                data: [45, 49, 60, 21, 36, 62, 54],
                fill: false,  // Fills the area below the line
                borderColor: '#36a2eb',
                backgroundColor: '#36a2eb',
                tension: 0.4,  // Smooth curve effect
            },
            {
                label: 'Converted',
                data: [10, 19, 16, 11, 16, 12, 20],
                fill: false,  // Fills the area below the line
                borderColor: '#4bc0c0',
                backgroundColor: '#4bc0c0',
                tension: 0.4,  // Smooth curve effect
            },
        ],
    };
    const optionsAreaLine = {
        plugins: {
            datalabels: {
                display: true,
                align: 'top' as const,
                backgroundColor: '#ddd',
                color: "#000",
                borderRadius: 3,
                font: {
                    size: 10,
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    //Stocked Bar

    const optionsStockedbar = {
        responsive: true,
        plugins: {
            datalabels: {

                color: "#fff",
            },
            legend: {
                position: 'top' as const,
            },
            //   title: {
            //     display: true,
            //     // text: 'Stacked Bar Chart Example',
            //   },
        },
        scales: {
            x: {
                stacked: true, // Stacking on the x-axis
            },
            y: {
                stacked: true, // Stacking on the y-axis
                beginAtZero: true,
            },
        },
    };


    const getCurrentDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getFirstDayOfMonth = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    };

    const formatToInput = (date: string) => {
        const [year, month, day] = date.split('-');
        return `${day}-${month}-${year}`;
    };

    const formatToISO = (date: string) => {
        const [day, month, year] = date.split('-');
        return `${year}-${month}-${day}`;
    };

    const [fromDate, setFromDate] = useState(formatToISO(getFirstDayOfMonth()));
    const [toDate, setToDate] = useState(formatToISO(getCurrentDate()));

    const handleDateChange = (e: any) => {
        const formattedDate = formatToISO(e.target.value);
        setFromDate(formattedDate);
    };
    const handleDateChange1 = (e: any) => {
        const formattedDate = formatToISO(e.target.value);
        setToDate(formattedDate);
    };



    const handleClick = () => {

        // setIsExpanded(!isExpanded);
    };
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    function TabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
        };
    }
    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }
    const rowpad = {
        Padding: "0px", // camelCase

    };


    useEffect(() => {
        // apiService.getcount({})
        apiService.leadfollowgetcount({})
            .then(response => {
                setCountData(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));


        apiService.Prioritycount(fromDate,toDate,search)
            .then(response => {
                setCountPriority(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.ModeCount(fromDate,toDate,search)
            .then(response => {
                setCountMode(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.StatusCount(fromDate,toDate,search)
            .then(response => {
                setCountStatus(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.leadnewexcount(fromDate,toDate,search)
            .then(response => {
                setCountNewex(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.CustomerOpportunity({})
            .then(response => {
                setCusOpp(response.data);
                console.log("=> ", response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));
    }, [fromDate,toDate,search]);

    useEffect(() => {
        // apiService.getcount({})
        apiService.Enqfollowgetcount({})
            .then(response => {
                setCountDataEnq(response.data);
              

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.PrioritycountEnq(fromDate,toDate,search)
            .then(response => {
                setCountPriorityEnq(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.ModeCountEnq(fromDate,toDate,search)
            .then(response => {
                setCountModeEnq(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.StatusCountEnq(fromDate,toDate,search)
            .then(response => {
                setCountStatusEnq(response.data);
              

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.leadnewexcountEnq(fromDate,toDate,search) 
            .then(response => {
                setCountNewexEnq(response.data);

            })
            .catch(err => console.error('Error fetching count data:', err));

        apiService.CustomerOpportunityEnq({})
            .then(response => {
                setCusOppEnq(response.data);
               

            })
            .catch(err => console.error('Error fetching count data:', err));

    }, [fromDate,toDate,search]);

    useEffect(() => {
      

        if (activeItem) {
            apiService.getleadfollowlist(rowsPerPage.toString(), activeItem, (page + 1).toString())
                .then(response => {
                    setLeadList(response.data);


                })
                .catch(err => console.error('Error fetching list data:', err));
        }
    }, [activeItem, rowsPerPage, page]);

    useEffect(() => {
       

        if (activeItem1) {
            apiService.getenqfollowlist(rowsPerPage.toString(), activeItem1, (page + 1).toString())
                .then(response => {
                    setEnqList(response.data);


                })
                .catch(err => console.error('Error fetching list data:', err));
        }
    }, [activeItem1, rowsPerPage, page]);

    const labels1 = countPriority.map(item => item.name);
    const dataValues1 = countPriority.map(item => item.count);

    const labelsenq1 = countPriorityEnq.map(item => item.name);
    const dataValuesenq1 = countPriorityEnq.map(item => item.count);

    const data2 = {
        labels: labels1,
        datasets: [
            {
                label: 'Count',
                data: dataValues1,
                backgroundColor: [
                    '#4bc0c0',
                    '#36a2eb',
                    '#ff6384'

                ],
                borderColor: [
                    '#4bc0c0',
                    '#36a2eb',
                    '#ff6384',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataenq2 = {
        labels: labelsenq1,
        datasets: [
            {
                label: 'Count',
                data: dataValuesenq1,
                backgroundColor: [
                    '#4bc0c0',
                    '#36a2eb',
                    '#ff6384'

                ],
                borderColor: [
                    '#4bc0c0',
                    '#36a2eb',
                    '#ff6384',
                ],
                borderWidth: 1,
            },
        ],
    };

    const labels2 = countMode.map(item => item.name);
    const dataCounts2 = countMode.map(item => item.mode_count);

    const labelsenq2 = countModeEnq.map(item => item.name);
    const dataCountsenq2 = countModeEnq.map(item => item.mode_count);

    const dataSource = {
        labels: labels2,
        datasets: [
            {
                label: 'Leads Count',
                data: dataCounts2,
                backgroundColor: [
                    '#008779',
                ],
                borderColor: [
                    '#008779',
                ],
                borderWidth: 1,
            },
        ],
    };

    const dataSourceenq = {
        labels: labelsenq2,
        datasets: [
            {
                label: 'Enquiries Count',
                data: dataCountsenq2,
                backgroundColor: [
                    '#008779',
                ],
                borderColor: [
                    '#008779',
                ],
                borderWidth: 1,
            },
        ],
    };

    const labels3 = countStatus.map(item => item.name);
    const dataCounts3 = countStatus.map(item => item.count);

    const labelsenq3 = countStatusEnq.map(item => item.name);
    const dataCountsenq3 = countStatusEnq.map(item => item.count);

    const data4 = {
        labels: labels3,
        datasets: [
            {
                label: 'Leads Count',
                data: dataCounts3,
                backgroundColor: '#008779',
            },
        ],
    };

    const dataenq4 = {
        labels: labelsenq3,
        datasets: [
            {
                label: 'Enquiries Count',
                data: dataCountsenq3,
                backgroundColor: '#008779',
            },
        ],
    };


    

    const labels4 = countNewex.map(item => item.newex);
    const dataCountsgen4 = countNewex.map(item => item.count);
    const dataCountsconv4 = countNewex.map(item => item.Converted);
    const dataCountsoppur4 = countNewex.map(item => item.Opportunity);

    const labelsenq4 = countNewexEnq.map(item => item.newex);
    const dataCountsgenenq4 = countNewexEnq.map(item => item.count);
    const dataCountsconvenq4 = countNewexEnq.map(item => item.Order);
    const dataCountsoppurenq4 = countNewexEnq.map(item => item.Opportunity);

    const dataStockedbar = {
        labels: labels4,
        datasets: [
            {
                label: 'Generated',
                data: dataCountsgen4,
                backgroundColor: '#ff6384',
            },
            {
                label: 'Opportunity',
                data: dataCountsoppur4,
                backgroundColor: '#36a2eb',
            },
            {
                label: 'Converted',
                data: dataCountsconv4,
                backgroundColor: '#4bc0c0',
            },
        ],
    };

    const dataStockedbarenq = {
        labels: labels4,
        datasets: [
            {
                label: 'Generated',
                data: dataCountsgenenq4,
                backgroundColor: '#ff6384',
            },
            {
                label: 'Opportunity',
                data: dataCountsoppurenq4,
                backgroundColor: '#36a2eb',
            },
            {
                label: 'Order Won',
                data: dataCountsconvenq4,
                backgroundColor: '#4bc0c0',
            },
        ],
    };

    const handleGetlist = (type: string) => {
        setActiveItem(type);
        console.log(`${type} clicked`);
    };

 

    const handleGetlist1 = (type: string) => {
        setActiveItem1(type);
        console.log(`${type} clicked`);
    };

    const updateEnquiry = (id: number) => {
        console.log("id", id);

        navigate('/lead/updatelead', { state: { id } });
    }
    const updateEnquiry1 = (id: number) => {
        console.log("id", id);

        navigate('/marketingcrm/updateenquiry', { state: { id } });
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value;
        setSearch(search);
    };

    useEffect(() => {
        const filteredleadList = leadList.filter(item =>
            Object.values(item).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(search.toLowerCase())
            )
        );
        setFilteredData(filteredleadList);
    }, [search, leadList]);
    useEffect(() => {
        const filteredleadList = EnqList.filter(item =>
            Object.values(item).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(search.toLowerCase())
            )
        );
        setFilteredData1(filteredleadList);
    }, [search, EnqList]);



    return (
        <>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row', // Set the flex direction to row
                    justifyContent: 'space-between', // Adjust spacing between elements if needed
                    alignItems: 'center', // Vertically center the items
                    marginTop: '15px',
                    marginLeft: '5px',
                }}
            >
                <Tabs style={{ marginTop: "15px;", marginLeft: "10px" }}
                    variant="scrollable"
                    scrollButtons="auto"
                    textColor="primary"
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    sx={{
                        '& .MuiTab-root': {
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: 'blue', // Changes background color on hover
                                color: '#fff',
                            },
                        },
                        '& .Mui-selected': {
                            backgroundColor: 'Blue', // Background color for selected tab
                            color: '#fff', // Keep the font color unchanged
                        },
                    }}
                >
                    <Tab label="Leads" {...a11yProps(0)} />
                    <Tab label="Deals(Enquiries)" {...a11yProps(1)} />
                </Tabs>
                <Box
                    sx={{
                        display: 'flex', // Enable flexbox for TextField alignment
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        alignItems: 'center', // Vertically align TextFields
                        gap: 2, // Adjust space between TextFields
                    }}
                >
                    <TextField
                        id="fdate"
                        label="From Date"
                        name="fdate"
                        type="date"
                        onChange={handleDateChange}
                        value={formatToInput(fromDate)}
                        InputLabelProps={{ shrink: true }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '5%', // Adjust this value as needed
                            },
                        }}
                        margin="dense"
                    />
                    <TextField
                        id="tdate"
                        label="To Date"
                        name="tdate"
                        type="date"
                        value={formatToInput(toDate)}  // Display initial value
                        onChange={handleDateChange1}
                        InputLabelProps={{ shrink: true }}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '5%', // Adjust this value as needed
                            },
                        }}
                        margin="dense"
                    />
                    <TextField
                        label="Search"
                        variant="outlined"
                        value={search}
                        onChange={handleSearch}
                        margin="dense"
                        sx={{
                            width: '30%', // Adjust width of Search field
                            '& .MuiInputBase-root': {
                                height: '5%', // Adjust this value as needed
                            },
                        }}
                    />
                </Box></Box>

            <TabPanel value={value} index={0}>
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid xs={12} md={5} style={{ paddingLeft: "10px" }}>
                        <Box p={0}>
                            <div className='divpanal'>
                                <div className='dpheader' onClick={handleClick} style={{ textAlign: "center" }}>
                                    <label><span className=''><FontAwesomeIcon icon={faCommentDots} /></span> Leads Follow</label>
                                </div>
                                {isExpanded && (
                                    <div className='dpmain'>
                                        <Grid container className='dpbox'
                                        >
                                            {countData.map((item, index) => (
                                                <Grid key={index} className='dpgrid' xs={12} md={2.4} onClick={() => handleGetlist(item.name)}
                                                    style={{
                                                        backgroundColor: activeItem === item.name ? '#00184a' : 'transparent',
                                                        borderRadius: activeItem === item.name ? '2px' : '0', // Rounded corners if active
                                                        color: activeItem === item.name ? 'white' : 'none',
                                                        padding: '5px', // Optional: Add some padding
                                                        transition: 'background-color 0.3s ease', // Smooth transition
                                                        cursor: 'pointer' // Change cursor to pointer
                                                    }}
                                                >
                                                    <label >{item.name}</label><br />
                                                    <span style={{ color: activeItem === item.name ? 'white' : 'none', }}>{item.count}</span>
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </div>
                                )}

                            </div>
                        </Box>
                        <Box p={0}>
                            <div className='divpanal' style={{ height: "448px" }}>
                                <div className='dpheader' onClick={handleClick}>
                                    <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Lead Statuses</label>
                                </div>

                                {isExpanded && (

                                    <div className='dpmain' style={{ width: '400px', height: '340px', marginLeft: "15px" }}>
                                        <Bar data={data4} options={options4} />

                                    </div>
                                )}

                            </div>
                        </Box>
                        <Box p={0}>
                            <div className='divpanal' style={{ height: "442px" }}>
                                <div className='dpheader' onClick={handleClick}>
                                    <label><span className=''><FontAwesomeIcon icon={faLineChart} /></span> Lead Generation Current Year <span>24-25</span></label>
                                </div>

                                {isExpanded && (

                                    <div className='dpmain' style={{ width: '400px', height: '400px', marginLeft: "15px" }}>
                                        <Line data={dataAreaLine} options={optionsAreaLine} height={250} />

                                    </div>
                                )}

                            </div>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={7} style={{}}>
                        <Grid container>
                            <Grid xs={12} md={5} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "292px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faPieChart} /></span> Leads Priority</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '220px', height: '220px', marginLeft: "24px", marginTop: "9px" }}>
                                                <Doughnut data={data2} options={options}>
                                                </Doughnut>

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={7} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Lead Sources</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '300px', height: '247px', marginLeft: "25px" }}>
                                                <Bar data={dataSource} options={options4} />

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={12} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faFileArchive} /></span> {activeItem} Followup Leads </label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{}}>
                                                <Paper>
                                                    <TableContainer style={{ maxHeight: '235px', overflowY: 'auto' }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow >
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Lead No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Customer</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Status</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {filteredData.map((lead, index) => (
                                                                    <TableRow
                                                                        key={lead.id}
                                                                        sx={{
                                                                            backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff", // Alternating background colors
                                                                        }}
                                                                    >
                                                                        <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell className='dpgrid' style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>
                                                                            <span style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline", cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    updateEnquiry(lead.id)
                                                                                }}
                                                                            >
                                                                                {lead.enqno}
                                                                            </span>
                                                                        </TableCell>
                                                                        <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>
                                                                            {lead.cusname}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                padding: "5px",
                                                                                fontSize: "12.7",
                                                                                border: "0px",
                                                                                borderBottom: "1px solid #eee",
                                                                                color: lead.isActive ? "#606060" : "red",
                                                                                fontWeight: "500",
                                                                            }}
                                                                        >
                                                                            {lead.status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Paper>
                                            </div>
                                        )}

                                    </div>
                                </Box>

                            </Grid>

                            <Grid xs={12} md={4} style={{}}>

                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "440px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faFileArchive} /></span> Opportunity Leads </label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain'>
                                                <Paper style={{ height: "395px" }}>
                                                    <TableContainer style={{ maxHeight: '390px', overflowY: 'auto' }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow >
                                                                    {/* <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell> */}
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}>Name</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}>Leads</TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {cusOpp.map((customer, index) => (
                                                                    <TableRow key={index} style={{ borderBottom: "1px solid #eee" }}>
                                                                        <TableCell
                                                                            style={{
                                                                                padding: "5px",
                                                                                fontSize: "12.7",
                                                                                border: "0px",
                                                                                display: "inline-flex",
                                                                                lineHeight: "3"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={require(`src/assets/staff/Suganthi.jpeg`)} // Modify this to dynamically select the right image
                                                                                alt="Company Logo"
                                                                                style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                            />
                                                                            <label style={{ paddingLeft: "7px" }}>{customer.customer_name}</label>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                padding: "5px",
                                                                                fontSize: "12.7",
                                                                                border: "0px"
                                                                            }}
                                                                        >
                                                                            <label
                                                                                style={{
                                                                                    fontWeight: "500",
                                                                                    color: "#0001fd",
                                                                                    textDecoration: "underline"
                                                                                }}
                                                                            >
                                                                                {customer.lead_count}
                                                                            </label>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}

                                                                {/* <TableRow style={{ borderBottom: "1px solid #eee" }}>
                                                                  
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", display: "inline-flex", lineHeight: "3" }}> <img
                                                                        src={require('src/assets/staff/Suganthi.jpeg')}
                                                                        alt="Company Logo"
                                                                        style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                    /> <label style={{ paddingLeft: "7px" }}> Suganthi</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>20</label> </TableCell>

                                                                </TableRow>
                                                                <TableRow style={{ borderBottom: "1px solid #eee" }}>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", display: "inline-flex", lineHeight: "3" }}> <img
                                                                        src={require('src/assets/staff/Sridevi.jpeg')}
                                                                        alt="Company Logo"
                                                                        style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                    /> <label style={{ paddingLeft: "7px" }}> Sri Devi</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>12</label> </TableCell>

                                                                </TableRow>
                                                                <TableRow style={{ borderBottom: "1px solid #eee" }}>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", display: "inline-flex", lineHeight: "3" }}> <img
                                                                        src={require('src/assets/staff/Christy.jpeg')}
                                                                        alt="Company Logo"
                                                                        style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                    /> <label style={{ paddingLeft: "7px" }}> Christy</label> </TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}><label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}>7</label> </TableCell>

                                                                </TableRow> */}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Paper>
                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={8} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "442px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Leads Generation Type</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '350px', height: '350px', marginLeft: "38px", marginTop: "9px" }}>
                                                <Bar data={dataStockedbar} options={optionsStockedbar} width={350} height={350} />

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>

                        </Grid>
                    </Grid>



                </Grid>
            </TabPanel >
            {/* Deal Enq */}
            < TabPanel value={value} index={1} >
                <Grid container style={{ marginTop: "10px" }}>
                    <Grid xs={12} md={5} style={{ paddingLeft: "10px" }}>
                        <Box p={0}>
                            <div className='divpanal'>
                                <div className='dpheader' onClick={handleClick} style={{ textAlign: "center" }}>
                                    <label><span className=''><FontAwesomeIcon icon={faCommentDots} /></span> Deals Follow</label>
                                </div>
                                {isExpanded && (
                                    <div className='dpmain'>
                                        <Grid container className='dpbox'>
                                            {countDataEnq.map((item, index) => (
                                                <Grid key={index} className='dpgrid' xs={12} md={2.4} onClick={() => handleGetlist1(item.name)}
                                                    style={{
                                                        backgroundColor: activeItem1 === item.name ? '#00184a' : 'transparent',
                                                        borderRadius: activeItem1 === item.name ? '0' : '0', // Rounded corners if active
                                                        color: activeItem1 === item.name ? 'white' : 'none',
                                                        padding: '5px', // Optional: Add some padding
                                                        transition: 'background-color 0.3s ease', // Smooth transition
                                                        cursor: 'pointer' // Change cursor to pointer
                                                    }}
                                                >
                                                    <label >{item.name}</label><br />
                                                    <span style={{ color: activeItem1 === item.name ? 'white' : 'none', }}>{item.count}</span>
                                                </Grid>
                                            ))}

                                        </Grid>
                                    </div>
                                )}

                            </div>
                        </Box>
                        <Box p={0}>
                            <div className='divpanal' style={{ height: "442px" }}>
                                <div className='dpheader' onClick={handleClick}>
                                    <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Deal Statuses</label>
                                </div>

                                {isExpanded && (

                                    <div className='dpmain' style={{ width: '400px', height: '340px', marginLeft: "15px" }}>
                                        <Bar data={dataenq4} options={options4} />

                                    </div>
                                )}

                            </div>
                        </Box>
                        <Box p={0}>
                            <div className='divpanal' style={{ height: "442px" }}>
                                <div className='dpheader' onClick={handleClick}>
                                    <label><span className=''><FontAwesomeIcon icon={faLineChart} /></span> Lead Generation Current Year <span>24-25</span></label>
                                </div>

                                {isExpanded && (

                                    <div className='dpmain' style={{ width: '400px', height: '400px', marginLeft: "15px" }}>
                                        <Line data={dataAreaLine} options={optionsAreaLine} height={250} />

                                    </div>
                                )}

                            </div>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={7} style={{}}>
                        <Grid container>
                            <Grid xs={12} md={5} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "292px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faPieChart} /></span> Deals Priority</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '220px', height: '220px', marginLeft: "24px", marginTop: "9px" }}>
                                                <Doughnut data={dataenq2} options={options}>
                                                </Doughnut>

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={7} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Deal Sources</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '300px', height: '247px', marginLeft: "25px" }}>
                                                <Bar data={dataSourceenq} options={options4} />

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={12} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal'>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faFileArchive} /></span> Today Followup Deals </label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{}}>
                                                <Paper>
                                                    <TableContainer>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Deal No</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Customer</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>Status</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>

                                                                {filteredData1.map((lead, index) => (
                                                                    <TableRow
                                                                        key={lead.id}
                                                                        sx={{
                                                                            backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff", // Alternating background colors
                                                                        }}
                                                                    >
                                                                        <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>
                                                                            {index + 1}
                                                                        </TableCell>
                                                                        <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>
                                                                            <label style={{ fontWeight: "500", color: "#0001fd", textDecoration: "underline" }}
                                                                                onClick={() => {
                                                                                    updateEnquiry1(lead.id)
                                                                                }}
                                                                            >
                                                                                {lead.enqno}
                                                                            </label>
                                                                        </TableCell>
                                                                        <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>
                                                                            {lead.cusname}
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                padding: "5px",
                                                                                fontSize: "12.7",
                                                                                border: "0px",
                                                                                borderBottom: "1px solid #eee",
                                                                                color: lead.isActive ? "#606060" : "red",
                                                                                fontWeight: "500",
                                                                            }}
                                                                        >
                                                                            {lead.status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Paper>
                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={4} style={{}}>

                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "440px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faFileArchive} /></span> Opportunity Leads </label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain'>
                                                <Paper style={{ height: "395px" }}>
                                                    <TableContainer style={{ maxHeight: '390px', overflowY: 'auto' }}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow >
                                                                    {/* <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px", borderBottom: "1px solid #eee" }}>S.No</TableCell> */}
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}>Name</TableCell>
                                                                    <TableCell style={{ padding: "5px", fontSize: "12.7", border: "0px" }}>Leads</TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {cusOppEnq.map((customer, index) => (
                                                                    <TableRow key={index} style={{ borderBottom: "1px solid #eee" }}>
                                                                        <TableCell
                                                                            style={{
                                                                                padding: "5px",
                                                                                fontSize: "12.7",
                                                                                border: "0px",
                                                                                display: "inline-flex",
                                                                                lineHeight: "3"
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={require(`src/assets/staff/Suganthi.jpeg`)} // Modify this to dynamically select the right image
                                                                                alt="Company Logo"
                                                                                style={{ width: 40, height: 40, borderRadius: "50%" }}
                                                                            />
                                                                            <label style={{ paddingLeft: "7px" }}>{customer.customer_name}</label>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={{
                                                                                padding: "5px",
                                                                                fontSize: "12.7",
                                                                                border: "0px"
                                                                            }}
                                                                        >
                                                                            <label
                                                                                style={{
                                                                                    fontWeight: "500",
                                                                                    color: "#0001fd",
                                                                                    textDecoration: "underline"
                                                                                }}
                                                                            >
                                                                                {customer.lead_count}
                                                                            </label>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}


                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>

                                                </Paper>
                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                            <Grid xs={12} md={8} style={{}}>
                                <Box p={0}>
                                    <div className='divpanal' style={{ height: "442px" }}>
                                        <div className='dpheader' onClick={handleClick}>
                                            <label><span className=''><FontAwesomeIcon icon={faBarChart} /></span> Leads Generation Type</label>
                                        </div>
                                        {isExpanded && (
                                            <div className='dpmain' style={{ width: '350px', height: '350px', marginLeft: "38px", marginTop: "9px" }}>
                                                <Bar data={dataStockedbarenq} options={optionsStockedbar} width={350} height={350} />

                                            </div>
                                        )}

                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>



                </Grid>
            </TabPanel >

        </>
    )
}