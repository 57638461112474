import { Helmet } from 'react-helmet-async';
import PageTitle from 'src/components/PageTitle';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    Box,
    TextField,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Modal,
    Typography,
    Tabs,
    Tab
} from '@mui/material';
import Footer from 'src/components/Footer';
import apiService from 'src/services/api-service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { login } from 'src/api-login';


interface LocationState {
    message?: string;
}

function Enquiry() {
    // For Grid Table
    const [data, setData] = useState([]);
    const [data1, setData1] = useState([]);
    const [countData, setCountData] = useState([]);
    const [countData1, setCountData1] = useState([]);
    // For Grid Table Pagination
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [isSearchModalOpen1, setIsSearchModalOpen1] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuery1, setSearchQuery1] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [filteredData1, setFilteredData1] = useState([]);
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState<string>('Today');
    const [activeItem1, setActiveItem1] = useState<string>('Today');


    const location = useLocation();

    // useEffect(() => {
    //     // Type assertion to specify the expected shape of location.state
    //     const state = location.state as LocationState;

    //     if (state?.message) {
    //         toast.success(state.message);
    //     }
    // }, [location.state]);

    useEffect(() => {
        const state = location.state as LocationState;

        if (state?.message) {
            toast.success(state.message);

            // Clear the message after displaying it
            navigate(location.pathname, { state: {} }); // Replace state with an empty object
        }
    }, [location.state, navigate, location.pathname]);
    // Fetch count data once on component mount
    // useEffect(() => {
    //     apiService.getcount({})
    //         .then(response => {
    //             setCountData(response.data);
    //         })
    //         .catch(err => console.error('Error fetching count data:', err));
    // }, []);
    useEffect(() => {
        const fetchData = () => {
            apiService.getcount(searchQuery.toLowerCase())
                .then(response => {
                    setCountData(response.data);
                })
                .catch(err => console.error('Error fetching count data:', err));
        };

        // Initial fetch
        fetchData();

        // Set interval to fetch every 5 seconds
        const intervalId = setInterval(fetchData, 5000);

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, [searchQuery]);

    useEffect(() => {
        const fetchDatacount = () => {
            apiService.getleadcount({})
                .then(response => {
                    setCountData1(response.data);
                    console.log('countData1', response.data);

                })
                .catch(err => console.error('Error fetching count data:', err));
        };
        fetchDatacount();

        // Set interval to fetch every 5 seconds
        const intervalId = setInterval(fetchDatacount, 5000);

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, []);

    // Fetch list data when activeItem, rowsPerPage, or page changes
    useEffect(() => {
        const fetchDatalist = () => {
            if (activeItem) {
                apiService.getlist(rowsPerPage.toString(), activeItem, (page + 1).toString())
                    .then(response => {
                        setData(response.data);
                        console.log("list=>", response.data);

                    })
                    .catch(err => console.error('Error fetching list data:', err));
            }
        }
        fetchDatalist();

        // Set interval to fetch every 5 seconds
        const intervalId = setInterval(fetchDatalist, 5000);

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);

    }, [activeItem, rowsPerPage, page]);

    useEffect(() => {
        console.log("activeItem1", activeItem1);
        const fetchDataleadlist = () => {
            if (activeItem1) {
                apiService.getleadlist(rowsPerPage.toString(), activeItem1, (page + 1).toString())
                    .then(response => {
                        setData1(response.data);
                        console.log("data1", response.data);

                    })
                    .catch(err => console.error('Error fetching list data:', err));
            }
        }
        fetchDataleadlist();

        // Set interval to fetch every 5 seconds
        const intervalId = setInterval(fetchDataleadlist, 5000);

        // Clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, [activeItem1, rowsPerPage, page]);

    // Filter data based on search query
    useEffect(() => {
        const filtered = data.filter(item =>
            Object.values(item).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setFilteredData(filtered);
    }, [searchQuery, data]);

    useEffect(() => {
        const filtered1 = data1.filter(item =>
            Object.values(item).some(value =>
                typeof value === 'string' && value.toLowerCase().includes(searchQuery1.toLowerCase())
            )
        );
        setFilteredData1(filtered1);
    }, [searchQuery1, data1]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const toggleSearchModal = () => {
        setIsSearchModalOpen(!isSearchModalOpen);
    };

    const toggleSearchModal1 = () => {
        setIsSearchModalOpen1(!isSearchModalOpen1);
    };

    const addNewEnquiry = () => {
        navigate('/marketingcrm/addenquiry');
    };
    const addNewLead = () => {
        navigate('/lead/addlead');
    };

    const updateEnquiry = (id: number) => {
        console.log("id", id);

        navigate('/marketingcrm/updateenquiry', { state: { id } });
    }

    const updateLead = (id: number) => {
        console.log("id", id);

        navigate('/lead/updatelead', { state: { id } });
    }

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        setSearchQuery(query);
    };

    const handleSearch1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query1 = event.target.value;
        setSearchQuery1(query1);
    };

    const handleFilter = () => {
        setIsSearchModalOpen(false); // Close the modal after filtering
    };

    const handleFilter1 = () => {
        setIsSearchModalOpen1(false); // Close the modal after filtering
    };

    const handleClick = (type: string) => {
        setActiveItem(type);
        console.log(`${type} clicked`);
    };
    const getBackgroundColor = (type: string) => {
        return activeItem === type ? '#00184a' : '#ffffff';
    };
    const getColor = (type: string) => {
        return activeItem === type ? 'white' : 'black';
    };

    const handleClick1 = (type: string) => {
        setActiveItem1(type);
        console.log(`${type} clicked`);
    };
    const getBackgroundColor1 = (type: string) => {
        return activeItem1 === type ? '#00184a' : '#ffffff';
    };
    const getColor1 = (type: string) => {
        return activeItem1 === type ? 'white' : 'black';
    };


    const [activeTab, setActiveTab] = useState(0); // Add state to track the active tab

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue); // Update the active tab when the user clicks on a new tab
    };

    return (
        <>
            <Helmet>
                <title>Enquiry - Admin</title>
            </Helmet>

            <PageTitleWrapper>

                {/* <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2, // Add some margin-bottom for spacing
                    }}
                >
                    {/* Title on the left */}
                {/* </PageTitleWrapper>   <PageTitle heading="LEAD BY ENQUIRY FOLLOWUP ANALYSIS" subHeading="" /> */}

                {/* Tabs on the right */}
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    sx={{
                        '& .MuiTab-root': {
                            cursor: 'pointer',
                            '&:hover': {
                                backgroundColor: '#f24646', // Changes background color on hover
                                color: '#fff',
                            },
                        },
                        '& .Mui-selected': {
                            backgroundColor: '#f24646', // Background color for selected tab
                            color: '#fff', // Keep the font color unchanged
                        },
                    }}
                >
                    <Tab label="Lead" />
                    <Tab label="Enquiry" />

                </Tabs>
                {/* </Box> */}
            </PageTitleWrapper>


            <Container maxWidth="lg">
                {/* Render content conditionally based on the selected tab */}
                {activeTab === 0 && (
                    <>
                        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                      
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                '& .header-item': {
                                                    width: '13%',
                                                    display: 'inline-block',
                                                    textAlign: 'center',
                                                    padding: '10px',
                                                    margin: '0 5px',
                                                    borderRadius: '5px',
                                                    boxShadow: '0 4px 5px rgba(0, 0, 0, 0.1)',
                                                    cursor: 'pointer'
                                                },
                                                '& .Pending': {
                                                    backgroundColor: getBackgroundColor1('Pending'),
                                                    color: getColor1('Pending'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Today': {
                                                    backgroundColor: getBackgroundColor1('Today'),
                                                    color: getColor1('Today'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Upcoming': {
                                                    backgroundColor: getBackgroundColor1('Upcoming'),
                                                    color: getColor1('Upcoming'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Order': {
                                                    backgroundColor: getBackgroundColor1('Order'),
                                                    color: getColor1('Order'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Reject': {
                                                    backgroundColor: getBackgroundColor1('Reject'),
                                                    color: getColor1('Reject'),
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        >
                                            {countData1.map((item) => (
                                                <div
                                                    key={item.name}
                                                    className={`header-item ${item.name}`}
                                                    onClick={() => handleClick1(item.name)}
                                                >
                                                    {`${item.name} Follow`}<br />{item.count}
                                                </div>
                                            ))}
                                            
                                            <Button sx={{ margin: 1, gap: 1 }} onClick={toggleSearchModal1} variant='contained' color="primary">
                                                <FontAwesomeIcon icon={faFilter as IconProp} /> Filter
                                            </Button>
                                            <Button sx={{ margin: 1, gap: 1 }} onClick={addNewLead} variant='contained' color="warning">
                                                <FontAwesomeIcon icon={faUserPlus as IconProp} /> Add Lead
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <br />
                            </Grid>
                        </Grid>

                        <Modal
                            open={isSearchModalOpen1}
                            onClose={toggleSearchModal1}
                            aria-labelledby="search-filter-modal"
                            aria-describedby="search-filter-description"
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    borderRadius: 1,
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <Typography variant="h6" component="h2">
                                    Search/Filter
                                </Typography>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Search"
                                    variant="outlined"
                                    value={searchQuery1}
                                    onChange={handleSearch1}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFilter1}
                                    sx={{ mt: 2 }}
                                >
                                    Apply Filter
                                </Button>
                            </Box>
                        </Modal>
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="tableHead">S.No</TableCell>
                                            <TableCell className="tableHead">Lead No</TableCell>
                                            <TableCell className="tableHead">Date</TableCell>
                                            <TableCell className="tableHead">Type</TableCell>
                                            <TableCell className="tableHead">Customer</TableCell>
                                            <TableCell className="tableHead">MKTG. Officer</TableCell>
                                            <TableCell className="tableHead">Last Follow</TableCell>
                                            <TableCell className="tableHead">Next Follow</TableCell>
                                            <TableCell className="tableHead">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData1.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell className="tableBody">{index + 1}</TableCell>
                                                <TableCell style={{ color: '#00184a' }} className="tableBody"><Button onClick={() => {
                                                    updateLead(row.id)
                                                }}> {row.enqno} </Button></TableCell>
                                                <TableCell className="tableBody">{row.enqdate}</ TableCell>
                                                <TableCell className="tableBody">{row.type}</TableCell>
                                                <TableCell className="tableBody">{row.cusname}</TableCell>
                                                <TableCell className="tableBody">{row.mktname}</TableCell>
                                                <TableCell className="tableBody">{row.lastfdate}</TableCell>
                                                <TableCell className="tableBody">{row.nffdate}</TableCell>
                                                <TableCell className="tableBody">{row.status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData1.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </>
                )}



                {activeTab === 1 && (
                    <>

                        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={3}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-around',
                                                '& .header-item': {
                                                    width: '13%',
                                                    display: 'inline-block',
                                                    textAlign: 'center',
                                                    padding: '10px',
                                                    margin: '0 5px',
                                                    borderRadius: '5px',
                                                    boxShadow: '0 4px 5px rgba(0, 0, 0, 0.1)',
                                                    cursor: 'pointer'
                                                },
                                                '& .Pending': {
                                                    backgroundColor: getBackgroundColor('Pending'),
                                                    color: getColor('Pending'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Today': {
                                                    backgroundColor: getBackgroundColor('Today'),
                                                    color: getColor('Today'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Upcoming': {
                                                    backgroundColor: getBackgroundColor('Upcoming'),
                                                    color: getColor('Upcoming'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Order': {
                                                    backgroundColor: getBackgroundColor('Order'),
                                                    color: getColor('Order'),
                                                    fontWeight: 'bold'
                                                },
                                                '& .Reject': {
                                                    backgroundColor: getBackgroundColor('Reject'),
                                                    color: getColor('Reject'),
                                                    fontWeight: 'bold'
                                                }
                                            }}
                                        >
                                            {countData.map((item) => (
                                                <div
                                                    key={item.name}
                                                    className={`header-item ${item.name}`}
                                                    onClick={() => handleClick(item.name)}
                                                >
                                                    {`${item.name} Follow`}<br />{item.count}
                                                </div>
                                            ))}

                                            <Button sx={{ margin: 1, gap: 1 }} onClick={toggleSearchModal} variant='contained' color="primary">
                                                <FontAwesomeIcon icon={faFilter as IconProp} /> Filter
                                            </Button>
                                            <Button sx={{ margin: 1, gap: 1 }} onClick={addNewEnquiry} variant='contained' color="warning">
                                                <FontAwesomeIcon icon={faUserPlus as IconProp} />  Add Enquiry
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                                <br />
                            </Grid>
                        </Grid>

                        <Modal
                            open={isSearchModalOpen}
                            onClose={toggleSearchModal}
                            aria-labelledby="search-filter-modal"
                            aria-describedby="search-filter-description"
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    borderRadius: 1,
                                    boxShadow: 24,
                                    p: 4,
                                }}
                            >
                                <Typography variant="h6" component="h2">
                                    Search/Filter
                                </Typography>
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label="Search"
                                    variant="outlined"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleFilter}
                                    sx={{ mt: 2 }}
                                >
                                    Apply Filter
                                </Button>
                            </Box>
                        </Modal>

                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="tableHead">S.No</TableCell>
                                            <TableCell className="tableHead">Enquiry No</TableCell>
                                            <TableCell className="tableHead">Date</TableCell>
                                            <TableCell className="tableHead">Type</TableCell>
                                            <TableCell className="tableHead">Customer</TableCell>
                                            <TableCell className="tableHead">MKTG. Officer</TableCell>
                                            <TableCell className="tableHead">Last Follow</TableCell>
                                            <TableCell className="tableHead">Next Follow</TableCell>
                                            <TableCell className="tableHead">Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                            <TableRow key={row.id}>
                                                <TableCell className="tableBody">{index + 1}</TableCell>
                                                <TableCell style={{ color: '#00184a' }} className="tableBody"><Button onClick={() => {
                                                    updateEnquiry(row.id)
                                                }}> {row.enqno} </Button></TableCell>
                                                <TableCell className="tableBody">{row.enqdate}</ TableCell>
                                                <TableCell className="tableBody">{row.type}</TableCell>
                                                <TableCell className="tableBody">{row.cusname}</TableCell>
                                                <TableCell className="tableBody">{row.mktname}</TableCell>
                                                <TableCell className="tableBody">{row.lastfdate}</TableCell>
                                                <TableCell className="tableBody">{row.nffdate}</TableCell>
                                                <TableCell className="tableBody">{row.status}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </>
                )}

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

            </Container>
            <Footer />
        </>
    );
}


export default Enquiry;
