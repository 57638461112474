import React, { useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CardContent, Card, Box, Grid, Typography, TextField, Button, TablePagination, CardHeader } from '@mui/material';
import apiService from 'src/services/api-service';
import { styled } from '@mui/system'; // Ensure this import is included
import PageTitle from 'src/components/PageTitle';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { DatePicker } from '@mui/lab';
import { toDate } from 'date-fns';
import Footer from 'src/components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faPrint } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import * as XLSX from 'xlsx';
import './homecss.css';
import { useLocation, useNavigate } from 'react-router-dom';



const cardStyle: React.CSSProperties = {
    justifyContent: 'center',
    backgroundColor: '#fff',
    padding: '15px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    width: '65%',
    height: '300px',
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 30,
    marginTop: 30,
};

const DashboardCard = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
}));

const MisDashboard: React.FC = () => {

    const [countData, setCountData] = useState([]);
    const [activeItem, setActiveItem] = useState<string>('Today');
    const [activeItem1, setActiveItem1] = useState<string>('');
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [fromDate, setFromDate] = useState<Date | null>(null);
    const [toDate, setToDate] = useState<Date | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        apiService.getcount({})
            .then(response => {
                setCountData(response.data);
            })
            .catch(err => console.error('Error fetching count data:', err));
    }, []);

    useEffect(() => {
        if (activeItem) {
            apiService.getstatus(rowsPerPage.toString(), activeItem, (page + 1).toString())
                .then(response => {
                    setData(response.data);
                })
                .catch(err => console.error('Error fetching list data:', err));
        }
    }, [activeItem, rowsPerPage, page]);

    useEffect(() => {
        if (activeItem) {
            apiService.getstatuslist(rowsPerPage.toString(), activeItem, (page + 1).toString(), activeItem1)
                .then(response => {
                    setList(response.data);
                })
                .catch(err => console.error('Error fetching list data:', err));
        }
    }, [activeItem, rowsPerPage, page, activeItem1]);

    useEffect(() => {
        console.log('list', list);
        if (list.length) {
            const filtered = list.filter(item =>
                Object.values(item).some(value =>
                    typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
                )
            );
            console.log('filtered', filtered);

            setFilteredData(filtered);
        } else {
            setFilteredData([]);

        }
    }, [searchQuery, list]);





    const handleClick = (type: string) => {
        setActiveItem(type);
        console.log(`Clicked: ${type}`);
        setActiveItem1("");
    };

    const getBackgroundColor = (type: string) => {
        return activeItem === type ? '#0152a8' : '#ffffff';
    };

    const getColor = (type: string) => {
        console.log(`Getting color for ${type}: ${activeItem === type ? 'white' : 'black'}`);
        return activeItem === type ? 'white' : 'black';
    };


    const handleRowClick = (row) => {
        const status = String(row.status);
        setActiveItem1(status);
        console.log('status', status);
    };



    const printRef = useRef(null);
    const [showImage, setShowImage] = useState(false);

    const handlePrint1 = useReactToPrint({
        content: () => printRef.current,
        documentTitle: "Enquiry Status wise Report",
        onBeforeGetContent: () => setShowImage(true),
        onBeforePrint: () => setShowImage(true),
        onAfterPrint: () => {
            setShowImage(false);
            console.log("after printing...");
        },
        removeAfterPrint: true,
    });

  

    const handleExportExcel = () => {
        const title: any[] = ['Enquiry MIS Report']; // Title row as an array
        const blankRow: any[] = []; // Blank row
    
        const table = printRef.current; // Make sure your `printRef` points to TableContainer.
    
        // Create a new workbook
        const wb = XLSX.utils.book_new();
    
        // Convert the table to a worksheet
        const ws = XLSX.utils.table_to_sheet(table, { raw: true });
    
        // Convert worksheet to JSON array (a 2D array format expected by aoa_to_sheet)
        const wsData = XLSX.utils.sheet_to_json(ws, { header: 1 }) as any[][];
    
        // Combine title, blank row, and table data into a single 2D array
        const fullWsData = [
            title, // Add title row
            blankRow, // Add blank row
            ...wsData // Spread the table data into the final worksheet data
        ];
    
        // Create a new worksheet from fullWsData (which is now a 2D array)
        const newWs = XLSX.utils.aoa_to_sheet(fullWsData);
    
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, newWs, 'Sheet1');
    
        // Write the file
        XLSX.writeFile(wb, 'Enquiry_Status_Report.xlsx');
    };
    
    const updateEnquiry = (id: number) => {
        console.log("id",id);
        
        navigate('/marketingcrm/updateenquiry', { state: { id} });
    }


    return (
        <>
            <PageTitleWrapper>
            <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <PageTitle heading="Enquiry MIS Report" subHeading="" />
            </Grid>

            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<FontAwesomeIcon icon={faPrint} />}
                            onClick={handlePrint1}
                            sx={{
                                m: 1,
                                width: '10ch',
                                '& .MuiInputBase-root': {
                                    height: '5%'  // Adjust this value as needed
                                }
                            }}
                        >
                            Print
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                            onClick={handleExportExcel}
                            sx={{
                                m: 1,
                                width: '10ch',
                                '& .MuiInputBase-root': {
                                    height: '5%'  // Adjust this value as needed
                                }
                            }}
                        >
                            Excel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
            </PageTitleWrapper>

            <Card sx={{ backgroundColor: '#edf0f5' }}>
                <CardContent>
                    <Grid container direction="row" spacing={3}>
                        {/* Left Side - Header Items */}
                        <Grid item xs={2.5}>
                            <Card
                                sx={{
                                    height: '175px', // Set the height of the Card
                                    // backgroundColor: 'ButtonShadow'
                                }}
                            >

                                <CardHeader
                                    title="Enquiry"
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: '2rem',
                                    }}
                                />
                                <TableContainer >
                                    <Table>
                                        <TableBody>
                                            {countData.slice(0, 4).map((item, index) => (
                                                index % 2 === 0 && (
                                                    <TableRow key={index}>
                                                        <TableCell
                                                            sx={{
                                                                width: '50%',
                                                                textAlign: 'center',
                                                                padding: '10px',
                                                                backgroundColor: getBackgroundColor(item.name),
                                                                color: getColor(item.name),
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                            }}
                                                            onClick={() => handleClick(item.name)}
                                                        >
                                                            <div style={{ color: getColor(item.name) }}>{item.name}</div>
                                                            <div style={{ color: getColor(item.name) }}>{item.count}</div>
                                                        </TableCell>
                                                        {countData[index + 1] && (
                                                            <TableCell
                                                                sx={{
                                                                    width: '50%',
                                                                    textAlign: 'center',
                                                                    padding: '10px',
                                                                    backgroundColor: getBackgroundColor(countData[index + 1].name),
                                                                    color: getColor(countData[index + 1].name),
                                                                    cursor: 'pointer',
                                                                    fontWeight: 'bold',
                                                                }}
                                                                onClick={() => handleClick(countData[index + 1].name)}
                                                            >
                                                                <div style={{ color: getColor(countData[index + 1].name) }}>{countData[index + 1].name}</div>
                                                                <div style={{ color: getColor(countData[index + 1].name) }}>{countData[index + 1].count}</div>
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                )
                                            ))}

                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>

                        {/* Right Side - Table */}
                        <Grid item xs={9.5}>
                            <Card
                                sx={{
                                    height: '175px', // Set the height of the Card
                                    // backgroundColor: 'ButtonShadow'
                                }}>
                                <CardHeader
                                    title="Status"
                                    sx={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        fontSize: '2rem',
                                    }}
                                />

                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                        <TableRow >
                                                {data.map((row) => (
                                                    <TableCell key={row.id}>{row.sname}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                {data.map((row) => (
                                                    <TableCell key={row.id} onClick={() => handleRowClick(row)} style={{ cursor: 'pointer' }} sx={{
                                                        backgroundColor: '#fff',
                                                    }}>{row.count}</TableCell>

                                                ))}
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Card>
                            <br />

                        </Grid>
                        <Grid item xs={12}>
                            <Paper ref={printRef}>
                                <div className="printable-content" style={{ padding: '20px' }}>
                                    <header style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                        <img
                                            src={require('../assets/logosaarc.png')}
                                            alt="Logo"
                                            style={{ width: '50px', height: '50px', marginRight: '20px' }}
                                        />
                                        <h1 style={{ margin: 0 }}>Enquiry MIS Report</h1>
                                    </header>
                                </div>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="tableHead">S.No</TableCell>
                                                <TableCell className="tableHead">Enquiry No</TableCell>
                                                <TableCell className="tableHead">Date</TableCell>
                                                <TableCell className="tableHead">Type</TableCell>
                                                <TableCell className="tableHead">Customer</TableCell>
                                                <TableCell className="tableHead">MKTG. Officer</TableCell>
                                                <TableCell className="tableHead">Last Follow</TableCell>
                                                <TableCell className="tableHead">Next Follow</TableCell>
                                                <TableCell className="tableHead">Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredData.length > 0 ? filteredData.map((row, index) => (
                                                <TableRow key={index + 1}>
                                                    <TableCell className="tableBody">{index + 1}</TableCell>
                                                    <TableCell style={{ color: '#00184a' }} className="tableBody"><Button onClick={() =>{
                                             updateEnquiry(row.id)
                                        }}> {row.enqno} </Button></TableCell>
                                                    <TableCell className="tableBody">{row.enqdate}</TableCell>
                                                    <TableCell className="tableBody">{row.type}</TableCell>
                                                    <TableCell className="tableBody">{row.cusname}</TableCell>
                                                    <TableCell className="tableBody">{row.mktname}</TableCell>
                                                    <TableCell className="tableBody">{row.lastfdate}</TableCell>
                                                    <TableCell className="tableBody">{row.nffdate}</TableCell>
                                                    <TableCell className="tableBody">{row.status}</TableCell>
                                                </TableRow>
                                            )) : (
                                                <TableRow>
                                                    <TableCell colSpan={9} align="center">No data available</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </Paper>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Footer />

            <style>
                {`
                    @media print {
                        .printable-content {
                            display: block !important;
                        }
   .css-rorn0c-MuiTableContainer-root {
    width: 100%;
    overflow-x: unset;

}
                    }

                    @media screen {
                        .printable-content {
                            display: none;
                        }
                    }
                `}
            </style>
        </>




    );
};

export default MisDashboard;