import { forwardRef, Ref, useState, ReactElement, ChangeEvent, useRef, useEffect } from 'react';
import {
  Avatar,
  Link,
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  lighten,
  List,
  ListItem,
  ListItemAvatar,
  TextField,
  Theme,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Hidden
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';

import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { FaMicrophone } from 'react-icons/fa6';
import { useNavigate } from 'react-router';
import { errorToast, successToast } from 'src/layouts/utile/toast';


// Ensure SpeechRecognition is available across browsers
const SpeechRecognition =
  (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function HeaderSearch() {
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const [recognizedText, setRecognizedText] = useState<string>("");
  const [speechActive, setSpeechActive] = useState<boolean>(false);
  const navigate = useNavigate();

  const recognitionRef = useRef<any>(null);
  const [isListening, setIsListening] = useState(true);
  const [transcript, setTranscript] = useState("");

  useEffect(() => {
    if (!SpeechRecognition) {
      alert("Your browser doesn't support Speech Recognition.");
      return;
    }

    // Initialize SpeechRecognition instance
    const recognition = new SpeechRecognition();
    recognitionRef.current = recognition;
    recognition.continuous = true; // Keep listening
    recognition.interimResults = true; // Show partial results
    recognition.lang = "en-US";

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      const lastResultIndex = event.results.length - 1;
      const command = event.results[lastResultIndex][0].transcript.trim();
      console.log("Voice Command:", command);
      setTranscript(command);

      // Handle specific commands
      handleVoiceCommand(command);
    };

    recognition.onend = () => {
      if (isListening) {
        console.log("Restarting microphone...");
      //  recognition.start(); // Restart if still listening
      }
    };

    recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
      console.error("Speech recognition error:", event.error);
      if (event.error === "not-allowed") {
       // alert("Microphone access denied.");
      }
    };

    // Start recognition on component mount
    recognition.start();

    // Cleanup on unmount
    return () => {
      recognition.stop();
      recognitionRef.current = null;
    };
  }, [isListening]);

  const handleVoiceCommand = (command: string) => {
    if (command.includes("click")) {
      document.getElementById("submit-button")?.click();
    }else if (command.includes("hel")) {
      successToast(`Hi! "${localStorage.getItem('uname')}"`);
      successToast(`What can I help you`);
    }
      else if (command.includes("go to")) {
      const page = command.replace("go to", "").trim();
      if(page){
        navigateToPage(page);
      }
      
    } else {
      //successToast(`Hi! "${localStorage.getItem('uname')}"`);
   //  successToast(`what can I help you`);
      // const textToType = command.replace("type", "").trim();
      // alert(textToType);
    }
  };

  const navigateToPage = (page: string) => {
    // Routes object mapping voice commands to URLs
const routes: { [key: string]: string } = {
  "user role": "/SuperAdmin/UserSettings/UserRole",
  "user": "/SuperAdmin/UserSettings/User",
  "department": "/SuperAdmin/UserSettings/Department",
  "designation": "/SuperAdmin/UserSettings/Designation",
  "employee": "/SuperAdmin/UserSettings/Employee",
  "city": "/SuperAdmin/UserSettings/City",
  "company": "/SuperAdmin/CompanyRegister/Company",
  "plant": "/SuperAdmin/CompanyRegister/Plant",
  "financial year": "/SuperAdmin/CompanyRegister/Financialyear",
  "tax": "/SuperAdmin/CompanyRegister/Tax",
  "mode of enquiry": "/MarketingCRM/GeneralSettings/ModeOfEnquiry",
  "enquiry type": "/MarketingCRM/GeneralSettings/Type",
  "followup type": "/MarketingCRM/GeneralSettings/FollowType",
  "priority": "/MarketingCRM/GeneralSettings/Priority",
  "status": "/MarketingCRM/GeneralSettings/Status",
  "terms and conditions": "/MarketingCRM/GeneralSettings/Quoteterms",
  "team": "/MarketingCRM/GeneralSettings/Team",
  "target": "/MarketingCRM/GeneralSettings/Target",
  "customer register": "/MarketingCRM/customer",
  "lead register": "/MarketingCRM/addlead",
  "enquiry register": "/MarketingCRM/addenquiry",
  "lead enquiry follow": "/MarketingCRM/LeadEnquiry",
  "monthly report": "/MarketingCRM/Reports/MonthlyVsActual",
  "enquiry status report": "/MarketingCRM/Reports/EnquiryStatusWiseReport",
  "enquiry mis report": "/MarketingCRM/Reports/MISDashboard",
  "daily followup report": "/MarketingCRM/Reports/DailyFollowupReport",
  "customer detail report": "/MarketingCRM/Reports/CustomerDetail",
  "test and accessories": "/design/role",
  "modal": "/design/modal",
  "design followup": "/Design/DesignFollowup",
  "concept drawing report": "/design/company",
  "production drawing report": "/admin/plant",
  "ecn report": "/admin/financialyear",
  "enquiry detail report": "/MarketingCRM/Reports/enquiryreport",
};

    const url = routes[page];
    if (url) {
      navigate(url);
    } else {
   //   alert(`Page "${page}" not found.`);
      errorToast(`Page "${page}" not found.`);
    }
  };
  const bikeonclick = () => {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert("Speech Recognition not supported in this browser");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = false; // Stop after each command
    recognition.interimResults = false; // Only provide final results
    recognition.lang = "en-US"; // Language setting

    recognition.onstart = () => setSpeechActive(true);
    recognition.onend = () => setSpeechActive(false);

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      const transcript = event.results[0][0].transcript.trim().toLowerCase();
      setRecognizedText(transcript);
      handleVoiceCommand(transcript); // Handle recognized command
    };

    // Start recognition on component mount
    recognition.start();

    // Cleanup on unmount
    return () => recognition.stop();
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title="AI Voice">
        <IconButton color="primary" onClick={bikeonclick}>
          <FaMicrophone />
        </IconButton>
      </Tooltip>

      {/* <Tooltip arrow title="AI Voice">
        <IconButton color="primary" onClick={handleClickOpen}>
          <FaMicrophone />
        </IconButton>
      </Tooltip> */}
      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <DialogTitleWrapper>
          <SearchInputWrapper
            value={searchValue}
            autoFocus={true}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
            placeholder="Search terms here..."
            fullWidth
            label="Search"
          />
        </DialogTitleWrapper>
        <Divider />

        {openSearchResults && (
          <DialogContent>
            <Box
              sx={{ pt: 0, pb: 1 }}
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="body2" component="span">
                Search results for{' '}
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  variant="body1"
                  component="span"
                >
                  {searchValue}
                </Typography>
              </Typography>
              <Link href="#" variant="body2" underline="hover">
                Advanced search
              </Link>
            </Box>
            <Divider sx={{ my: 1 }} />
            <List disablePadding>
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) =>
                          theme.palette.secondary.main
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link
                      href="#"
                      underline="hover"
                      sx={{ fontWeight: 'bold' }}
                      variant="body2"
                    >
                      Dashboard for Healthcare Platform
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      color: (theme: Theme) =>
                        lighten(theme.palette.secondary.main, 0.5)
                    }}
                  >
                    This page contains all the necessary information for
                    managing all hospital staff.
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
              <Divider sx={{ my: 1 }} component="li" />
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) =>
                          theme.palette.secondary.main
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link
                      href="#"
                      underline="hover"
                      sx={{ fontWeight: 'bold' }}
                      variant="body2"
                    >
                      Example Projects Application
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      color: (theme: Theme) =>
                        lighten(theme.palette.secondary.main, 0.5)
                    }}
                  >
                    This is yet another search result pointing to a app page.
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
              <Divider sx={{ my: 1 }} component="li" />
              <ListItem button>
                <Hidden smDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) =>
                          theme.palette.secondary.main
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex="1">
                  <Box display="flex" justifyContent="space-between">
                    <Link
                      href="#"
                      underline="hover"
                      sx={{ fontWeight: 'bold' }}
                      variant="body2"
                    >
                      Search Results Page
                    </Link>
                  </Box>
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{
                      color: (theme: Theme) =>
                        lighten(theme.palette.secondary.main, 0.5)
                    }}
                  >
                    Choose if you would like to show or not this typography
                    section here...
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
            </List>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Box sx={{ textAlign: 'center' }}>
              <Button color="primary">View all search results</Button>
            </Box>
          </DialogContent>
        )}
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
