import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import PageTitle from "src/components/PageTitle";
import PageTitleWrapper from "src/components/PageTitleWrapper";
import { useNavigate } from "react-router-dom";
import apiService from 'src/services/api-service';
import './designdev.css';
import SearchIcon from '@mui/icons-material/Search';

interface Data1 {
  id: number;
  reqtypeid: number;
  enqno: string;
  enqdate: Date;
  type: string;
  sname: string;
  cusname: string;
  mktname?: string; // Make optional for cases where it's not needed
  req?: string; // Make optional for cases where it's not needed
  status: string;
  designstatus: string;
  mpnno?: string; // New field to include for "NewEnq_Close"
}

const DesignDev: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activePartition, setActivePartition] = useState<string | null>(null);
  const [countData, setCountData] = useState([]);
  const [tableData, setTableData] = useState<Data1[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const data = [
    {
      label: 'New Enquiries',
      partitions: [
        { label: 'Pending', key: 'NewEnq_Pending' },
        { label: 'Request to Clarify', key: 'NewEnq_Clarify' },
        { label: 'Completed', key: 'NewEnq_Close' }
      ]
    },
    {
      label: 'Engineering Change Request',
      partitions: [
        { label: 'Pending', key: 'ECR_Pending' },
        { label: 'Completed', key: 'ECR_Close' }
      ]
    }
  ];

  useEffect(() => {
    apiService.getDesignCount({})
      .then(response => {
        setCountData(response.data);
        console.log("response.data", response.data);
      })
      .catch(err => console.error('Error fetching count data:', err));
  }, []);

  useEffect(() => {
    if (activePartition) {
      apiService
        .getDesignList(rowsPerPage.toString(), activePartition, (page + 1).toString(), search)
        .then((response) => {
          console.log("Fetched list data:", response.data);
          setTableData(response.data);
        })
        .catch((err) => {
          console.log("Error fetching grid data:", err);
        });
    }
  }, [activePartition, rowsPerPage, page, search]);

  const navigate = useNavigate();

  const handleClick = (index: number, partitionKey: string) => {
    setActiveIndex(index);
    setActivePartition(partitionKey);
    setPage(0); // Reset page to 0 when a new partition is clicked
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when rows per page changes
  };

  const handleSearch = () => {
    if (activePartition) {
      apiService
        .getDesignList(rowsPerPage.toString(), activePartition, (page + 1).toString(), search)
        .then((response) => {
          setTableData(response.data);
        })
        .catch((err) => {
          console.log("Error during search:", err);
        });
    } else {
      console.log("Please select a partition to search within.");
    }
  };


  const handleUpdateClick = (id: number, reqtypeid: number) => {
    localStorage.setItem('activeIndex', JSON.stringify(activeIndex));
    localStorage.setItem('activePartition', activePartition);
    localStorage.setItem('tableData', JSON.stringify(tableData));
    localStorage.setItem('page', JSON.stringify(page));
    localStorage.setItem('rowsPerPage', JSON.stringify(rowsPerPage));

    navigate('/Design/UpdateDesign', { state: { id, reqtypeid } });
  };

  return (
    <>
      <Helmet>
        <title>Design & Development - Design</title>
      </Helmet>

      <PageTitleWrapper>
        <header>
          <PageTitle
            heading="Design and Development"
            subHeading=""
          />
        </header>
        <Grid container item xs={12} sm={6} justifyContent="flex-end" alignItems="center" sx={{ marginLeft: '480px', marginTop: '-35px' }}>
          <TextField
            id="search"
            name="search"
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
            margin="dense"
            style={{ marginRight: '-3px', width: '180px' }} // Adjust width as needed
            placeholder="Search"
            size="small" // Reduces the size
            variant="outlined" // Smaller variant style
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
              style: { padding: '3px 10px' } // Reduces inner padding
            }}
          />

        </Grid>
      </PageTitleWrapper>
      <Card>
        <CardContent>
          <div className="top-row">
            {data.map((item, index) => (
              <div key={index} className="box-container">
                <span className="box-label">{item.label}</span>
                <div className="partitions-container">
                  {item.partitions.map(partition => (
                    <div
                      key={partition.key}
                      className={`box ${activeIndex === index && activePartition === partition.key ? 'active' : ''}`}
                      onClick={() => handleClick(index, partition.key)}
                    >
                      <span className="partition-label">{partition.label}</span>
                      <span className="partition-digit">
                        {countData.find(cd => cd.name === partition.key)?.count || 0}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </CardContent>
      </Card>
      <br />
      {activePartition && (
        <Grid item xs={12} className="grid-margin">
          <Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-head">
                    <TableCell>S.No</TableCell>
                    <TableCell>Enquiry No</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Short name</TableCell>
                    <TableCell>Customer</TableCell>
                    {activePartition === 'NewEnq_Pending' && (
                      <>
                        <TableCell>MKTG Officer</TableCell>
                        <TableCell>Requirement</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Design Status</TableCell>
                      </>
                    )}
                    {activePartition === 'NewEnq_Close' && (
                      <>
                        <TableCell>MPN No</TableCell>
                        <TableCell>Status</TableCell>
                      </>
                    )}
                    {activePartition === 'NewEnq_Clarify' && (
                      <>
                        <TableCell>MKTG Officer</TableCell>
                        <TableCell>Requirement</TableCell>
                        <TableCell>Status</TableCell>
                      </>
                    )}
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index) => (
                    <TableRow key={item.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.enqno}</TableCell>
                      <TableCell>{item.enqdate}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>{item.sname}</TableCell>
                      <TableCell>{item.cusname}</TableCell>
                      {activePartition === 'NewEnq_Pending' && (
                        <>
                          <TableCell>{item.mktname}</TableCell>
                          <TableCell>{item.req}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>{item.designstatus}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleUpdateClick(item.id, item.reqtypeid)}
                            >
                              Update
                            </Button>
                          </TableCell>
                        </>
                      )}
                      {activePartition === 'NewEnq_Close' && (
                        <>
                          <TableCell>{item.mpnno}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleUpdateClick(item.id, item.reqtypeid)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </>
                      )}
                      {activePartition === 'NewEnq_Clarify' && (
                        <>
                          <TableCell>{item.mktname}</TableCell>
                          <TableCell>{item.req}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleUpdateClick(item.id, item.reqtypeid)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={tableData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Paper>
        </Grid>
      )}
    </>
  );
}

export default DesignDev;
