import { useContext, useRef, useState } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';
import './sidebarstyle.css'
import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  Button,
  lighten,
  darken,
  Tooltip
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import { NavLink, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight, faTachometerAlt, faShoppingCart, faUsers, faChartBar, faFolder, faGear,faAnchor,faTruckFast,faFileEdit,faUsersGear,faStore,faCheckDouble,faWarehouse,faCaravan,faHandshake,faGears,faCalculator, faUserTag, faUser, faUserShield, faBuilding, faSitemap, faIdBadge, faPeopleCarry, faUserCheck, faUserGraduate, faHardHat, faUserSecret, faUserTie, faCity, faMapMarkerAlt, faIndustry, faCogs, faHome, faCalendar, faChartLine, faCalendarAlt, faFileInvoice, faMoneyBillWave, faReceipt, faFileAlt, faUserFriends, faTools, faClipboardCheck, faChartPie, faWallet, faClipboardList, faThumbsUp, faStar, faShieldAlt, faGauge, faAward, faVial, faWrench, faCubes, faDraftingCompass, faShapes, faPenRuler, faPencilRuler, faBarsStaggered, faMicrophone } from '@fortawesome/free-solid-svg-icons';


const saarclogo = require("../../../assets/logosaarc.png")
const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);
  const [recognizedText, setRecognizedText] = useState<string>("");
  const [speechActive, setSpeechActive] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleVoiceCommand = (command: string) => {
    if (command.includes("click")) {
      document.getElementById("submit-button")?.click();
    } else if (command.includes("go to")) {
      const page = command.replace("go to", "").trim();
      navigateToPage(page);
    } else {
      // const textToType = command.replace("type", "").trim();
      // alert(textToType);
    }
  };

  const navigateToPage = (page: string) => {
    // Routes object mapping voice commands to URLs
const routes: { [key: string]: string } = {
  "user role": "/SuperAdmin/UserSettings/UserRole",
  "user": "/SuperAdmin/UserSettings/User",
  "department": "/SuperAdmin/UserSettings/Department",
  "designation": "/SuperAdmin/UserSettings/Designation",
  "employee": "/SuperAdmin/UserSettings/Employee",
  "city": "/SuperAdmin/UserSettings/City",
  "company": "/SuperAdmin/CompanyRegister/Company",
  "plant": "/SuperAdmin/CompanyRegister/Plant",
  "financial year": "/SuperAdmin/CompanyRegister/Financialyear",
  "tax": "/SuperAdmin/CompanyRegister/Tax",
  "mode of enquiry": "/MarketingCRM/GeneralSettings/ModeOfEnquiry",
  "enquiry type": "/MarketingCRM/GeneralSettings/Type",
  "followup type": "/MarketingCRM/GeneralSettings/FollowType",
  "priority": "/MarketingCRM/GeneralSettings/Priority",
  "status": "/MarketingCRM/GeneralSettings/Status",
  "terms and conditions": "/MarketingCRM/GeneralSettings/Quoteterms",
  "team": "/MarketingCRM/GeneralSettings/Team",
  "target": "/MarketingCRM/GeneralSettings/Target",
  "customer register": "/MarketingCRM/customer",
  "lead register": "/MarketingCRM/addlead",
  "enquiry register": "/MarketingCRM/addenquiry",
  "lead enquiry followup": "/MarketingCRM/LeadEnquiry",
  "monthly report": "/MarketingCRM/Reports/MonthlyVsActual",
  "enquiry status report": "/MarketingCRM/Reports/EnquiryStatusWiseReport",
  "mis report": "/MarketingCRM/Reports/MISDashboard",
  "daily followup report": "/MarketingCRM/Reports/DailyFollowupReport",
  "customer detail report": "/MarketingCRM/Reports/CustomerDetail",
  "test and accessories": "/design/role",
  "modal": "/design/modal",
  "design followup": "/Design/DesignFollowup",
  "concept drawing report": "/design/company",
  "production drawing report": "/admin/plant",
  "ecn report": "/admin/financialyear",
};

    const url = routes[page];
    if (url) {
      navigate(url);
    } else {
      alert(`Page "${page}" not found.`);
    }
  };
  const bikeonclick = () => {
    const SpeechRecognition =
      (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;

    if (!SpeechRecognition) {
      alert("Speech Recognition not supported in this browser");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = false; // Stop after each command
    recognition.interimResults = false; // Only provide final results
    recognition.lang = "en-US"; // Language setting

    recognition.onstart = () => setSpeechActive(true);
    recognition.onend = () => setSpeechActive(false);

    recognition.onresult = (event: SpeechRecognitionEvent) => {
      const transcript = event.results[0][0].transcript.trim().toLowerCase();
      setRecognizedText(transcript);
      handleVoiceCommand(transcript); // Handle recognized command
    };

    // Start recognition on component mount
    recognition.start();

    // Cleanup on unmount
    return () => recognition.stop();
  };
  return (
    <>

      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >

        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 200
              }}
            >
              <NavLink to="/MarketingCRM/crmdashboard"  className='logodiv'>
                <img src={saarclogo} />
                <h3>SAARC <span>Cases</span></h3>
              </NavLink >

              {/* <Logo /> */}
            </Box>
          </Box>
          {/* <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          /> */}
          {/* <button className="btn btn-primary" onClick={bikeonclick} style={{backgroundColor: "#5569ff",textAlign:"center"}}>
          <FontAwesomeIcon icon={faMicrophone} style={{fontSize: "15px"}}/>
          <br />
           AI Voice
          </button> */}
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        {/* <Box p={2}>
          <Button
            href="https://bloomui.com"
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            color="warning"
            size="small"
            fullWidth
          >
            Upgrade to PRO
          </Button>
        </Box> */}
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5)
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
